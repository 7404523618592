<template>
  <div class="modal" v-if="show">
    <div class="content">
      <div class="iconWrapper">
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.9998 22.3125C24.2622 22.3125 26.9061 19.6678 26.9061 16.4062C26.9061 13.1447 24.2646 10.5 20.9998 10.5C17.735 10.5 15.0936 13.1414 15.0936 16.4062C15.0936 19.6711 17.735 22.3125 20.9998 22.3125ZM23.6248 26.25H18.3748C14.475 26.25 11.1938 28.7011 9.86816 32.1325C12.7229 34.9863 16.6604 36.75 20.9998 36.75C25.3393 36.75 29.2768 34.9839 32.1314 32.1325C30.8025 28.7027 27.5213 26.25 23.6248 26.25Z"
            fill="#FF0009"
          />
          <path
            d="M21 0C9.40078 0 0 9.40078 0 21C0 32.5992 9.40078 42 21 42C32.5992 42 42 32.5992 42 21C42 9.40078 32.5992 0 21 0ZM21 10.5C24.2624 10.5 26.9062 13.1447 26.9062 16.4062C26.9062 19.6678 24.2648 22.3125 21 22.3125C17.7384 22.3125 15.0938 19.6678 15.0938 16.4062C15.0938 13.1447 17.7352 10.5 21 10.5ZM21 36.75C16.6581 36.75 12.723 34.9839 9.86836 32.1325C11.1973 28.7027 14.4785 26.25 18.375 26.25H23.625C27.5248 26.25 30.806 28.7011 32.1316 32.1325C29.277 34.9863 25.3395 36.75 21 36.75Z"
            fill="#FF999D"
          />
        </svg>

        <h1>{{ t['incorrect_email'] }}</h1>
        <p>{{ t['wrong_email_message'] }}</p>
      </div>
      <div class="actionButtons">
        <button data-test-id="logout_email_error" aria-label="Email Error logout" @click="logout">{{ t['logout'] }}</button>
        <button data-test-id="browse_similar_email_error" aria-label="Browse Similar Cars" @click="browseSimilarCars">{{ t['browse_similars'] }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalEmailError',
  props: { show: { type: Boolean, default: false } },
  computed: {
    t() {
      return this.$root.languageResources.MODAL_EMAIL_ERROR_COMPONENT
    }
  },
  methods: {
    logout() {
      this.$emit('logout')
    },
    browseSimilarCars() {
      this.$emit('browseSimilarCars')
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  display: grid;
  place-items: center;
  z-index: 3;

  > * {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .content {
    background-color: white;
    border-radius: 16px;
    max-width: 636px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    padding: 2rem 0;

    > .iconWrapper {
      max-width: 75%;
      margin: auto;

      > h1 {
        font-size: 1.563em;
      }
    }

    > .actionButtons {
      display: flex;
      width: 80%;
      justify-content: center;
      gap: 1rem;
      margin-top: 2rem;

      @media screen and (max-width: 35rem) {
        flex-direction: column;
      }

      > :first-child,
      :last-child {
        padding: 0.75em 1em;
        border-radius: 2em;
        font-size: 1rem;
        flex: 1 1 0%;
        font-weight: bold;
        cursor: pointer;
        white-space: nowrap;

        @media screen and (max-width: 35rem) {
          padding: 1rem 2rem 1rem 2rem;
          margin: 0 2rem 0 2rem;
          font-size: 1rem;
        }
      }

      > :last-child {
        color: #0068ff;
        background-color: white;
        border-color: #0068ff;
        border-style: solid;
        border-image: none;
        font-family: 'Biennale', sans-serif;
      }

      > :first-child {
        color: white;
        background-color: #0068ff;
        border: none;
        font-family: 'Biennale', sans-serif;
      }
    }
  }
}
</style>
