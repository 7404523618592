<template>
  <footer class="footer">
    <svg width="69" height="20" viewBox="0 0 69 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.921466 17.2614C0.111563 16.6234 -0.0334939 15.4556 0.595087 14.6491C1.23576 13.8425 2.4083 13.698 3.21821 14.324C4.3424 15.2028 6.14353 15.7445 7.65454 15.7807C9.20182 15.8168 11.3293 15.3112 11.3656 13.8906C11.4019 12.3738 9.87875 12.2895 7.37651 12.0487C3.00062 11.6274 -0.106023 10.568 0.00277022 6.39068C0.099475 2.69488 3.67755 0.732612 7.6908 0.877073C9.96337 0.949304 12.4656 1.76792 14.1942 3.12826C15.0041 3.7663 15.1492 4.93403 14.5206 5.74061C13.8799 6.54718 12.7074 6.69165 11.8975 6.06565C10.7491 5.16276 9.16555 4.69326 7.70289 4.62103C5.95012 4.53676 3.78635 5.09053 3.75008 6.49903C3.71382 8.01587 5.23692 8.10014 7.73916 8.34091C12.115 8.76225 15.2217 9.82164 15.1129 13.999C15.0162 17.6105 11.6315 19.5487 7.66663 19.5126C5.22483 19.4765 2.65006 18.6097 0.921466 17.2614Z" fill="#F2F2F2" />
      <path d="M16.2383 10.3751C16.2383 5.10228 20.2757 0.864746 25.2318 0.864746C30.0187 0.864746 33.9715 4.82539 34.2133 9.88153C34.2375 10.3871 34.0562 10.8928 33.6935 11.2539C33.343 11.6271 32.8473 11.8318 32.3396 11.8318H20.2878C20.8801 14.107 23.0439 15.7804 25.5945 15.7804C27.178 15.7804 28.7011 15.1303 29.7649 13.9987C30.466 13.2403 31.6506 13.2041 32.4122 13.9024C33.1737 14.6006 33.21 15.7804 32.5089 16.5388C30.744 18.4288 28.2055 19.5123 25.5945 19.5123C20.457 19.5123 16.2383 15.4553 16.2383 10.3751ZM29.9462 8.11189C29.2209 6.05331 27.3714 4.59666 25.2197 4.59666C23.056 4.59666 21.2186 6.07739 20.5054 8.11189H29.9462Z" fill="#F2F2F2" />
      <path d="M34.9141 10.3751C34.9141 5.10228 38.9515 0.864746 43.9076 0.864746C48.6945 0.864746 52.6473 4.82539 52.8891 9.88153C52.9132 10.3871 52.7319 10.8928 52.3693 11.2539C52.0187 11.6271 51.5231 11.8318 51.0154 11.8318H38.9757C39.568 14.107 41.7318 15.7804 44.2823 15.7804C45.8659 15.7804 47.389 15.1303 48.4527 13.9987C49.1538 13.2403 50.3385 13.2041 51.1 13.9024C51.8616 14.6006 51.8978 15.7804 51.1967 16.5388C49.4319 18.4288 46.8934 19.5123 44.2823 19.5123C39.1449 19.5123 34.9141 15.4553 34.9141 10.3751ZM48.6341 8.11189C47.9088 6.05331 46.0593 4.59666 43.9076 4.59666C41.7438 4.59666 39.9064 6.07739 39.1932 8.11189H48.6341Z" fill="#F2F2F2" />
      <path d="M53.8911 18.4166C53.6131 17.7545 53.7219 16.972 54.157 16.4182L63.4528 4.58445H55.4263C54.4955 4.58445 53.746 3.7538 53.746 2.7185C53.746 1.68319 54.4955 0.852539 55.4263 0.852539H67.1517C67.8166 0.852539 68.4089 1.27389 68.6869 1.94804C68.9649 2.61015 68.8561 3.39265 68.421 3.94641L59.1252 15.7802H67.1517C68.0825 15.7802 68.832 16.6108 68.832 17.6462C68.832 18.6815 68.0825 19.5121 67.1517 19.5121H55.4263C54.7614 19.5121 54.1691 19.0787 53.8911 18.4166Z" fill="#F2F2F2" />
    </svg>
    <nav class="social">
      <a @click="trackEvent($root.analyticsEvents.OUTBOUND_LINK_CLICK, { name: 'Instagram' })" href="https://www.instagram.com/seez.dk/" target="_blank" rel="noreferrer" aria-label="Instagram">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.50212 4.38503C6.8063 4.38503 4.63185 6.4455 4.63185 9C4.63185 11.5545 6.8063 13.615 9.50212 13.615C12.1979 13.615 14.3724 11.5545 14.3724 9C14.3724 6.4455 12.1979 4.38503 9.50212 4.38503ZM9.50212 12.0003C7.76001 12.0003 6.33581 10.6548 6.33581 9C6.33581 7.3452 7.75577 5.99967 9.50212 5.99967C11.2485 5.99967 12.6684 7.3452 12.6684 9C12.6684 10.6548 11.2442 12.0003 9.50212 12.0003ZM15.7076 4.19625C15.7076 4.79471 15.1989 5.27268 14.5716 5.27268C13.94 5.27268 13.4356 4.79069 13.4356 4.19625C13.4356 3.60181 13.9443 3.11983 14.5716 3.11983C15.1989 3.11983 15.7076 3.60181 15.7076 4.19625ZM18.9332 5.28874C18.8612 3.84681 18.5136 2.56956 17.3988 1.51724C16.2883 0.464911 14.9404 0.135557 13.4187 0.0632601C11.8504 -0.0210867 7.14964 -0.0210867 5.58132 0.0632601C4.06386 0.131541 2.71595 0.460895 1.60117 1.51322C0.486392 2.56555 0.143056 3.8428 0.0667596 5.28473C-0.0222532 6.77084 -0.0222532 11.2251 0.0667596 12.7113C0.138818 14.1532 0.486392 15.4304 1.60117 16.4828C2.71595 17.5351 4.05962 17.8644 5.58132 17.9367C7.14964 18.0211 11.8504 18.0211 13.4187 17.9367C14.9404 17.8685 16.2883 17.5391 17.3988 16.4828C18.5094 15.4304 18.8569 14.1532 18.9332 12.7113C19.0223 11.2251 19.0223 6.77485 18.9332 5.28874ZM16.9071 14.3058C16.5765 15.093 15.9365 15.6995 15.1015 16.0168C13.851 16.4868 10.8839 16.3783 9.50212 16.3783C8.1203 16.3783 5.14897 16.4828 3.90279 16.0168C3.072 15.7036 2.43196 15.0971 2.0971 14.3058C1.60117 13.1209 1.71562 10.3094 1.71562 9C1.71562 7.69062 1.60541 4.87504 2.0971 3.69419C2.42772 2.90695 3.06776 2.30046 3.90279 1.98315C5.15321 1.51322 8.1203 1.62167 9.50212 1.62167C10.8839 1.62167 13.8553 1.51724 15.1015 1.98315C15.9322 2.29644 16.5723 2.90293 16.9071 3.69419C17.4031 4.87906 17.2886 7.69062 17.2886 9C17.2886 10.3094 17.4031 13.125 16.9071 14.3058Z" fill="white" />
        </svg>
      </a>
      <a @click="trackEvent($root.analyticsEvents.OUTBOUND_LINK_CLICK, { name: 'Linkedin' })" href="https://linkedin.com/company/seez-denmark/" target="_blank" rel="noreferrer" aria-label="LinkedIn">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.6429 0H1.3529C0.606473 0 0 0.582589 0 1.29777V16.7022C0 17.4174 0.606473 18 1.3529 18H17.6429C18.3893 18 19 17.4174 19 16.7022V1.29777C19 0.582589 18.3893 0 17.6429 0ZM5.74241 15.4286H2.92634V6.83839H5.74665V15.4286H5.74241ZM4.33437 5.66518C3.43103 5.66518 2.70156 4.97009 2.70156 4.1183C2.70156 3.26652 3.43103 2.57143 4.33437 2.57143C5.23348 2.57143 5.96719 3.26652 5.96719 4.1183C5.96719 4.97411 5.23772 5.66518 4.33437 5.66518ZM16.2984 15.4286H13.4824V11.25C13.4824 10.2536 13.4612 8.97188 12.0192 8.97188C10.5518 8.97188 10.327 10.0567 10.327 11.1777V15.4286H7.51094V6.83839H10.2125V8.01161H10.2507C10.6281 7.33661 11.5484 6.62545 12.9183 6.62545C15.7683 6.62545 16.2984 8.40536 16.2984 10.7196V15.4286Z" fill="white" />
        </svg>
      </a>
      <a @click="trackEvent($root.analyticsEvents.OUTBOUND_LINK_CLICK, { name: 'Youtube' })" href="https://www.youtube.com/channel/UC0MDYtgUBi02-6tqhWgSGBw" target="_blank" rel="noreferrer" aria-label="YouTube">
        <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.1948 2.74542C26.8756 1.66476 25.9351 0.813669 24.741 0.524838C22.5765 0 13.8972 0 13.8972 0C13.8972 0 5.21789 0 3.05336 0.524838C1.85921 0.813714 0.918713 1.66476 0.599504 2.74542C0.0195312 4.70417 0.0195312 8.79093 0.0195312 8.79093C0.0195312 8.79093 0.0195312 12.8777 0.599504 14.8364C0.918713 15.9171 1.85921 16.7327 3.05336 17.0216C5.21789 17.5464 13.8972 17.5464 13.8972 17.5464C13.8972 17.5464 22.5764 17.5464 24.741 17.0216C25.9351 16.7327 26.8756 15.9171 27.1948 14.8364C27.7748 12.8777 27.7748 8.79093 27.7748 8.79093C27.7748 8.79093 27.7748 4.70417 27.1948 2.74542ZM11.0585 12.5014V5.08046L18.3127 8.79102L11.0585 12.5014Z" fill="white" />
        </svg>
      </a>
    </nav> 
    <p class="dark">
      Kontakt os: Tlf.: +45 80 82 08 01 (alle dage 10:00 - 18:00)
      <br />
      &copy; Seez ApS 2022 - <span>{{ t['all_rights_reserved'] }}</span>
      <br />
      CVR: 42847682
    </p>
    
    
    <nav class="about">
      <router-link @click.native="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'About us' })" :to="'/' + $root.localizedRoutes.About.path">{{ t['about_us'] }}</router-link>
      <router-link @click.native="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'About financing' })" :to="'/' + $root.localizedRoutes.Financing.path">{{ t['about_financing'] }}</router-link>
      <router-link @click.native="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'About trade-in' })" :to="'/' + $root.localizedRoutes.TradeIn.path">{{ t['about_trade_in'] }}</router-link>
    </nav>
    <nav class="contact">
      <a @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Support' })" href="https://support.seez.dk" target="_blank" rel="noreferrer">{{ t['support'] }}</a>
      <a @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Report a Bug' })" href="mailto:bug@seez.dk">{{ t['report_bug'] }}</a>
      <a @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Become a Dealer' })" href="mailto:dealer@seez.dk"> {{ t['become_a_dealer'] }}</a>
      <a @click="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Careers' })" href="https://seez.co/careers/" target="_blank" rel="noreferrer">{{ t['careers'] }}</a>
    </nav>
    <nav class="legal">
      <router-link @click.native="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Privacy Policy' })" :to="'/' + $root.localizedRoutes.Privacy.path">{{ t['privacy_policy'] }}</router-link>
      <router-link @click.native="trackEvent($root.analyticsEvents.NAVIGATION, { name: 'Terms and Conditions' })" :to="'/' + $root.localizedRoutes.Terms.path">{{ t['terms_and_conditions'] }}</router-link>
      <span>&nbsp;</span>
      <span class="languages" v-if="multilanguage">
        <button @click="setLanguage('en')">English</button>
        <button @click="setLanguage('da')">Danish</button>
      </span>
      <div />
    </nav>
  </footer>
</template>

<script>
export default {
  computed: {
    t() {
      return this.$root.languageResources.FOOTER_COMPONENT_TRANSLATIONS
    },
    multilanguage() {
      return process.env.VUE_APP_MULTILANGUAGE !== 'false'
    }
  },
  methods: {
    trackEvent(eventName, properties) {
      const userId = this.$root.user != null ? this.$root.user.id : ''
      if(properties.name === 'Support'){
        this.$root.track(this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, { event_name: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, event_category: 'user_engagment',
          event_action: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, event_label:{ user_id: userId} }
        )
      }
      return  this.$root.track(eventName, { 
        event_name: eventName,
        event_label: properties.name,
        event_category: 'user_engagement',
        event_action: {
          iteraction: {
            navigation:{
              type: 'footer_navigation'
            }
          }
        },
         
      })
    },
    setLanguage(lang) {
      localStorage.setItem('language', lang)
      this.$root.language = lang
      this.$root.setLanguage()
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  color: var(--background);
  background-color: #1a2634;
  display: grid;
  padding: 3em var(--content-side-padding-wide) 1em;
  grid-template-columns: 5fr 3fr 3fr 2fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'logo about contact legal'
    'social about contact legal'
    'copy about contact legal';
  gap: 1em;
  align-items: end;

  a {
    color: var(--background);
  }

  .dark {
    grid-area: copy;
    color: #999;
    margin: 0;
    font-size: small;
    line-height: 1.4em;
  }

  .bold {
    font-weight: bold;
  }

  > nav {
    > p {
      text-transform: uppercase;
      font-weight: bold;
    }
    > a {
      display: block;
      margin: 1em 0;
    }
  }

  .social {
    grid-area: social;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    > a {
      margin: 0;
    }
  }

  > img {
    grid-area: logo;
  }

  .about {
    grid-area: about;
    align-self: start;
  }

  .contact {
    grid-area: contact;
  }

  .legal {
    grid-area: legal;
    align-self: start;
  }

  .languages {
    display: flex;
    gap: 1em;

    > button {
      border: none;
      background: none;
      cursor: pointer;
      color: white;
      padding: 0;
      font-family: 'Biennale', sans-serif;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 50rem) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    padding: 3em var(--content-side-padding) 5em;
    grid-template-areas:
      'logo empty'
      'about contact'
      'legal social'
      'copy copy';
    gap: 1em 3em;
    align-items: start;

    > nav > a {
      margin: 0.5em 0;
    }

    > .social {
      gap: 1em 2em;
      justify-content: space-evenly;
      align-self: center;
    }

    > .dark {
      justify-self: center;
    }
  }

  @media print {
    display: none;
  }
}
</style>
