<template>
  <div class="checkout">
    <check-out :params="'?order=' + $route.params.id" v-if="$root.SDKLoaded" :lg="$root.language" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" />
