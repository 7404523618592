<template>
  <label for="clipBtn" class="clipboard">
    <div>
      <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.3594 2.14062C22.2656 1.04688 20.8594 0.5 19.4531 0.5C18.0469 0.5 16.6406 1.04688 15.5469 2.14062L13.8672 3.82031C14.4531 4.13281 15 4.52344 15.5078 4.99219C15.625 5.10938 15.7422 5.26562 15.8203 5.38281L17.3047 3.89844C17.8906 3.35156 18.6328 3 19.4531 3C20.2734 3 21.0156 3.35156 21.6016 3.89844C22.1484 4.48438 22.4609 5.22656 22.4609 6.04688C22.4609 6.86719 22.1484 7.60938 21.6016 8.19531L16.4062 13.3906C15.8203 13.9375 15.0781 14.25 14.2578 14.25C13.4375 14.25 12.6953 13.9375 12.1094 13.3906C11.5625 12.8047 11.2109 12.0625 11.2109 11.2422C11.2109 10.4219 11.5234 9.67969 12.0703 9.09375L12.2656 8.97656C12.1875 8.82031 12.0703 8.66406 11.9531 8.54688C11.6406 8.19531 11.1719 8 10.7031 8C10.2344 8 9.80469 8.19531 9.45312 8.50781C8.28125 10.6172 8.55469 13.3516 10.3516 15.1484C11.4453 16.2422 12.8516 16.75 14.2578 16.75C15.6641 16.75 17.0703 16.2422 18.1641 15.1484L23.3594 9.95312C24.4922 8.82031 25 7.375 24.9609 5.89062C24.9609 4.52344 24.4141 3.19531 23.3594 2.14062ZM9.14062 15.6562L7.65625 17.1406C7.07031 17.6875 6.32812 18 5.50781 18C4.6875 18 3.94531 17.6875 3.35938 17.1406C2.8125 16.5547 2.5 15.8125 2.5 14.9922C2.5 14.1719 2.8125 13.4297 3.35938 12.8438L8.55469 7.64844C9.14062 7.0625 9.88281 6.75 10.7031 6.75C11.5625 6.75 12.2656 7.10156 12.8516 7.64844C13.3984 8.23438 13.7109 8.97656 13.7109 9.79688C13.7109 10.6172 13.3984 11.3594 12.8516 11.9453L12.6953 12.0625C12.7734 12.2188 12.8906 12.375 13.0078 12.4922C13.3203 12.8438 13.7891 13 14.2578 13C14.7266 13 15.1562 12.8438 15.5078 12.5312C16.6797 10.4219 16.4062 7.6875 14.6094 5.89062C13.5156 4.79688 12.1094 4.25 10.7031 4.25C9.29688 4.25 7.89062 4.79688 6.79688 5.89062L1.60156 11.0859C0.507812 12.1797 0 13.5859 0 14.9922C0 16.3984 0.507812 17.8438 1.60156 18.8984C2.69531 19.9922 4.10156 20.5 5.50781 20.5C6.91406 20.5 8.32031 19.9922 9.41406 18.8984L11.0938 17.2188C10.5078 16.9062 9.96094 16.5156 9.45312 16.0469C9.33594 15.9297 9.21875 15.7734 9.14062 15.6562Z"
          fill="white"
        />
      </svg>
      <h5>{{ t['link_copied'] }}</h5>
    </div>
    <button id="clipBtn" @click="$emit('close')">
      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z"
          fill="#0068FF"
        />
      </svg>
    </button>
  </label>
</template>

<script>
export default {
  name: 'ClipboardButton',
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    }
  }
}
</script>

<style lang="scss">
.clipboard {
  display: flex;
  justify-content: space-between;
  background-color: #0068ff;
  border-radius: 32px;
  width: 364px;
  margin: auto;
  padding: 0.4rem 0.4rem;
	cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    color: white;
    padding-inline-start: 1rem;

    > h5 {
      font-size: 1em;
      padding-inline-start: 1rem;
    }
  }

  > button {
    border-radius: 9999px;
    width: 50px;
    height: 50px;
    background-color: white;
    cursor: pointer;
  }
}
</style>
