export const ROUTES_TRANSLATIONS = {
  site_title: 'Seez.dk',
  Home: {
    title: 'Seez - Din online forhandler af næsten nye biler',
    description: 'Hos Seez finder du brugte biler, der er så gode som nye. Se vores udvalg af kvalitetsbiler og find din næsten nye bil'
  },
  Search: {
    path: 'find-brugt-bil',
    title: 'Find din næsten nye bil hos',
    description: 'Alle brugte biler fra Seez er udvalgt med omhu og lever op til vores høje krav til kvalitet. Find din næste bil i Seez store bilunivers'
  },
  Listing: {
    path: 'listing'
  },
  TradeIn: {
    path: 'byttebil',
    title: 'Byt din bil i handlen. Beregn byttepris',
    description: 'Brug din gamle bil, som en del af finansieringen til din nye. Med Seez\' byttehandel får du et tilbud inden for få timer. Ansøg nemt og hurtigt online'
  },
  Financing: {
    path: 'billaan',
    title: 'Bilfinansiering » Få et billån gennem Seez',
    description: 'Finansier din nye bil med et attraktivt lån fra vores samarbejdspartner Santander. Se dine lånemuligheder, og beregn dit billån her'
  },
  Terms: {
    path: 'vilkaar-for-brug',
    title: 'Vilkår og betingelser',
    description: 'Læs Seez Danmarks vilkår og betingelser. Find information om bl.a. ophavsret og ansvarsfraskrivelse.'
  },
  Cookies: {
    // path: '',
    title: 'Cookiepolitik',
    description: 'På Seez.dk anvender vi cookies for at optimere brugeroplevelsen. Du har mulighed for at administrere dine cookiepræferencer.'
  },
  Privacy: {
    path: 'privatlivspolitik',
    title: 'Privatlivspolitik',
    description: 'Privatlivspolitik for Seez Danmark gælder for alle de personlige oplysninger, som indsamles og behandles af Seez.'
  },
  Searches: {
    path: 'mine-soegninger',
    title: 'Mine gemte søgninger',
    description: 'Se din seneste søgninger på Seez, og vend tilbage til de bilmodeller, du tidligere har vist interesse i'
  },
  Favorites: {
    path: 'favoritter',
    title: 'Mine favoritter',
    description: 'Hos Seez kan du gemme de bilmodeller, du er interesseret i under favoritter. Vend nemt og hurtigt tilbage til den rette bil for dig her'
  },
  Account: {
    path: 'konto',
    title: 'Log ind - Min konto',
    description: 'Log ind på din Seez konto og se din søgehistorik, gemte favoritter og ordrehistorik. Find alle dine informationer her'
  },
  Orders: {
    path: 'ordrer',
    title: 'Min ordre - Leveringsstatus og ordrehistorik',
    description: 'Følg din ordre, tjek leveringsstatus og følg ansøgningsproces for billån eller byttehandel på din Seez konto.'
  },
  Details: {
    path: 'listing'
  },
  Intro: {
    path: 'start'
  },
  Why: {
    path: 'hvorfor-seez',
    title: 'Hvorfor Seez? Nemt og bekvemt bilkøb online',
    description: 'Seez er en onlinebaseret forhandler af brugte premiumbiler. Du får altid 1 års fuld garanti på din nye bil og mulighed for prøvekørsel i 14 dage.'
  },
  WhySeez: {
    path: 'hvorfor-seez',
    title: 'Hvorfor Seez? Nemt og bekvemt bilkøb online',
    description: 'Seez er en onlinebaseret forhandler af brugte premiumbiler. Du får altid 1 års fuld garanti på din nye bil og mulighed for prøvekørsel i 14 dage.'
  },
  Quality: {
    path: 'kvalitet'
  },
  Delivery: {
    path: 'levering'
  },
  Return: {
    path: 'retur'
  },
  Warranty: {
    path: 'garanti'
  },
  About: {
    path: 'om-seez'
  }
}

export const FAVORITES_VIEW_TRANSLATIONS = {
  my_favorites: 'Mine favoritter'
}


export const ERROR_VIEW_TRANSLATIONS = {
  error: 'Fejl 404',
  page_not_found: 'Siden blev ikke fundet'
}

export const PROMO_BANNER_COMPONENT = {
  fue_on_us: 'Denne sommer dækker vi benzinen på din nye bil. Køb en bil fra seez og få gavekort på',
  gift_card: '5.000 kr. til brændstof/ opladning',
  valid_month: '* Tilbuddet gælder i juni måned   ',
  tc_apply: ' Der tager forbehold for betingelser',

}

export const FINANCING_VIEW_TRANSLATIONS = {
  finance_your_car: 'Finansiering af din bil',
  spread_the_cost: 'Fordel omkostningerne på din bil over 12 til 96 måneder, <br/> med fleksibel afbetaling',
  check_budget: 'Find ud af, hvad der passer dit budget',
  apply_get_started: 'Du kan ansøge om finansiering, så snart du har valgt din bil og trykket på \'Kom i gang\'. Sådan fungerer det.\'',
  get_quote: 'Få et tilbud',
  use_our_calculator: 'Brug vores beregner til at finde en finansieringsplan, som passer dig. Udbetalingen kan være helt ned til 0 DKK, eller du kan bruge værdien af din byttebil som udbetaling',
  finance_partner: 'Vores finansieringspartner',
  get_clearer_picture: 'Få et klart billede af, hvad du har råd til',
  use_loan_calculator: 'Brug vores låneberegner til at beregne den månedlige udgift med forskellige udbetalinger.',
  ready_get_finance: 'Klar til at finansiere din nye bil?',
  pay_with_finance: 'Når du har valgt din nye bil, så vælg \'Betal med finansiering\' under betaling.',
  search_all_cars: 'Søg alle biler',
  cars: 'biler',
  how_it_works: 'Sådan fungerer det',
  apply_online: 'Ansøg online',
  choose_the_terms: 'Vælg de vilkår, der passer dig, og udfyld vores enkle låneansøgning, mens du er ved at købe din nye bil.',
  get_a_decision: 'Få en godkendelse',
  receive_a_decision: 'Få svar på din ansøgning inden for 24 timer - eller kortere, hvis du søger inden for normal arbejdstid.',
  popularQuestions: {
    title: 'Populære spørgsmål om finansiering',
    accordions: [
      {
        id: 'aboutSeez',
        title: 'Hvad er Seez?',
        text: [`Seez er en ny måde at købe en næsten ny bil på online. Vores platform giver dig mulighed for at søge gennem et væld af næsten nye biler fra forhandlere i hele Danmark. Når du har valgt den bil, du vil ha', kan du ansøge om finansiering, bytte din gamle bil ind i handlen og underskrive alle kontrakter hjemme fra din lænestol.
        Vi har dermed gjort bilkøb til en hurtig og problemfri proces. Og ikke nok med det: din bil bliver leveret til døren og derefter har du 14 dage til at beslutte, om du vil beholde den - eller returnere den, uden nogen stiller spørgsmål!`]
      },
      {
        id: 'accidentedCars',
        title:'Sælger I biler, der har været involveret i en ulykke?',
        text: [`Nej. Vi har sat de højeste standarder for vores biler, som ikke kan opfyldes af biler, der har været involveret i ulykker.  
        Enhver bil, der har været i alt fra et kofangerkys til en totalskade, bliver automatisk afvist af vores Selection Experts og kommer aldrig til salg på vores platform.`]
      },
      {
        id: 'isPriceNegotiable',
        title: 'Kan man handle med jer?',
        text: [`Når du køber en bil fra Seez, køber du også vores viden og erfaringer med at vurdere den rigtige pris. Så selvom vi ikke er til at handle med, så tør vi godt love, at du får din bil til den bedst mulige pris hos os. 
        Desuden er noget af det bedste ved at købe online, at det er hurtigt, effektivt og helt uden diskussion om prisen.`]
      },
      {
        id: 'testDrive',
        title: 'Kan jeg prøvekøre jeres biler?',
        text: [`Prøvekørsler hører fortiden til. At køre en tur på 20-30 minutter er simpelthen ikke nok til at vurdere bilen grundigt. I stedet giver vi dig 14 dage til at gennemteste den alle vegne på alle veje under flere vejforhold - alene eller med hele familien.
        Er der noget du er utilfreds med, tager vi bilen tilbage inden for 14 dage og giver dig fuld refusion, forudsat at bilen ikke har kørt mere end 500 km i perioden og naturligvis er ubeskadiget eller ændret på nogen måde.`]
      }
    ]
  }
}

export const HOME_VIEW_TRANSLATIONS = {
  buy_your_almost_new_car: 'Køb håndplukkede og kvalitetstestede biler online fra Danmarks bedste forhandlere ',
  with_complete_confidence: 'Fri levering til døren og 14 dages testkørsel',
  make: 'Mærke',
  model: 'Model',
  search: 'Søg',
  search_all_cars: 'Søg alle biler',
  inspire_me: 'Få inspiration',
  shop_by_car_type: 'Søg på biltype',
  bought_a_car_from_seez: 'Har du købt en bil hos Seez?',
  tell_us_what_you_think: 'Fortæl os, hvad du syntes',
  our_certified_dealers: 'Vores premium brands',
  browse_by_lifestyle: 'Søg efter livsstil',
  unsure_what_you_are_looking_for: 'Usikker på, hvad du leder efter?',
  find_inspiration_here: 'Få inspiration her.',
  see_all: 'Se alle',
  budget: 'Økonomisk',
  family_life: 'Familievenlig',
  outdoor_adventure: 'Udendørs eventyr',
  single: 'Et liv i luksus',
  car_financing: 'Finansiering',
  finance_calculator: 'Finansieringsudregner',
  get_a_car_loan_easily: 'Vi har gjort det nemt og effektivt at få et billån. Hos Seez kan du ansøge om finansiering online og få den mest favorable rente inden for 24 timer',
  learn_more: 'Byttebil med Seez',
  trade_in_valuation: 'Byttebil',
  have_a_car_to_trade_in: 'Har du en bil, der skal indgå i handlen?',
  plug_car_get_best_offer: 'Indtast oplysninger om din nuværende bil og få tilsendt et tilbud på din byttebil. Ingen stress, bindende tilbud eller skjulte gebyrer',
  little_extra_for_peace_of_mind: 'A Little Extra for Some Peace of Mind',
  car_care: 'Car care',
  want_to_give_car_the_love: 'Want to give your new car the love it deserves?',
  car_care_gives_confidence: 'Car Care gives you everything you need to keep your almost new car almost new for as long as possible. Don\'t just buy with confidence, drive with confidence',
  servicing: 'Servicing',
  time_for_car_maintenance: 'Time for your car\'s annual maintenance run?',
  service_center:
    'Get your almost new car serviced at our servicing centers and let us pamper the both of you. Sit back, sip on your coffee, &amp; let our experts keep your car the same perfect bundle of joy that you bought',
  warranty: 'Warranty',
  one_year_not_enough: 'One year not enough?',
  extend_warranty: 'Extend your warranty at purchase to a full lifetime. Never worry again, we\'ve got you covered',
  recently_added_cars: 'Senest tilføjede biler',
  check_out_the_newest_cars: 'Shop fra vores udvalg af kvalitetsbiler',
  browse_all_vehicles: 'Søg alle biler',
  finance_with_seez: 'Se finansieringsmuligheder',
  trade_with_seez: 'Lær om byttebil',
  our_customer_reviews: 'Det siger vores kunder',
  finance_provided_by: 'Finansierring leveret af'
}

export const MODAL_UNAVAILABLE_CARS_TRANSLATIONS = {
  car_is_not_available: 'Denne bil er ikke længere tilgængelig',
  we_got_similar_cars: 'Vi har lignende biler, du kan vælge imellem.',
  back_home: 'Gå til forsiden',
  view_similar: 'Se lignende biler',
  we_will_notify_you: 'Vi giver dig besked, når vi har en ny tilsvarende bil, eller hvis denne bil blive tilgængelig igen.',
  browse_all: 'Browse all'
}

export const JOURNEY_INTRO_VIEW_TRANSLATIONS = {
  welcome: 'Velkommen',
  you_found_perfect_car: 'Du har fundet din perfekte bil, og er nu klar til at komme igang',
  during_your_checkout: 'Under din checkout',
  you_fill_personal_details: 'Udfyld dine personlige oplysninger',
  you_add_trade_in: 'Tilføj en byttebil, hvis du har en, ved at tilføje nogle detaljer og billeder',
  you_decide_how_apply_finance: 'Du bestemmer selv, hvordan du vil betale',
  you_add_your_date: 'Tilføj din leveringsadresse og foretrukne leveringsdato',
  your_car_is_reserved: 'Your car is reserved',
  what_happens_next: 'Hvad sker der  derefter?',
  trade_in: 'Byttebil',
  receive_offer_one_day:
    'Har en byttebil, så vil du indenfor 24 timer modtage et tilbud fra forhandleren. Det er helt op til dig om du accepterer prisen, fortsætte uden byttebil eller helt annullere handlen.',
  financing: 'Finansiering',
  you_receive_decision: 'Du vil modtage en email for at ansøge om finansiering, efterfulgt af finansieringsaftalen til digital underskrift.',
  sales_agreement: 'Salgsaftale',
  we_send_you_contract: 'Du vil modtage en endelig slutseddel til digital underskrift.',
  payment: 'Betaling',
  you_transfer_your_payment: 'Gennemfør betalingen via bankoverførsel.',
  delivery: 'Levering',
  we_deliver_your_car: 'Vi leverer din nye bil på din ønskede adresse. Hvis du har tilføjet en byttebil og accepteret tilbuddet, tager vi den med tilbage.',
  things_to_note: 'Bemærk',
  you_free_of_charge: 'Du kan altid ombestemme dig og annullere ordren, helt gratis, selv efter bekræftelse.',
  you_will_not_be_expected_payment: 'Du forventes ikke at foretage nogen betalinger, før du har underskrevet kontrakten.',
  after_delivery: 'Efter levering',
  you_have_fourteen_days: 'Du har 14 dage til at teste bilen og beslutte, om du vil beholde den.',
  start_checkout: 'Start Checkout',
  here_when_you_need_us: 'Her når du har brug for os',
  if_you_need_assistance: `Hvis du på noget tidspunkt har brug for hjælp, kan du kontakte os via livechat-pop-up'en eller efterlade dit nummer, så ringer vi til dig inden for normal arbejdstid 
(alle dage 10:00 - 18:00)`,
  fourteen_days_return: '14 dages fuld returret',
  full_transparency: 'Vi tror på fuld gennemsigtighed.',
  fourteen_days_test_drive: 'Du får hele 14 dage til at prøvekøre din nye bil. Hvis du ikke er tilfreds med bilen, sender du den bare tilbage til os - og vi stiller ingen spørgsmål.',
  one_year_guarantee: '1 års garanti',
  we_believe_in_peace_of_mind: 'Vi tror på "ro i sindet".',
  no_extra_cost_guarantee: 'Du får 1 års fuld garanti på din nye bil. Ingen ekstra omkostninger.',
  quality_assured: 'Kvalitetssikret',
  we_believe_almost_new: 'Vi tror på værdien i "næsten nyt".',
  our_cars_are_certified: 'Vores udvalg af biler kommer udelukkende fra betroede premium partnere. Alle bilerne er certificerede og gennemtestede.',
  free_delivery: 'Fri levering',
  total_convinience: 'Vi tror på total bekvemmelighed.',
  your_car_delivered_to_door: 'Du behøver ikke længere en bil for at købe en ny bil. Din bil bliver leveret direkte til døren.',
  total_to_pay: 'Total udbetaling',
  car_is_not_available: 'Denne bil er ikke længere tilgængelig',
  we_got_similar_cars: 'Vi har lignende biler, du kan vælge imellem.',
  back_home: 'Gå til forsiden',
  view_similar: 'Se lignende biler'
}

export const BENEFITS_COMPONENT_TRANSLATIONS = {
  quality_assurance: 'Kvalitetssikret',
  we_believe_value_almost_new: 'Vi tror på værdien i "næsten nyt"',
  our_selection_of_cars: 'Vores udvalg af biler kommer udelukkende fra betroede premium partnere. Alle bilerne er certificerede og gennemtestede',
  fourteen_day_return: '14 dages fuld returret',
  we_believe_transparency: 'Vi tror på fuld gennemsigtighed',
  you_get_fourteen_days_test_drive: 'Du får hele 14 dage til at prøvekøre din nye bil. Hvis du ikke er tilfreds med bilen, sender du den bare tilbage til os - og vi stiller ingen spørgsmål',
  one_year_warranty: '1 års garanti',
  we_believe_peace_of_mind: 'Vi tror på "ro i sindet"',
  your_car_goes_home_with_full_guaranty: 'Du får 1 års fuld garanti på din nye bil. Ingen ekstra omkostninger',
  free_delivery: 'Fri levering',
  we_believe_full_convenience: 'Vi tror på total bekvemmelighed',
  you_do_not_need_car_to_buy_one: 'Du behøver ikke længere en bil for at købe en ny bil. Din bil bliver leveret direkte til døren',
  why_buy_a_car_from_seez: 'Hvorfor købe en bil fra Seez?'
}

export const HEADER_COMPONENT_TRANSLATIONS = {
  browse: 'Søg',
  trade_in: 'Byttebil',
  financing: 'Finansiering',
  why_seez: 'Hvorfor Seez',
  support: 'Hjælp',
  login: 'Log ind',
  my_searches: 'Gemte søgninger',
  my_favorites: 'Favoritter',
  my_orders: 'Ordrer',
  account_settings: 'Konto indstillinger',
  logout: 'Log ud',
  why_buy_from_seez: 'Hvorfor skal du købe fra Seez',
  our_aim: 'Vores mål hos Seez er at åbne en verden, hvor du kan købe en bil, sælge din gamle og få finansiering og forsikring med fuld tryghed – altsammen i trygge rammer fra dit eget hjem.',
  read_more_about_seez: 'Læs mere om Seez',
  how_it_works: 'Sådan virker det',
  seez_benefits: 'Seez Fordele',
  quality_asured_title: 'Kvalitetssikring fra vores betroede partnere',
  quality_asured_text: 'Vores udvalg af biler kommer udelukkende fra betroede premium partnere. Alle bilerne er certificerede og gennemtestede.',
  return_title: '14 dages retur og tilbagebetaling',
  return_text: 'Du får hele 14 dage til at prøvekøre din nye bil. Hvis du ikke er tilfreds med bilen, sender du den bare tilbage til os - og vi stiller ingen spørgsmål.',
  warranty_title: '1 års garanti',
  warranty_text: 'Du får 1 års fuld garanti på din nye bil. Ingen ekstra omkostninger.',
  free_delivery_title: 'Gratis levering',
  free_delivery_text: 'Du behøver ikke længere en bil for at købe en ny bil. Din bil bliver leveret direkte til døren.',

}

export const LOGIN_COMPONENT_TRANSLATIONS = {
  login: 'Velkommen!',
  please_login: 'Bekræft din email adresse, så du kan gemme din favoritsøgninger og få adgang til din konto.',
  email_address: 'Email adresse',
  accept_eula: 'Jeg bekræfter at jeg have læst og accepteret Seez\'s {tu} og {pp}.',
  terms_use: 'vilkår for brug',
  privacy_policy: 'privatlivspolitik',
  please_verify_code: 'Indtast koden, der er sendt til din email',
  resend_otp: 'Send koden',
  get_otp: 'Få tilsendt adgangskode',
  coming_soon: 'Kommer snart',
  confirm: 'Bekræft',
  go_back: 'Tilbage',
  continue_with_google: 'Fortsæt med Google',
  continue_with_facebook: 'Fortsæt med Facebook',
  marketing_terms: `Ja tak, jeg ønsker, at Seez Aps holder mig opdateret om produkter relevant for
min bil og produkter indenfor deres sortiment, herunder også påmindelser om
varer i min online indkøbskurv, via brev, telefon og elektronisk post (e-mail og
SMS). Seez ApS må også kontakte mig med henblik på udvidelse af mit samtykke til
at omfatte andre produkter og kontaktformer. Jeg accepterer Seezs opbevaring og
behandling af min data som beskrevet i privatlivspolitiken, og at jeg altid kan
tilbagekalde mit samtykke ved at kontakte Seez på e-mail: support@seez.dk`
}

export const FOOTER_COMPONENT_TRANSLATIONS = {
  all_rights_reserved: 'Alle rettigheder forbeholdes',
  about_us: 'Om os',
  about_financing: 'Finansiering',
  about_trade_in: 'Byttebil',
  careers: 'Job hos Seez',
  support: 'Support',
  report_bug: 'Rapporter fejl',
  become_a_dealer: 'Bliv Forhandler',
  manage_subscriptions: 'Manage Subscriptions',
  seez: 'seez.co',
  privacy_policy: 'Privatlivspolitik',
  terms_and_conditions: 'Vilkår og betingelser',
  cookie_policy: 'Cookie politik'
}

export const TRADE_VIEW_TRANSLATIONS = {
  about_trade_in: 'Byttebil',
  trading_your_car_is_easy: 'Ud med det gamle, ind med det nye',
  how_it_works: 'Sådan fungerer det',
  trading_your_car_is_quick: 'Brug din gamle bil som en del af betalingen for den nye.',
  step_one: 'Trin 1',
  fill_in_info: 'Udfyld information om din bil',
  step_two: 'Trin 2',
  get_offer_from_us: 'Få et tilbud fra os inden for en time',
  step_three: 'Trin 3',
  accept_have_the_full_amount: 'Få beløbet modregnet i prisen for din nye bil',
  why_trade_with_seez: 'Hvorfor handle med Seez',
  quick_and_easy_process: 'Hurtig og enkel proces',
  we_will_only_ask_necessary: 'Vi spørger kun om, hvad der er absolut nødvendigt',
  no_extra_hidden_fees: 'Ingen skjulte gebyrer',
  we_deduct_offer_amount: 'Vi trækker præcis det beløb fra prisen på din nye bil, som vi har tilbudt',
  haggle_free: 'Ingen diskussioner, ærlig vurdering',
  we_give_you_best_offer: 'Du får altid vores bedste tilbud',
  easy_hand_over: 'Nem overdragelse',
  we_pick_up_your_trade_in: 'Vi tager din gamle bil med, når vi leverer din nye bil',
  what_to_expect_on_the_day: 'Hvad kan du forvente på selve dagen',
  we_drop_off_your_new_car: 'Din næsten nye bil bliver leveret',
  at_your_door: 'Din bil bliver leveret præcis der, hvor du har bedt om det - hjemme eller på jobbet.',
  our_expert_gives_your_a_quick: 'Din byttebil får et hurtigt tjek',
  inspection: 'Vores ekspert giver din byttebil en hurtig, men grundig gennemgang.',
  we_take_it_from_there: 'Vi klarer resten',
  we_exchange_keys: 'Vi udveksler nøgler og dokumenter og overlader din nye bil til dig.',
  at_the_time_of_delivery: 'Ved leveringen',
  you_should_have_with_you: ' skal du have:',
  a_copy_of_drive_license: 'En kopi af dit kørekort',
  a_copy_of_car_registration: 'En kopi af din registreringsattest',
  your_car_service_history: 'Din bils servicehistorie/servicebog (hvis den findes)',
  a_set_of_keys: 'Mindst ét sæt nøgler',
  any_loan_information: 'Relevant information om eventuelle lån - herunder også dit kontonummer',
  recently_added_cars: 'Senest tilføjede biler',
  browse_all_vehicles: 'Søg alle biler'
}

export const WHY_SEEZ_VIEW_TRANSLATIONS = {
  why_seez: 'Hvorfor Seez',
  buy_a_car_msg: 'Bilkøb, man kan stole på',
  why_is_hard_to_do: 'So why is it so hard to do?',
  about_us: 'Lidt om os',
  at_our_core: 'Vi hos Seez er grundlæggende en flok tech-nørder og bilentusiaster, der konstant diskuterer hvilken halvdel af os, der er vigtigst for denne virksomheds succes!',
  what_is_non_debatable: 'Men det kan ikke diskuteres, at vi alle ønsker at skabe en forretning, der vil give dig en fuldstændig problemfri oplevelse, når du køber bil online.',
  what_make_us_different: 'Hvad gør os anderledes?',
  we_understand: 'Vi forstår dig',
  we_have_all_been_there_msg: 'Vi har alle sammen prøvet at skulle ud at købe ny bil. Vi ved, hvor forvirrende og frustrerende det kan være med alle de beslutninger, det involverer.',
  you_never_question_our_loyalties: 'Du behøver aldrig være i tvivl om vores loyalitet.',
  we_care: 'Vi bekymrer os om dig',
  we_have_our_top_priority: 'Du er den vigtigste for os. Hele vores virksomhed er bygget op omkring at kunne give dig alle tilgængelige oplysninger, så du kan træffe den bedste beslutning.',
  you_never_have_to_hanggle: 'Du behøver aldrig forhandle om prisen igen.',
  we_commit: 'Vi forpligter os',
  we_work_with_partners_who_shares_our_values: 'Vi samarbejder kun med partnere, der deler vores værdier. Vi skaffer de bedste biler på markedet, inspicerer dem selv og tilbyder dig 1 års garanti.',
  you_dont_have_to_worry: 'Du behøver ikke at skulle bekymre dig om manglende kvalitet.',
  we_empower: 'Vi er til rådighed',
  we_are_here_for_you:
    'Vi er her for din skyld. Vores indsats slutter ikke bare, når vi har leveret din bil til dig. Vores medarbejdere og forhandlere er altid til rådighed for at hjælpe med alle dine bilbehov.',
  you_dont_have_to_go: 'Du behøver aldrig mere klare det alene.',
  we_made_our_mission_to_empower:
    'Hos Seez har vi gjort det til vores mission at være til rådighed for dig, så du kan købe din næste bil med fuld tillid. Vi hjælper dig med alt, hvad du har brug for, så du kan glæde dig til at købe ny bil.',
  browse_cars: 'Find din næste bil',
  seez_benefits: 'Seez fordele, du får med enhver bil'
}

export const ORDER_CARD_COMPONENT_TRANSLATIONS = {
  km: 'km',
  price: 'Pris',
  kr: 'kr.',
  order_summary: 'Ordreoversigt',
  restart_order: 'Genstart ordre',
  resume_purchase: 'Genoptag køb',
  cancelled: 'annulleret',
  view_order: 'Se ordre'
}

export const ORDERS_COMPONENT_TRANSLATIONS = {
  my_orders: 'Ordrer',
  cancel_order: 'Annullere ordren',
  current_order: 'Nuværende ordre',
  expires_in: 'udløber om',
  previous_orders: 'Tidligere bestillinger',
  cancel_order_title: 'Annulér ordren',
  cancel_order_description: 'Annullering af denne ordre vil fjerne reservationen og slette alle informationer, der ikke er gemt.<br /><b>Er du sikker på, at du vil fortsætte?</b>',
  cancel_order_confirm: 'Ja, jeg vil annullere ordren',
  cancel_order_abort: 'Tilbage til ordren'
}

export const MODAL_ACTIVE_ORDER_COMPONENT = {
  active_order_change: 'Ændringer til aktiv ordre',
  you_have_active_order: 'Du har allerede en aktiv ordre i gang',
  are_you_sure_cancel: 'Er du sikker på, at du vil annullere din nuværende ordre, og starte på en ny?',
  are_you_sure_cancel_active_order: 'Er du sikker på, at du vil annullere din nuværende ordre, og starte på en ny?',
  price: 'Pris',
  cancel_start_new_order: 'Annuller og start ny ordre',
  resume_order: 'Genoptag ordre'
}

export const MODAL_EMAIL_ERROR_COMPONENT = {
  incorrect_email: 'Incorrect Email',
  wrong_email_message: 'The email you logged in with is not associated with this order. Try logging in with a different one.',
  logout: 'Logout',
  browse_similars: 'Browse similar cars'
}

export const CHECKOUT_COMPONENT_TRANSLATIONS = {
  user_not_found: 'Bruger ikke fundet'
}

export const PROFILE_VIEW_TRANSLATIONS = {
  my_account: 'Min konto',
  account_settings: 'Konto indstillinger',
  my_favorites: 'Favorit-biler',
  my_orders: 'Ordrer',
  my_searches: 'Gemte søgninger',
  my_trade_ins: 'Mine indbytter',
  logout: 'Log ud'
}

export const SEARCHES_VIEW_TRANSLATIONS = {
  saved_searches: 'Gemte søgninger'
}

export const LOGOUT_COMPONENT_TRANSLATIONS = {
  comfirm_logout: 'Er du sikker på, at du vil logge ud?',
  logout: 'Log ud',
  cancel: 'Fortryd'
}

export const META_SEO_CONTENT = {
  default: { title: 'Brugt {brand}', description: 'Brugte {brand} til salg » Stort udvalg af brugte biler' }
}

export const TERMS_OF_USE = {
  title: 'Vilkår for brug',
  full_text: `      <ol>
        <li>
          Vilkårene
          <ol>
            <li>Disse vilkår og betingelser (”Vilkårene”) er gældende, når Kunderne (”Kunder” eller ”Du”) bruger vores website www.seez.dk (”Website”), der giver adgang til Seez-markedspladsen (”Markedspladsen”) til køb af biler fra vores forhandlingspartnere (”Forhandlere”). Vilkårene kan til enhver tid blive ændret, og du bedes derfor tjekke nærværende vilkår, når du køber en bil (”Bilen”) via Seez.</li>
            <li>Sørg for at læse Vilkårene grundigt igennem, inden du køber din bil. Vi anbefaler, at du gemmer en kopi af Vilkårene til senere brug.</li>
            <li>Når du har accepteret Vilkårene, accepterer du at modtage Vilkårene, aftalen (som defineret nedenfor) og alle andre oplysninger, der relaterer sig til salget af Bilen, elektronisk og i læsbart format, f.eks. pdf.</li>
          </ol>
        </li>
        <li>
          Hvem er vi?
          <ol>
            <li>Websitet drives af Seez ApS (enten ”Vi”, ”Os” eller ”Seez”), en virksomhed, der er registreret i Danmark under CVR-nummer 42847682 og med hjemstedsadresse på Højbro Plads 10, 1200 København.</li>
            <li>Hvis du ønsker at kontakte os eller har spørgsmål vedrørende Bilen eller den af Seez leverede tjenesteydelse, bedes du kontakte os. Du kan finde vores kontaktoplysninger på https://support.seez.dk/.</li>
          </ol>
        </li>
        <li>
          Seez Aps’ rolle i forbindelse med aftalen mellem dig og partnerne
          <ol>
            <li>Seez stiller Markedspladsen til rådighed for kunden til formidling af køb af biler og andre tjenesteydelser, kundeservice og levering af de varer og ydelser, der tilbydes på Markedspladsen.</li>
            <li>Forhandlere, finansieringspartnere, forsikringsselskaber og andre af de leverandører, vi samarbejder med, benævnes tilsammen partnerne (”Partnerne”) i nærværende vilkår.</li>
            <li>Du kan ikke købe en bil gennem os, hvis det sker som led i erhvervsvirksomhed, eller hvis Bilen ikke udelukkende er beregnet til personlig brug.</li>
            <li>Du skal desuden være bosiddende i Danmark for at kunne købe en bil gennem Markedspladsen.</li>
            <li>
              Når din ordre på Seez-markedspladsen er blevet bekræftet (”Ordrebekræftelsen”), genereres der en juridisk bindende aftale (”Aftalen”) mellem dig og den respektive forhandler:
              <ol>
                <li>Aftalen er baseret på de oplysninger, der er anført i ordren</li>
                <li>Aftalen skal underskrives af begge parter, inden forhandleren har foretaget overdragelse af ejendomsretten</li>
                <li>Hvis der er uoverensstemmelse mellem Aftalen og Vilkårene, gives Aftalen forrang.</li>
              </ol>
            </li>
            <li>Hvis du vælger at betale for Bilen ved hjælp af en finansieringsaftale hos en långiver (som defineret i punkt 9 nedenfor), skal du indgå en aftale med långiveren, hvorefter den relevante forhandler overdrager ejendomsretten i henhold til Aftalen mellem kunden og långiveren. Du kan finde yderligere oplysninger i punkt 9.</li>
            <li>Forhandlerne er uafhængige af Seez. Seez er således ikke ansvarlig for eventuelle uoverensstemmelser eller fejl i Aftalen mellem kunden og forhandleren.</li>
            <li>
              Det kan ind imellem ske, at vi efter at have accepteret din ordre bliver klar over, at vi ikke er i stand til gå videre med Aftalen, og du vil i så fald få besked via e-mail. Eksempler på grund til dette kan bl.a. være:
              <ol>
                <li>Den bil, du har valgt, er allerede blevet solgt</li>
                <li>Der er fejl i prisen eller beskrivelsen af Bilen</li>
                <li>Du skal være opmærksom på, at der kan være andre grunde end de herover anførte til, at vi ikke kan acceptere din ordre.</li>
              </ol>
            </li>
            <li>For klarhedens skyld gemmer Seez en kopi af både ordren og Aftalen i sin database.</li>
          </ol>
        </li>
        <li>
          Din bil
          <ol>
            <li>Bilen kan have været brugt før som leasing- eller udlejningsbil og kan have haft flere forskellige brugere. Den kan også være importeret fra Tyskland eller andre lande, der opfylder de danske regler. Alle biler på Markedspladsen er højst fire år gamle og har et kilometertal på højst 100.000 km. Bilerne har været igennem en række test og eftersyn og er derfor dokumenteret rene, pålidelige og sikre.</li>
            <li>Hver bil får foretaget et grundigt eftersyn hos den respektive forhandler, der leverer Bilen. Du finder en nøjagtig beskrivelse i den aftale, der er indgået med forhandleren.</li>
            <li>Vi bestræber os bedst muligt for at vise Bilen nøjagtigt, herunder eventuelle skader, men vi kan ikke garantere, at billederne af Bilen på Markedspladsen viser alle dele af Bilen eller Bilens farve. Billederne af Bilen på Markedspladsen leveres af forhandleren. Hvis du ikke er tilfreds med Bilens faktiske udseende, kan du annullere købet ved at benytte vores 14-dages tilbagebetalingsgaranti (se yderligere oplysninger i punkt 10).</li>
            <li>Det kilometertal, der står anført på Markedspladsen for Bilen, kan variere lidt fra den leverede bils faktiske kilometertal. Differencen i kilometertallet vil højst være 200 kilometer.</li>
            <li>Du kan kontakte os inden afgivelse af ordren og stille eventuelle spørgsmål til Bilen. Du finder vores kontaktoplysninger på https://support.seez.dk/.</li>
          </ol>
        </li>
        <li>
          Levering
          <ol>
            <li>Vi leverer Bilen til dig på det leveringssted og det leveringstidspunkt, der er anført i Ordrebekræftelsen (”Levering”).</li>
            <li>Seez leverer til adresser i Danmark (”Jylland”, ”Fyn”, ”Sjælland” og ”Brofaste øer”). Hvis leveringsadressen er på en ikke-brofast ø, eller hvis stedet er specielt vanskeligt at komme til på grund af visse vejr- eller vejforhold i løbet af året, forbeholder Seez sig ret til at afvise Levering.</li>
            <li>Du kan ændre leveringstidspunkt og -dato ved at sende en e-mail til vores kundeserviceteam på support@seez.dk mindst 24 timer inden det aftalte leveringstidspunkt. Vi ændrer leveringsdato og -tidspunkt for dig, uden at det koster ekstra.</li>
            <li>Du kan også annullere ordren uden nogen begrundelse i op til 24 timer, inden Bilen leveres, ved at sende en e-mail til vores kundeserviceteam på support@seez.dk.</li>
            <li>Vi leverer kun Bilen til dig på det leveringssted, der er anført i ordren. Ved Levering skal du fremvise gyldigt id (dansk kørekort eller kopi af pas).</li>
            <li>Vi skal bruge et sikkert sted til at parkere Bilen (f.eks. i en indkørsel eller sikker parkering på gaden), mens vi foretager leveringskontrol sammen med dig. Hvis du sælger en byttebil til os, skal du også sørge for, at den er parkeret sikkert, og at der er adgang til den. Det er dit ansvar at give os oplysninger om et sikkert sted, og du er ansvarlig for enhver udgift, straf eller bøde, vi måtte pådrage os som følge af parkering på et sikkert sted efter dine anvisninger.</li>
            <li>
              Du ejer Bilen, når:
              <ol>
                <li>Du har accepteret Bilens Levering ved at underskrive følgesedlen</li>
                <li>Vi har accepteret og modtaget byttebilen, såfremt du vælger at bruge en byttebil som delvis betaling (se punkt 8 for yderligere information)</li>
                <li>Forhandleren har modtaget fuld betaling for det skyldige beløb og eventuelle andre beløb, du skylder os</li>
                <li>Hvis du køber Bilen ved hjælp af en finansieringsaftale, ejer tredjepartsfinansieringsselskabet Bilen indtil det tidspunkt, hvor ejendomsretten overdrages i henhold til finansieringsaftalen.</li>
              </ol>
            </li>
            <li>Du har ansvar for Bilen fra Levering.</li>
          </ol>
        </li>
        <li>
          Betaling
          <ol>
            <li>Det skyldige beløb for Bilen er anført i den ordrebekræftelse, der modtages, når ordren er blevet færdigbehandlet på Markedspladsen.</li>
            <li>Ordrebekræftelsen omfatter gældende skattemæssige afgifter. Miljøskat og/eller vægtafgift, der ikke er forfalden, er ikke inkluderet i prisen.</li>
            <li>Det skyldig beløb kan kun betales via bankoverførsel til den bankkonto som er oplyst.. I øjeblikket accepterer vi ikke andre betalingsformer.</li>
            <li>Hvis ordrebekræftelsen anfører et beløb, der skal betales til dig (hvis byttebilens værdi overstiger udbetalingsbeløbet), bedes du kontakte vores kundeserviceafdeling på support@seez.dk for at høre om dine muligheder: Du kan enten</li>
            <li>justere udbetalingen, så den passer til byttebilens værdi, eller</li>
            <li>få det udestående restbeløb refunderet.</li>
          </ol>
        </li>
        <li>
          Garanti
          <ol>
            <li>Garantien for din nye bil leveres af forhandleren. Bilen leveres med mindst 1 års garanti. Men afhængig af Bilen kan den være længere. Garantien er en omfattende forhandlergaranti.</li>
          </ol>
        </li>
        <li>
          Byttebil
          <ol>
            <li>Hvis du vælger at bruge en byttebil som delvis betaling, skal du oplyse om byttebilen på Seez-markedspladsen som en del af din ordre. Vi kan til enhver tid bede dig om yderligere oplysninger om byttebilen inden Levering.</li>
            <li>Vi foretager en vurdering af byttebilen, når du bekræfter din ordre på Seez-markedspladsen. Du skal give retvisende, nøjagtige og opdaterede oplysninger til os om byttebilen.</li>
            <li>Byttebilen bliver efterset ved Levering af din nye bil for at sikre, at de oplysninger, du gav ved afgivelse af ordren, er retvisende og nøjagtige. Vi er også nødt til at sikre os, at byttebilen er køreklar og ikke har haft skader, som du ikke har oplyst om.</li>
            <li>
              Hvis byttebilen er købt ved hjælp af en finansieringsaftale, accepterer vi din bil, selvom der stadig er restgæld i Bilen. Du kan:
              <ol>
                <li>Betale restgælden i Bilen, inden vi henter den</li>
                <li>Meddele os det nøjagtige restbeløb, når du afgiver din ordre, i afsnittet for byttebil, så lægger vi beløbet til det skyldige beløb, og vi sørger for betaling af restgælden til långiveren på dine vegne.</li>
              </ol>
            </li>
            <li>Hvis Bilen stadig er under finansiering, og restgælden er højere end byttebilens værdi, kan vi ikke acceptere byttebilen.</li>
            <li>
              Ved Levering i forbindelse med byttebilen skal du aflevere:
              <ol>
                <li>Alle kopier af byttebilens nøgler</li>
                <li>Byttebilens seneste registreringsbevis og dokumentation for betaling af skatter og afgifter (sammen med relevant købsbevis, hvis vi anmoder om det)</li>
                <li>Byttebilens servicebog (hvis den findes)</li>
                <li>Byttebilens brugermanual (hvis den findes)</li>
                <li>Eventuelt ekstraudstyr, såsom låsemøtrikker til hjulene, instrumentpanel til radio, fjernbetjeninger, SD-kort og/eller opladningskabler (hvis du har en plugin-bil)</li>
                <li>Hvis du har en byttebil med udestående tinglyst bank gæld, skal du udlevere et bevis på det udestående beløb for tinglystingen på din byttebil. F.eks. seneste lån opgørelse fra din bank</li>
              </ol>
            </li>
            <li>
              Vi kan til enhver tid afvise eller genoverveje tilbuddet på en byttebil op til 14 dage efter afhentningstidspunktet, hvis:
              <ol>
                <li>De oplysninger, vi modtager vedrørende byttebilen, ikke er retvisende og nøjagtige</li>
                <li>Byttebilen har fået skader, der ikke er oplyst til os ved afgivelse af ordren</li>
                <li>Der er væsentlige uoverensstemmelser i de oplysninger og dokumenter, du har afleveret i forbindelse med byttebilen, eller væsentlige dokumenter mangler, er unøjagtige eller ufuldstændige (f.eks. hvis registreringsattesten mangler eller er ufuldstændig)</li>
                <li>Byttebilen har fejl, der kan få indflydelse på byttebilens værdi, eller den ikke er køreklar</li>
                <li>Byttebilen har været involveret i en større ulykke eller har været totalskadet</li>
                <li>Byttebilen er blevet ændret hvad angår udseende eller funktion</li>
                <li>Du ikke har retsevne til at indgå en bindende aftale om salg af byttebilen</li>
                <li>Du ikke er eneejer af byttebilen</li>
                <li>Du har undladt at informere om, at en anden person eller enhed har et krav mod byttebilen, eller at der er restgæld i byttebilen</li>
                <li>Du ikke står anført som den registrerede ejer af byttebilen på registreringsattesten</li>
                <li>Byttebilen ikke er registreret i Danmark</li>
                <li>Byttebilen ikke har sit retlige registrerings- og stelnummer</li>
                <li>Kilometertælleren på byttebilen står på mere end 1.500 kilometer over det kilometertal, der er anført i ordren</li>
                <li>Byttebilen er stjålet eller indberettet til retshåndhævende myndigheder</li>
              </ol>
            </li>
            <li>Vi henviser til 8.6 og 8.7 som betingelserne for byttebil.</li>
            <li>Eventuel miljøafgift og/eller vægtafgift, der ikke er betalt for byttebilen, betales af kunden, indtil Seez har accepteret byttebilen.</li>
            <li>
              Hvis byttebilen ikke opfylder betingelserne for byttebil, kan du:
              <ol>
                <li>Vælge at fortsætte med ordren, der så vil være afhængig af en ny vurdering af byttebilen. Du vil blive bedt om at underskrive en kontraktændring ved Levering, hvor du accepterer en ekstrabetaling på baggrund af den nye vurdering. Vi sender dig en e-mail senest syv dage efter den nye vurdering.</li>
                <li>Vælge at fortsætte med ordren uden byttebil. I så fald kan din nye bil ikke leveres, før den nye ordre er blevet færdigbehandlet, og eventuel udstående ekstrabetaling er afregnet. Hvis du efterfølgende vælger at annullere ordren, skal der betales et leveringsgebyr på 2.500 kr.</li>
                <li>Vælge at annullere ordren ved betaling af et leveringsgebyr på 2.500 kr., og du vil derfor ikke få leveret din nye bil.</li>
              </ol>
            </li>
            <li>Hvis vi accepterer byttebilen og senere opdager, at byttebilen ikke opfylder de i dette punkt anførte betingelser, kan vi tage skridt til at tilbagesøge nedsættelsen i byttebilens værdi som følge af, at byttebilen ikke opfylder betingelserne i nærværende punkt (tilbagebetalingskrav). Du kan også på forlangende blive pålagt at betale alle de rimelige udgifter og omkostninger, vi måtte pådrage os i forbindelse med tilbagebetalingskravet.</li>
            <li>Du har ansvar for byttebilens stand, indtil vi har accepteret bilen som byttebil ved leveringen. Såfremt der sker skader i denne periode, er kunden forpligtet, og vi er berettiget, til at genforhandle bytteprisen eller afvise byttebilen.</li>
            <li>Du skal fjerne alle personlige ejendele fra byttebilen, inden vi henter den. Vi påtager os intet ansvar for eventuelle personlige genstande, der bortkommer, når byttebilen er kommet i vores besiddelse.</li>
            <li>
              Du har ansvar for at fjerne, slette eller ændre dine personlige oplysninger i byttebilen, inden byttebilen overdrages, herunder:
              <ol>
                <li>Hvis byttebilen er udstyret med GPS, telefoninterface eller andre enheder, hvorpå der kan gemmes data, skal du sørge for at have fjernet alle personlige oplysninger fra disse enheder, inden byttebilen overdrages til os. Dette omfatter kontaktoplysninger (navne og telefonnumre), tidligere destinationer eller favoritdestinationer i din kørehistorik og standard ”hjemadresse”.</li>
                <li>Du skal sørge for, at dit navn og din adresse fjernes fra eller ændres i alle service- eller vedligeholdelsespapirer, som du afleverer sammen med byttebilen.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Finansiering
          <ol>
            <li>Du kan ansøge om finansiering fra en af vores tredjepartsfinansieringsleverandører (långiver) til finansiering af købet af bilen.</li>
            <li>Det er alene långiver, der træffer beslutning vedrørende din ansøgning om finansiering.</li>
            <li>I alle tilfælde skal der betales en udbetaling, der som udgangspunkt udgør mindst 20 % af Bilens listepris.</li>
            <li>For at opnå finansiering skal du underskrive en købsaftale (finansieringsaftale) med långiver inden levering. Forsinkelse i underskrift af finansieringsaftalen kan forsinke leveringen.</li>
            <li>Hvis du ansøger om finansiering, og dette afslås af långiver, skal du betale for Bilen på anden vis. Hvis du ikke er i stand til at betale på anden vis, annullerer vi din ordre, og der er ingen aftale.</li>
            <li>Du må ikke ansøge om finansiering gennem en långiver, hvis det er meningen, at Bilen primært skal bruges af en anden end dig.</li>
            <li>Der henvises til de vilkår og betingelser, der er anført i finansieringsaftalen mellem dig og långiver, hvad angår finansiering af købet af Bilen.</li>
            <li>
              Du har ret til at træde tilbage i overensstemmelse med de vilkår, der er anført i din finansieringsaftale. I Vilkårene for din finansieringsaftale kan du få yderligere oplysninger om din ret til at træde tilbage.
              <ol>
                <li>Hvis du gør brug af din ret til at træde tilbage, træder du kun tilbage fra finansieringsaftalen. Du vil blive pålagt at betale det samlede tilgodehavende inklusive renter til långiveren i henhold til Vilkårene i finansieringsaftalen. Du bedes kontakte långiver for at få yderligere oplysninger om retten til at træde tilbage fra finansieringsaftalen.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Vores 14-dages tilbagebetalingsgaranti
          <ol>
            <li>Du kan returnere Bilen til os senest 14 dage fra leveringstidspunktet og -datoen uden at give os nogen begrundelse ved hjælp af vores 14-dages tilbagebetalingsgaranti (garantien), såfremt Bilen opfylder de betingelse for returnering, der er anført i punkt 10.5 nedenfor.</li>
            <li>Du har ret til at bruge Bilen for at fastslå, om den er det rigtige for dig. Vi anser en sådan brug for at være et par prøveture svarende til det, du ville få, hvis du købte en bil direkte fra en forhandler.</li>
            <li>Hvis du ønsker at returnere Bilen i henhold til garantien, skal du give os besked ved at sende en e-mail til vores kundeserviceteam på support@seez.dk. Kundeserviceteamet vil derefter aftale et tidspunkt, hvor vi henter Bilen hos dig på den samme adresse, hvor Bilen blev leveret.</li>
            <li>
              Hvis du vælger at returnere Bilen i henhold til garantien:
              <ol>
                <li>Hvis du har betalt Bilen fuldt ud i form af kontant betaling via bankoverførsel, betaler vi det forfaldne beløb til den samme bankkonto, der oprindeligt blev brugt til at betale for Bilen, i henhold til de i punkt 12.4.4 anførte vilkår.</li>
                <li>Hvis du har betalt for Bilen gennem en finansieringsaftale, får du betalt den udbetaling, du betalte til os og i henhold til de i punkt 12.4.4 anførte vilkår, og vi tilbagebetaler den resterende købspris til den finansieringsudbyder, du har indgået finansieringsaftalen med.</li>
                <li>Hvis du har solgt en byttebil til os som en del af betalingen for Bilen (herunder som udbetaling på en finansieringsaftale), er vi ikke i stand til at returnere byttebilen til dig. Vi inkluderer et beløb, der svarer til den pris, vi har betalt dig for byttebilen, i det beløb, der skal tilbagebetales til dig (såfremt byttebilen opfylder de i punkt 10 anførte betingelser).</li>
                <li>
                  Vi tilbageholder 4.000 kr. fra det beløb, der tilbagebetales til dig, som anført i punkt 12.3.1, 12.3.2 og 12.3.3, indtil:
                  <ol>
                    <li>Alle de nødvendige dokumenter til os i henhold til punkt 12.8 er blevet returneret</li>
                    <li>Vi modtager bekræftelse på, at Bilen ikke er behæftet med gebyrer eller krav fra eventuelle tredjeparter</li>
                    <li>Vi forventer, at dette vil tage højst to uger</li>
                  </ol>
                </li>
                <li>Vi godtgør eventuelle beløb, vi skylder dig, senest 14 dage efter det tidspunkt, hvor Bilen returneres. Vi tilbagebetaler beløbet via den samme betalingsmetode, som du benyttede til at betale os, og der opkræves ikke gebyr i forbindelse med tilbagebetalingen.</li>
              </ol>
            </li>
            <li>
              Du kan returnere Bilen til os ved hjælp af garantien, såfremt Bilen opfylder følgende betingelser (”Returneringsbetingelser”):
              <ol>
                <li>Bilen har ikke kørt mere end 200 kilometer efter Levering</li>
                <li>Der er ikke foretaget nogen form for ændring eller modificering af Bilen</li>
                <li>Bilen har sammen stand, som da den blev leveret eller hentet (med undtagelse af rimelig slitage)</li>
                <li>Bilen er ikke behæftet med gebyrer eller krav fra tredjeparter, herunder en finansieringsleverandør (med undtagelse af finansiering gennem finansieringsaftalen)</li>
                <li>Bilen har ikke kørt siden det tidspunkt, hvor du gav os besked om ønsket om at returnere Bilen til os</li>
                <li>Vi henviser til nærværende betingelser som Returneringsbetingelserne.</li>
              </ol>
            </li>
            <li>Såfremt Bilen ikke opfylder Returneringsbetingelserne, kan vi efter eget skøn tillade dig at returnere Bilen til os mod betaling af et ekstragebyr (returneringsgebyr), der fastsættes af os.</li>
            <li>Du skal give besked til finansieringsleverandøren om, at du ønsker at træde tilbage fra finansieringsaftalen. Vi informerer ikke finansieringsudbyderen for dig.</li>
            <li>
              Ved returnering af Bilen skal du aflevere:
              <ol>
                <li>Alle kopier af byttebilens nøgler</li>
                <li>Bilens registreringsbevis</li>
                <li>Dokumentation for restgæld</li>
                <li>Bilens servicebog (hvis den findes)</li>
                <li>Bilens brugermanual (hvis den findes)</li>
                <li>Eventuelt ekstraudstyr, såsom låsemøtrikker til hjulene, instrumentpanel til radio eller fjernbetjeninger, SD-kort og/eller opladningskabler (hvis du har en plugin-bil)</li>
                <li>Alle dokumenter eller genstande, du fik udleveret ved Levering af Bilen</li>
              </ol>
            </li>
            <li>Det er dit ansvar at fjerne dine personlige ejendele eller slette personlige oplysninger fra Bilen inden afhentning på samme måde som med en byttebil (se punkt 8).</li>
            <li>Hvis du har brug for at aftale en ny dato og et nyt tidspunkt for afhentning af den returnerede bil, skal du kontakte kundeservice mindst 24 timer inden det tidspunkt, du har aftalt afhentning af Bilen. Du skal give os mulighed for at hente Bilen så hurtigt som muligt og senest syv dage efter, at du har givet meddelelse om annullering.</li>
            <li>Hvis du returnerer Bilen ved hjælp af garantien, giver vi besked til garantiselskabet, og garantiaftalen eller andre serviceaftaler, der er aftalt gennem garantiselskabet, vil automatisk blive opsagt.</li>
          </ol>
        </li>
        <li>
          Databeskyttelse
          <ol>
            <li>Du kan se, hvordan vi bruger dine personlige oplysninger, i vores fortrolighedspolitik her Privacy Policy.</li>
          </ol>
        </li>
        <li>
          Ansvar
          <ol>
            <li>Der er intet i disse vilkår, der fritager os for eller begrænser vores ansvar ved død eller personskader, som opstår i forbindelse med uagtsomhed fra vores side eller bedrageri eller vildledning fra vores side eller ethvert andet ansvar, der ikke kan fritages for eller begrænses ifølge loven.</li>
            <li>I det omfang at vi kan begrænse vores ansvar ifølge loven, er vi ikke ansvarlig over for dig for indirekte eller forbundne tab eller tab af indtægt eller omsætning, nedgang i forretningen, tab af fortjeneste eller tab af forventede besparelser (det være sig direkte eller indirekte).</li>
            <li>I det omfang at vi kan fritages for vores ansvar ifølge loven, fritager vi alle underforståede erklæringer, garantier, vilkår og betingelser fra Aftalen.</li>
            <li>I henhold til ovennævnte kan vores maksimale samlede erstatningsansvar over for dig eller i forbindelse med ordren og Aftalen med dig ikke overstige Bilens købspris.</li>
          </ol>
        </li>
        <li>
          Forhold uden for vores kontrol
          <ol>
            <li>Vi er ikke ansvarlig for manglende evne fra vores side til at opfylde Aftalen eller for forsinkelse i opfyldelse af Aftalen, der skyldes omstændigheder eller begivenheder uden for vores kontrol (forhold uden for vores kontrol). Omstændigheder eller begivenheder, der er omfattet af dette punkt, er bl.a. optøjer, terror- eller krigshandlinger, civile uroligheder, strejke, brand, oversvømmelse, andre naturkatastrofer, pandemier og ulykker med relation til Bilen. Andre omstændigheder eller begivenheder kan også være inkluderet, hvis de ligger uden for vores kontrol.</li>
            <li>Hvis vores Levering af Bilen forsinkes af et forhold uden for vores kontrol, kontakter vi dig hurtigst muligt for at informere dig om det, og vi gør, hvad vi kan, for at mindske forsinkelsen. Hvis vi gør dette, er vi ikke ansvarlig for forsinkelsen. Hvis der opstår en forsinkelsen, kan du kontakte os for at opsige Aftalen og få det depositum eller den købspris, du på daværende tidspunkt betalte for Bilen, refunderet.</li>
            <li>Hvis der skulle opstå forhold uden for vores kontrol, som har konsekvenser for opfyldelse af Aftalen efter, at vi har leveret Bilen til dig, giver vi dig hurtigst muligt besked. Vores forpligtelser over for dig i henhold til Aftalen vil blive midlertidigt forsinket, og det aftalte tidspunkt for opfyldelsen af vores forpligtelser vil blive forlænget, indtil forholdene uden for vores kontrol er slut.</li>
          </ol>
        </li>
        <li>
          Navne og varemærkerettigheder
          <ol>
            <li>Enhver oplysning, design, data, samtykke og anden software i forbindelse med intellektuelle ejendomsrettigheder og lignende tilhører til enhver tid udelukkende Seez ApS eller vores partnere. Indholdet er beskyttet ifølge dansk lovgivning, og overtrædelse af intellektuelle ejendomsrettigheder tilhørende Seez ApS eller vores partnere kan medføre civilretlige samt strafferetlige foranstaltninger og sanktioner.</li>
            <li>Du må ikke overdrage dine rettigheder eller forpligtelser i henhold til Aftalen til andre. Vi kan overdrage vores rettigheder eller forpligtelser i henhold til Aftalen til andre, såfremt det ikke påvirker dine rettigheder i henhold til Aftalen.</li>
          </ol>
        </li>
        <li>
          Diverse
          <ol>
            <li>Hvis en domstol finder dele af Aftalen ulovlig, ugyldig eller på anden vis uden retskraft, vil de øvrige dele af Aftalen fortsat være i kraft og gældende.</li>
            <li>Aftalen giver ikke anledning til rettigheder, der kan håndhæves af personer, som ikke er part i Aftalen.</li>
            <li>Hvis vi på noget tidspunkt undlader at udøve eller håndhæve en rettighed i henhold til nærværende vilkår over for dig, forhindrer det os ikke i at gøre det på et senere tidspunkt.</li>
            <li>Aftalen udgør hele Aftalen mellem dig og os i forbindelse med købet af Bilen. Aftalen erstatter alle tidligere drøftelser eller aftaler mellem dig og os i forhold til købet af Bilen.</li>
          </ol>
        </li>
        <li>
          Tvister
          <ol>
            <li>I tilfælde af at der skulle opstå tvister på baggrund af de tjenester, der leveres på Seez-platformen, eller andre problemer på baggrund af forholdet mellem brugeren og Seez, anbefaler vi dig at kontakte os, så vi kan finde en mindelig løsning.</li>
            <li>Hvis tvisten ikke kan afgøres ved hjælp af en mindelig løsning, kan brugeren indgive en klage til Konkurrence- og Forbrugerstyrelsen, Center for Klageløsning, Carl Jacobsens Vej 35, 2500 Valby. Klagen kan indsendes elektronisk via www.forbrug.dk. Kunder kan desuden klage til EU’s klageportal på www.ec.europa.eu/odr.</li>
            <li>Såfremt tvisten ikke kan løses ved de herover nævnte instanser, skal den afgøres i henhold til dansk lov og af domstolene i Danmark med Københavns Byret som værneting.</li>
          </ol>
        </li>
      </ol>`
}

export const QUALITY_PAGE = {
  title: 'Kvalitetssikring fra vores betroede partnere',
  p1: 'Vi har sat vores standarder så højt, at de kun kan opfyldes af et særligt udvalg af biler fra betroede forhandlere - biler der er næsten nye.',
  p2: 'Og vi tilbyder kun de biler, der passer til den standard.',
  almostNewCar: {
    title: 'Hvad er en næsten ny bil?',
    textArray: ['Næsten nyt er et koncept, som vi tror fuldt og fast på.', 'Det er et løfte vi giver dig om kun at levere biler i premium kvalitet, der er så tæt på perfekte, at de næsten er nye.'],
    listTitle: 'Enhver næsten ny bil',
    listItems: [
      'Den er ikke mere end 7 år gammel',
      'Har kørt mindre end 125,000 km',
      'Har aldrig været i en ulykke',
      'Har minimum et års garanti'
    ]
  },
  historyChecks: {
    title: 'Service historie',
    textArray: ['Alle biler har service historik som beskrevet.', 'Derudover gennemgår vi hverenkelt grundigt for at sikre dig at din nye bil giver dig ro i sindet og sikkerhed i trafikken.'],
    listItems: [
      'Kommer med service historik som beskrevet',
      'Er for nylig serviceret jævnfør bilfabrikantens specifikationer',
      'Er gennemgået af leverings specialister',
      'Er kontrolleret af en uddannet mekaniker'
    ]
  },
  needUsText: [
    'Vores næsten nye biler er håndplukket fra de bedste forhandlere i hele landet. Tag endelig kontakt hvis du vil vide mere.',
    'Vi tilbyder kun de bedste brugte biler og garanterer dig at bilen lever op til vores standard. Alle vores biler kommer med 14 dages fuld returret og minimum 1 års garanti.'
  ],
  popularQuestions: {
    title: 'Populære spørgsmål om vores kvalitets sikring',
    accordions: [
      {
        id: 'testDrive',
        title: 'Kan jeg prøvekøre jeres biler?',
        text: [
          'Prøvekørsler hører fortiden til. At køre en tur på 20-30 minutter er simpelthen ikke nok til at vurdere bilen grundigt. I stedet giver vi dig 14 dage til at gennemteste den alle vegne på alle veje under flere vejforhold - alene eller med hele familien.',
          'Er der noget du er utilfreds med, tager vi bilen tilbage inden for 14 dage og giver dig fuld refusion, forudsat at bilen ikke har kørt mere end 500 km i perioden og naturligvis er ubeskadiget eller ændret på nogen måde.'
        ]
      },
      {
        id: 'imperfections',
        title: 'Hvordan kan jeg se eventuelle skader på en bil, før jeg køber den?',
        text: ['Vores biler er så tæt på nye som muligt. Men hvis de har skrammer, buler eller lignende, vil det være fremhævet og tydeligt på billederne.']
      },
      {
        id: 'condition',
        title: 'Hvad nu hvis bilen ikke er i den stand, jeg havde forventet?',
        text: [
          'Vi opretholder det højeste niveau af ærlighed og gennemsigtighed. Vi gør alt, hvad vi kan for at sikre, at bilens stand er korrekt og præcist beskrevet.',
          'Men hvis du er så uheldig at få leveret en bil fra os, der ikke er i overensstemmelse med den forventede stand, så finder vi ud af at få det udbedret - eller også returnerer du bilen inden for 14 dage efter de gældende regler.'
        ]
      },
      {
        id: 'history',
        title: 'Er der servicehistorie til bilerne?',
        text: ['Ja. Alle biler på vores platform er serviceret efter fabrikkens forskrifter og standarder.']
      }
    ]
  }
}

export const DEALERS_SECTION = {
  title: 'Vi har teamet op med de bedste bilforhandlere så vi kan garantere den bedste kvalitet'
}

export const FOOTER_CARDS = {
  warranty: {
    title: '1 års garanti',
    description: 'Du får 1 års fuld garanti på din nye bil. Ingen ekstra omkostninger.',
    icon: 'shield'
  },
  return: {
    title: '14 dages fuld returret',
    description: 'Du får hele 14 dage til at prøvekøre din nye bil. Hvis du ikke er tilfreds med bilen, sender du den bare tilbage til os - og vi stiller ingen spørgsmål.',
    icon: 'repeat'
  },
  quality: {
    title: 'Garanteret kvalitet',
    description: 'Vores udvalg af biler kommer udelukkende fra betroede premium partnere. Alle bilerne er certificerede og gennemtestede.',
    icon: 'medal'
  }
}

export const PAGE_FOOTER = {
  inYourHands: {
    title: 'Når bilen er i dine hænder',
    textArray: ['Mens du har de 14 dage til at prøvekøre din nye bil, skal du vide at alle vores biler kommer med 14 dages fuld returret samt minimum et års garanti.']
  },
  peaceOfMind: {
    title: 'Få ro i sjælen',
    textArray: ['Vi tilbyder kun de bedste brugte biler og garanterer dig at bilen lever op til standard.', 'Alle vores biler kommer med 14 dages gratis returret og minimum 1 års garanti.']
  },
  needUs: {
    title: 'Vi er her når du har brug for os'
  },
  carSearch: {
    text: 'Er du klar?',
    title: 'Lad os finde din næste bil'
  }
}

export const FREE_DELIVERY = {
  title: 'Gratis levering',
  headerText: ['Søg rundt omkring i hele landet, og køb din drømmebil med absolut ingen bekymring om hvor bilen står henne. Vi leverer nemlig din næsten nye bil lige til døren.'],
  covidText: 'For en sikkerheds skyld, afspritter vi bilen inden aflevering.',
  deliveryMap: {
    title: 'Hvor leverer vi til?',
    textArray: [
      'Vi tilbyder fri levering alle steder i Danmark, på nær til ikke brofaste øer som Bornholm, samsø og fanø.',
      'Vi vil altid yde vores bedste for at levere din bil til døren.',
      'Dog kan der forekomme lejligheder hvor dette ikke er muligt. I sådanne tilfælde vil vi gøre alt for at finde den bedste mulige løsning til dig.'
    ]
  },
  behindTheScenes: {
    title: 'Bag kulissen',
    listItems: [
      'Når ordren er bekræftet, vil forhandleren klargøre din bil så den er klar til levering.',
      'Det kan tage op til 8 dage, til at få registreringen samt forsikring klar til dig.',
      'Vores leverings eksperter henter så bilen, og kører den til vores lokale mekaniker hvor han kontrollerer at alt lever op til vores næsten nye bil standard. For eksempel hvis din bil har mindre end 5000 km til den skal have lavet service, afleverer vi selvfølgelig din bil nyserviceret.'
    ]
  },
  deliveryDay: {
    title: 'Leveringsdagen',
    text: 'Leveringen tager ikke mere end 20 minutter. Inden bilen overleveres skal vi dog se en form for ID og have en underskrift så vi er sikre på vi det er dig vi overleverer den til.',
    textArray: [
      'Vi ringer til dig når vi er på vej, for at sikre at du er hjemme så leveringen kan forløbe så glidende som muligt.',
      'Vores eksperter har viden om enhver lille detalje på din bil, både indeni og udenpå. Han vil give dig en komplet gennemgang af din næsten nye bil, samt besvare alle dine spørgsmål.',
      'Når din bil er leveret, følger alt dette med:'
    ],
    listItems: ['Registreringsattesten', 'Garanti certifikat', 'En fuld opdateret service bog']
  },
  tradeIn: {
    title: 'Byttebilen',
    text: 'Hvis du køber en bil, og har taget din gamle bil med i handlen, tager vi din gamle bil med retur og afleverer den til forhandleren. På denne måde vil du ikke stå uden nogen bil, og kan med det samme køre på vejene i din næsten nye bil.',
    buttonText: 'Læs mere om byttebil'
  },
  documents: {
    title: 'De nødvendige dokumenter',
    listItems: ['For en god og sikker handel, skal vi se billede dokumentation når vi leverer bilen.', 'Hvis din gamle bil er med i handlen, skal vi bruge følgende ting:'],
    cards: ['Registreringsattesten på byttebilen', 'Servicebogen', 'Mindst et sæt nøgler', 'Bilens manuel hvis den haves']
  },
  needUsText: [
    'Vores team er her for dig hvert et step der er. Vores telefon er åben og vi er klar til altid at besvare et hvert spørgsmål du måtte have.',
    'Vi er altid glade for at besvare eventuelle spørgsmål, du har.'
  ],
  popularQuestions: {
    title: 'Populære spørgsmål om fri levering',
    accordions: [
      {
        id: 'someoneElse',
        title: 'Kan en anden modtage min bil?',
        text: [
          'Selvfølgelig forstår vi, at du kan blive forhindret i at modtage bilen. Men vi kan ikke levere den til andre end dig. Du skal være til stede, vise ID og kvittere skriftligt for modtagelsen.',
          'Desuden gør vi rigtig meget ud af at overdrage bilen til dig - og det bør du bestemt ikke gå glip af.'
        ]
      },
      {
        id: 'timeSlot',
        title: 'Kan jeg vælge et foretrukket tidsrum for leveringen?',
        text: ['Ja, du kan bestille leveringen inden for et 2-timers tidsrum.']
      },
      {
        id: 'flatBed',
        title: 'Bliver bilen leveret på en autotransporter eller bliver den kørt?',
        text: ['Din bil bliver leveret på en af vores autotransportere - nyvasket samt fuldstændig rengjort og desinficeret indvendigt.']
      },
      {
        id: 'badCar',
        title: 'Hvad nu hvis jeg ikke kan li\' bilen, når den bliver leveret?',
        text: [
          'Hvis du af en eller anden grund ikke kan li\' din næsten nye bil, skal du kontakte vores kundesupport indenfor 14 dage, så henter vi bilen og giver dig pengene tilbage. Sådan.',
          'Har du byttet din gamle bil ind, kan vi ikke returnere den, men du kan beholde det fulde beløb, vi tilbød.'
        ]
      }
    ]
  }
}

export const RETURN_PAGE = {
  title: '14 dages retur og tilbagebetaling',
  headerText: [
    'Vi mener ikke at prøvekøre en bil i 30 minutter er nok til at mærke efter om det er en bil for dig. Tag den med hjem og se om den passer ind i dit liv, så du er 100% sikker på, at det er den bil du skal have.',
    'Og hvis det ikke er den, sender du den bare tilbage. Du skal blot anmode om en returnering indenfor 14 dage, og du får dine penge tilbage.'
  ],
  returnCar: {
    title: 'Hvordan returneres bilen',
    text: 'Returnering af bilen er sikkert og nemt. Du skal kun gøre to ting'
  },
  returnSteps: {
    step1: 'Step 1: Kom i kontakt med vores kundeservice',
    phoneText: 'Du kan ringe til vores agenter på +45 80 82 08 01 mellem 10 – 18',
    emailText: 'E-mail Support@seez.dk og udfyld vores returseddel',
    buttonText: 'Hent retur form',
    step2: 'Step 2: Vores agenter vil arrangere bilen afhentning indenfor 2 arbejdes dage'
  },
  finePrint: {
    title: 'Det med småt',
    text: 'En 14 dages prøvetur lyder måske for godt til at være sandt, men det får du hos os. Der er dog enkelte forudsætninger du skal være opmærksom på, se nærmere herunder:',
    listItems: [
      'Vi kan acceptere enhver bil, der har været i almindelig brug hos dig.',
      'Vi kan ikke acceptere bilen, hvis der er kørt mere end 200 km i de 14 dage.',
      'Vi kan ikke acceptere en bil, hvis den har været involveret i en ulykke eller blevet beskadiget på nogen måde.',
      'Vi kan ikke acceptere en bil der er blevet ændret eller på anden vis gjort noget ved.'
    ]
  },
  refunds: {
    title: 'Refundering',
    p1: 'Når vi har tjekket bilen og bekræftet at din bil kan returneres, starter vores agenter returneringsprocessen.',
    p2: 'Din tilbagebetaling vil blive udstedt på samme måde, som du har betalt for bilen. Hvis du har søgt om finansiering, har du 14 dages henstandsperiode til at annullere ansøgningen. Visse ansøgningsgebyrer kan dog muligvis ikke refunderes.',
    cards: [
      {
        title: 'Bank overførsel',
        description: 'En fuld refundering vil blive tilbage overført inden for 7 arbejdsdage.',
        icon: 'coin'
      },
      {
        title: 'Finansiering',
        description: 'Vi informerer Santander og de vil kontakte dig for at starte processen. Din udbetaling vil blive fuldt refundering til din bankkonto.',
        icon: 'bank'
      }
    ]
  },
  note: {
    title: 'Obs ved byttebil:',
    text: 'Som det ser ud lige nu, kan vi ikke give dig din byttebil tilbage, hvis du vælger at returnere din nye bil. Men det beløb vi tilbød for din byttebil, vil blive refunderet til dig.'
  },
  needUsText: [
    'Vores team er her for dig hvert et step der er. Vores telefon er åben og vi er klar til altid at besvare et hvert spørgsmål du måtte have.',
    'Vi er altid glade for at besvare eventuelle spørgsmål, du har'
  ],
  popularQuestions: {
    title: 'Populære spørgsmål om 14 dages returret',
    accordions: [
      {
        id: 'collectCar',
        title: 'Henter I bilen gratis?',
        text: ['Ja, vi henter din bil samme sted, som vi leverede den til, gratis.', 'For flere detaljer, se vilkår og betingelser.']
      },
      {
        id: 'howLong',
        title: 'Hvor lang tid varer det, før I henter bilen?',
        text: [
          'Når du er sikker på, at du vil returnere bilen, så skriv til os på support@seez.dk',
          'Inden for en arbejdstime bliver du ringet op for at aftale tid og sted for afhentning. Bilen bliver efterfølgende afhentet inden for 72 timer.'
        ]
      },
      {
        id: 'stayHome',
        title: 'Behøver jeg at være hjemme, når bilen bliver hentet?',
        text: ['Ja, du er nødt til at være til stede, så du kan bekræfte afhentningen skriftligt, når vi har godkendt bilen til returnering.']
      },
      {
        id: 'responsible',
        title: 'Hvem har ansvaret for at forsikre bilen, indtil den er afhentet?',
        text: ['Det er dit ansvar at sørge for at bilen er forsikret, indtil den er afhentet.']
      }
    ]
  }
}

export const WARRANTY_PAGE = {
  title: '1 års garanti',
  headerText: [
    'Når du køber en næsten ny bil fra os betyder det ingen kompromis. Vores biler kommer direkte fra de bedste forhandlere på tværs af hele landet, og de kommer alle med minimum 1 års garanti.',
    'Vi vil gerne have at du ved, at vi dækker dig.'
  ],
  whatIsWarranty: {
    title: 'Hvad er garanti ?',
    textArray: [
      'Når du køber en bil fra Seez kan du være sikker på at det er en god investering. Vi håndplukker de bedste biler fra de bedste forhandlere i hele landet. Vi kontrollerer bilerne grundigt og giver 1 års garanti sammen med evt. resterende fabriksgaranti.',
      'Og vi garanterer at så længe bilen er under garanti, vil forhandleren udbedre eventuelt opståede fejl, helt uden beregning.'
    ]
  },
  servicing: {
    title: 'Service på din nye bil',
    textArray: [
      'Alle vores biler bliver leveret med service udført i nyere tid. Vi sikrer os at din nye bil ikke skal have service de næste 5.000 km du kører i den.',
      'Når det er tid til service vil din bil selv lyse i servicelampen i bilens display. Så ringer du bare til nummeret i din service manual og aftaler et autoriseret service så du kan beholde bilens garanti.',
      'Hvis din bil lyser for service inden du har kørt 5.000 km i den, tager du bare fat i os, så sørger vi for at få bilen serviceret. Du kan ringe til os alle dage imellem 10.00 og 18.00 på 80820801 eller sende en mail til support@seez.dk'
    ]
  },
  needUsText: ['Hvis du er i tvivl om, at din bil er blevet serviceret, kan du altid spørge vores leverings specialist eller ringe til os.', 'Vi er altid glade for at besvare eventuelle spørgsmål, du har.'],
  popularQuestions: {
    title: 'Populære spørgsmål om 1 års garanti',
    accordions: [
      {
        id: 'insurance',
        title: 'Er forsikring inkluderet?',
        text: [
          'Vi har endnu ikke fundet den rigtige forsikringspartner og kan derfor ikke tilbyde dig fordelagtige præmier. Det arbejder vi i øjeblikket hårdt på at rette op på. Så du skal selv vælge et forsikringsselskab.'
        ]
      },
      {
        id: 'certifications',
        title: 'Hvilke dokumenter følger der med bilen?',
        text: ['Din bil leveres med:'],
        list: ['Registreringsattest', 'Garanti certifkat', 'Fuldt opdateret servicebog']
      },
      {
        id: 'serviceBook',
        title: 'Hvor er min bils servicebog?',
        text: [
          'Hvis din bil har en fysisk servicebog, ligger den i handskerummet. Er den elektronisk får du adgang til den via bilens skærm. I begge tilfælde vil den medarbejder, der leverer bilen, vise dig, hvor du finder den, hvor du kan se sidste servicedato med km-tal samt hvor du kan se, hvornår bilen skal til service igen.'
        ]
      },
      {
        id: 'service',
        title: 'Hvordan ved jeg, hvornår min bil skal til service?',
        text: ['Din bils manual beskriver detaljeret, hvornår bilen skal til service. Samt hvilke arbejder, der skal udføres ved de forskellige services.']
      }
    ]
  }
}

export const ABOUT_SEEZ = {
  title: 'Om Seez',
  headerText: [
    'Seez er en helt ny måde at købe biler fuldt ud online.',
    'Vores mål er at hjælpe dig med at låse op for en verden hvor du kan købe en bil, bytte din gamle bil ind, og få finansering og forsikring med fuld tillid og ro i sindet - alt sammen gjort fra dine trygge omgivelser derhjemme.'
  ],
  needUsText: [
    'Vores næsten nye biler er håndplukket fra de bedste forhandlere i hele landet. Tag endelig kontakt hvis du vil vide mere.',
    'Vi er glade for alle henvendelser og spørgsmål du må have.'
  ],
  popularQuestions: {
    title: 'Popular questions about quality assured',
    accordions: [
      {
        id: 'testDrive',
        title: 'Can I test drive the car?',
        text: [
          'Test drives are a thing of the past. Taking a car for a 30 minute spin is an inefficient way to test it. Instead, we offer you 14 days to take the car home and take your time assessing it in any way you think is best - be it driving it on different terrains, in varying weather conditions, or simply squeezing your entire family into it and driving around the block.',
          'As long as the car is undamaged, unaltered, and hasn\'t been driven more than 500 km, we\'ll take it back and give you a full refund should you choose to return it within the 14 day limit.'
        ]
      },
      {
        id: 'imperfections',
        title: 'How can I see any imperfections on a car before I complete my purchase?',
        text: ['Our almost new cars are as close to new as possible. However, if any scratches or dents do exist, we will be upfront and highlight them in the photos provided.']
      },
      {
        id: 'condition',
        title: 'What if my car isn’t in the condition I expected',
        text: [
          'Our promise is to uphold the highest levels of honesty and transparency. We will do everything we can to ensure that the condition of the car is reported factually and accurately.',
          'However, if for any reason you receive a car that does not match the expected condition, we will be there to support you every step of the way regardless of whether you choose to fix it or return it - as long as the return is within the 14 day period.'
        ]
      },
      {
        id: 'history',
        title: 'Do your cars come with a service history?',
        text: ['Yes. All the cars on our platform have been serviced in line with manufacturer recommendations and standards.']
      }
    ]
  },
  customerTeamTitle: 'Mød nogen af vores kunde mestre',
  team: {
    Amanda: {
      occupation: 'Kundeoplevelses Specialist',
      quote: '“Jeg elsker at bruge mine kundeservice evner til at hjælpe folk med at opdage noget der kunne gøre deres liv bedre”'
    },
    Elisa: {
      occupation: 'Kundeoplevelses Specialist',
      quote: '“Det bedste ved mit job er at møde nye mennesker og udforske hvad de egentlig vil have, så jeg kan levere den glæde til dem.”'
    },
    Kristian: {
      occupation: 'Kunde Service Manager',
      quote: '“Kunde oplevelser handler om den oplevelse vi leverer til vores kunder, og jeg sigter altid efter at levere den bedste oplevelse overhovedet muligt.”'
    },
    Nikki: {
      occupation: 'Leverings Specialist',
      quote: '“Hele min opvækst har omhandlet biler, mekanik og transport. At kunne levere kvalitetsbiler og kunne give tilfredse kunder en grundig gennemgang af deres nye biler giver mig et smil på læben hver dag.”'
    }
  },
  review: {
    title: 'Vi ledte efter ny familiebil og faldt…',
    text: 'Vi ledte efter ny familiebil og faldt over Seez.dk De havde et stort udvalg af biler og da vi havde brug for hjælp til at finde frem til den bil som passede til vores familie, stod deres kundeservice klar med det samme. Vi kan klart anbefale dem!',
    date: 'For 7 dage siden'
  },
  ourPromise: {
    title: 'Vores løfte til dig',
    description:
      'Når du køber en næsten ny bil hos os, køber du ikke kun kvalitet, men også vores forpligtelse - om at vi vil gøre alt hvad vi kan for at give dig en bilkøbsoplevelse, der er gennemskuelig og lige til for dig.',
    cards: [
      {
        id: 'Delivery',
        title: 'Fri Levering',
        text: 'Du kan søge rundt omkring i hele landet og købe din drømmebil med absolut ingen bekymringer om hvor bilen står henne.'
      },
      {
        id: 'Return',
        title: '14 dages fuld returret',
        text: 'Vi mener ikke at prøvekøre en bil i 30 minutter er nok til at mærke efter om det er en bil for dig. Vi giver dig 14 dage.'
      },
      {
        id: 'Quality',
        title: 'Kvalitetssikret fra betroede partnere',
        text: 'Vi har sat vores standarder så højt, at de kun kan opfyldes af et særligt udvalg af biler fra vores betroede forhandlere.'
      },
      {
        id: 'Warranty',
        title: '1 Års Garanti',
        text: 'At købe en næsten ny bil fra os betyder det komplet ro i sindet. Vores biler kommer med minimum 1 års garanti.'
      }
    ],
    readMore: 'Læs Mere'
  },
  news: {
    title: 'Læs omkring os i nyhederne',
    pressRelease: 'Læs artikel'
  }
}

export const WHY_SEEZ = {
  title: 'Hvordan fungerer det?',
  headerText: [
    'Vi har alle været der. Vi ved, hvor forvirrende og stressende det kan være at købe en bil– alle de beslutninger, og små detaljer der er involveret.',
    'Så vi har gjort det til vores mission at gøre det let at købe din næste bil med tillid. Vi har gjort det så enkelt og bekvemt, at når du køber en bil hos os, føler du kun glæden ved en bil.'
  ],
  needUsText: [
    'Vores næsten nye biler er håndplukket fra de bedste forhandlere i hele landet. Tag endelig kontakt hvis du vil vide mere.',
    'Vi er glade for alle henvendelser og spørgsmål du må have.'
  ],
  popularQuestions: {
    title: 'Populære spørgsmål om hvordan det fungere',
    accordions: [
      {
        id: 'seez',
        title: 'Hvad er Seez?',
        text: [
          'Seez er en ny måde at købe en næsten ny bil på online. Vores platform giver dig mulighed for at søge gennem et væld af næsten nye biler fra forhandlere i hele Danmark. Når du har valgt den bil, du vil ha\', kan du ansøge om finansiering, bytte din gamle bil ind i handlen og underskrive alle kontrakter hjemme fra din lænestol. Vi har dermed gjort bilkøb til en hurtig og problemfri proces. Og ikke nok med det: din bil bliver leveret til døren og derefter har du 14 dage til at beslutte, om du vil beholde den - eller returnere den, uden nogen stiller spørgsmål!'
        ]
      },
      {
        id: 'accident',
        title: 'Sælger I biler, der har været involveret i en ulykke?',
        text: [
          'Nej. Vi har sat de højeste standarder for vores biler, som ikke kan opfyldes af biler, der har været involveret i ulykker. Enhver bil, der har været i alt fra et kofangerkys til en totalskade, bliver automatisk afvist af vores Selection Experts og kommer aldrig til salg på vores platform.'
        ]
      },
      {
        id: 'negotiable',
        title: 'Kan man handle med jer?',
        text: [
          'Når du køber en bil fra Seez, køber du også vores viden og erfaringer med at vurdere den rigtige pris. Så selvom vi ikke er til at handle med, så tør vi godt love, at du får din bil til den bedst mulige pris hos os. Desuden er noget af det bedste ved at købe online, at det er hurtigt, effektivt og helt uden diskussion om prisen.'
        ]
      },
      {
        id: 'testDrive',
        title: 'Kan jeg prøvekøre jeres biler?',
        text: [
          'Prøvekørsler hører fortiden til. At køre en tur på 20-30 minutter er simpelthen ikke nok til at vurdere bilen grundigt. I stedet giver vi dig 14 dage til at gennemteste den alle vegne på alle veje under flere vejforhold - alene eller med hele familien. Er der noget du er utilfreds med, tager vi bilen tilbage inden for 14 dage og giver dig fuld refusion, forudsat at bilen ikke har kørt mere end 500 km i perioden og naturligvis er ubeskadiget eller ændret på nogen måde.'
        ]
      }
    ]
  },
  buyBrowse: {
    title: 'Søg & køb en bil helt online',
    cards: [
      {
        title: 'Hurtigt',
        text: 'Udfyld en hurtig 20 minutters ansøgning, der fortæller os, hvem du er, hvordan du vil betale, og om du har en byttebil med i handlen.'
      },
      {
        title: 'Praktisk',
        text: 'Underskriv alle dokumenterne hjemmefra, og vi leverer din bil lige til døren.'
      },
      {
        title: 'Sikkert',
        text: 'Du har 14 dage til at beslutte dig. Vælger du at returnere bilen, refunderes alt, hvad du har betalt for bilen 100 %.'
      }
    ]
  },
  review: {
    text: 'Fantastisk service. Jeg er meget imponeret over den venlighed og kompetence der er at finde hos Seez og deres medarbejdere.',
    customer: 'Gite'
  },
  howItWorks: {
    title: 'Hvordan fungerer det?',
    text: 'Find din drømmebil og køb den helt uden besvær og ingen skjulte gebyrer.',
    steps: [
      {
        id: 'search',
        image: 'find',
        title: 'Find den perfekte bil',
        textArray: [
          'Se vores brede udvalg af kvalitetsbiler lige ved hånden.',
          'Vores biler kommer fra premium-forhandlere og kontrolleres af vores inspektionsteam. Alle vores biler kommer med minimum 1 års garanti og 14 dages fuld pengene tilbage garanti.',
          'Vores biler er prissat nøjagtigt, som du ville have fået det fra forhandlerne for at sikre, at du altid får den største værdi.'
        ],
        linkText: 'Søg biler'
      },
      {
        id: 'quality',
        image: 'reserve',
        title: 'Vi reserverer bilen for dig',
        textArray: [
          'Når du har sendt din ansøgning, reserverer vi bilen til dig.',
          'Vores ansøgning er nem og tager ikke mere end 20 minutter. Vi vil bede om:',
          '1. Få personlige oplysninger',
          '2. Detaljer om din byttebil, hvis den skal ingå i handlen',
          '3. Din foretrukne betalingsmetode - vi kan hjælpe dig med at ansøge om finansiering, hvis det er nødvendigt',
          '4. Din ønskede leveringsadresse'
        ],
        linkText: 'Tjek vores kriterier for kvalitetssikring'
      },
      {
        id: 'delivery',
        image: 'deliver',
        title: 'Leveret til døren',
        textArray: [
          'Modtag din bil hvor som helst efter eget valg.',
          'Når din ordre er gennemført, kan du bare læne dig tilbage, slappe af og vente på, at din nye bil kommer til dig. Vi leverer bilen stort set overalt i Danmark - så længe du er der (med ID) for at modtage den.',
          'Hvis du har tilføjet en byttebil, giver vi den en hurtig inspektion og transporterer den til dens nye hjem.'
        ],
        linkText: 'Læs om gratis levering'
      },
      {
        id: 'return',
        image: 'decision',
        title: '14 dage til at beslutte dig',
        textArray: [
          'Vi giver dig 14 dage til at prøvekøre din bil og beslutte, om du kan lide den.',
          'Tag dig tid, kør den rundt i byen, tag din familie med på en tur, eller sæt dig i trafikken og test sædernes komfortniveau. Så længe du ikke beskadiger den, modificerer den eller kører over vores maksimale kilometergrænse (200km), kan du returnere den og få fuld refusion.',
          'Hvis du har søgt om finansiering, vil din aftale blive annulleret og din udbetaling refunderet.'
        ],
        linkText: 'Læs om 14 dages tilbagebetalingsgaranti'
      }
    ]
  },
  finance: {
    cards: [
      {
        id: 'Financing',
        title: 'Finansier en brugt bil',
        text: 'Ansøg om finansiering hos vores bankpartner. Du skal blot vælge den månedlige betalingsmuligheder, der passer til dig, så vil vi give dig en svar inden for en arbejdsdag.',
        providedBy: 'Finansiering fra'
      },
      {
        id: 'TradeIn',
        title: 'Byt din gamle bil',
        text: 'Udfyld en hurtig ansøgning om din byttebil og få et tilbud online fra vores samarbejdspartnere inden for få timer. Hvis du er tilfreds med tilbuddet, henter vi din byttebil ved levering af din nye bil.'
      }
    ]
  }
}

export const LISTING_DETAILS_COMPONENT_TRANSLATIONS = {
  link_copied: 'Linket er kopieret til clipboard',
  partner_dealer: 'Forhandler',
  show_all_photos: 'Vis alle billeder',
  trade_it_in: 'Byttebil',
  use_your_old_car: 'Brug din gamle bil til at hjælpe med i betalingen af din nye bil',
  learn_about_trade_in: 'Lær mere om indbytning af bil',
  buy_now: 'Kom igang',
  full_price: 'Fuld pris',
  monthly_price: 'Måneds pris',
  money_back: '14 dages fuld returret',
  browse_all_vehicles: 'Søg alle biler',
  get_valuation: 'Få din indbyttevurdering',
  download_brochure: 'Download Brochure',
  brochure: 'Brochure',
  save: 'Gem',
  share: 'Del',
  vat_include: 'Varebil inkl moms',
  vat_plus: 'Varebil plus moms',
  van: 'Varebil',
  cash: 'Kontant',
  financing_from: 'Finansering fra',
  financed_by: 'Finansiering ydet af',
  month: 'Måned',
  monthly_payment_info:
    'Månedlig ydelse 2.721 kr. i 84 mdr. Variabel lånerente 1,26%. Årlige omk. 4,56%. Samlede kreditomkostninger: 32.565 kr. Udbetaling: 48.980 kr. Samlet beløb der skal betales: 228.485 kr.',
  get_started: 'Kom igang',
  login_to_buy: 'Log ind for at købe',
  resume_checkout: 'Genoptag køb',
  car_reserved: 'Bil reserveret',
  reserved: 'Reserveret',
  sold: 'Solgt',
  coming_soon: 'Kommer snart',
  color: 'Farve',
  kilometrage: 'Kilometertal',
  horsepower: 'HK',
  km: 'Kilometertal',
  transmission: 'Gearkasse',
  fuel: 'Brændstof',
  registration: 'Registrering',
  car_financing: 'Bil finansiering',
  finance_your_car: 'Total online-finansiering',
  quick_specs: 'Specifikationer',
  completely_online: 'af din bil',
  we_made_get_loan_easy_msg: ' Vi har gjort det så nemt og effektivt som muligt at få et billån. Med Seez kan du nu ansøge online og få den mest favorable rente inden for 24 timer.',
  enter_your_down_payment: 'Indtast din udbetaling og lånets løbetid for at få et estimat af din månedlige ydelse',
  seez_benefits_you_get: 'Seez fordele du opnår med denne bil',
  quality_assurance: 'Kvalitetssikring',
  we_believe_value_almost_new: 'Vi tror på værdien i "næsten ny"',
  fourteen_days_return: '14 dages fuld returret',
  we_believe_full_transparency: 'Vi tror på fuld gennemsigtighed',
  one_year_warranty: '1 års garanti',
  we_believe_full_peace_mind: 'Vi tror på fuldstændig "ro i sindet"',
  free_delivery: 'Fri levering',
  we_believe_full_convenience: 'Vi tror på total bekvemmelighed',
  general_information: 'Generel information',
  year: 'Årgang',
  range: 'Range',
  fuel_consumption: 'Km/l',
  l_km: 'L/100 km',
  km_l: 'km/L',
  green_tax: 'Grøn ejerafgift',
  fuel_type: 'Brændstof',
  reference_number: 'Referencenummer',
  technical_specs: 'Tekniske specifikationer',
  hk: 'HK',
  acceleration: 'Acceleration (0-100 km/t)',
  interior_material: 'Interior material',
  top_speed: 'Tophastighed',
  airbags: 'Airbags',
  number_of_gears: 'Antal gear',
  abs: 'ABS',
  available: 'Available',
  undisclosed: 'Undisclosed',
  cylinders: 'Antal cylindre',
  dimension_capacity: 'Dimensioner/kapacitet',
  weight: 'Totalvægt',
  max_attachment: 'Max anhænger',
  load_capacity: 'Læssekapacitet',
  number_of_doors: 'Antal døre',
  comfort_technology: 'Komfort og teknologi',
  design: 'Design',
  safety_environment: 'Sikkerhed og miljø',
  equipment_details: 'Udstyrsliste',
  you_might_like: 'Du vil måske være interesseret i',
  equipment: 'Udstyr',
  price: 'Pris',
  description: 'Beskrivelse',
  read_more: 'Læs Mere',
  read_less: 'Skjul',
  free_tradein_notes: 'Ting at notere',
  free_tradein_explanation:
    '<p>Få en hurtig vurdering af din gamle bil så du kan få en ide om hvor meget den bidrager til din nye bil.</p><p>Byttebilsværdien bliver givet af vurderingseksperter fra vores betroede forhandlere.</p><p>Vi giver en bindende byttepris på din byttebil, når vi har alle nødvendige informationer på den.</p>',
  Mercedes: {
    id: 23,
    name: 'Mercedes-Benz',
    main: `Hos Seez ved vi, at Mercedes er indbegrebet af luksus, stilrent design og køreegenskaber af øverste skuffe. Mercedes fåes både som MPV, stationcar, SUV, coupé, hatchback, sedan og cabriolet, og vi forhandler de mest populære modeller, som blandt andet trækker A-klassen, C-klassen og CLK-klassen. Alle modeller, som også findes i en AMG-udgave, hvilken tilbyder en endnu højere ydeevne. 
Mercedes er et bilmærke, som arbejder under et motto om at ”The Best or Nothing”, og det er i høj grad noget, de lever op til. Med en god brugt Mercedes er du med andre ord garanteret en bil i et stilrent design, der oser af karakter og samtidig har en høj ydeevne, som sikrer dig en god køreoplevelse – hver gang.`
  },
  Ford: {
    id: 17,
    name: 'Ford',
    main: `Vi har et bredt udvalg af nyere brugte Ford biler til salg, hvilket betyder, at du har mulighed for at finde netop den næsten nye bil, du er på udkig efter. 
Ford er et bilmærke med en lang historie og dermed også en masse erfaring, når det kommer til at producere biler. Det betyder, at Ford har et stærkt og attraktivt udvalg af biler, som blandt andet rummer Ford Ka, Fiesta, Focus, Kuga samt C-Max og S-Max modellerne. Det har gjort, at Ford er at finde på listen over de mest populære bilmærker i Danmark og er stærkt repræsenteret i alle kategorier – lige fra den mindre bybil til den store SUV.`
  },
  Renault: {
    id: 13,
    name: 'Renault',
    main: `Renault er et fransk bilmærke, som blev etableret i 1898 af Louis Renault, og som efter en stor succes i motorsport slog sig op som storleverandør af især personbiler. 
Renault er fransk ingeniørkunst, når det er allerbedst. I dag står bilmærket bag en lang række livsstilsbiler og varevogne, som har oplevet stor efterspørgsel fra både ind- og udland. Hos Seez forstår vi denne efterspørgsel og har derfor samlet et bredt udvalg af brugte Renault biler af øverste skuffe, som er nøje udvalgt af vores medarbejdere. Det betyder, at du har mulighed for at finde netop den bil, du er på udkig efter.`
  },
  Dacia: {
    id: 30,
    name: 'Dacia',
    main: `Dacia forhandler kvalitetsbiler, som er fyldt med innovative teknologier og praktiske løsninger – alt sammen til skarpe priser. Deres sortiment strækker sig fra den kompakte MPV til den smarte familiebil, som alle er bygget efter et koncept om bundsolid offroad kapacitet. Det betyder dog ikke, at man behøver at gå på kompromis med designet. Dacia er nemlig fyldt med smukke linjer og luksus, som gør køreoplevelsen til en leg. 
Dacia biler er kendt for at være rummelige, så du altid har plads til oppakningen. Eksempelvis får du op til 475 liters bagagerum i den ikoniske Dacia Duster, hvilket betyder, at du er sikret en bil, som dækker de fleste af dagligdagens udfordringer.`
  },
  we_make_your_trade_in_easy: 'Vi gør det let at bytte din gamle bil',
  fill_out_your_details_and_quick: 'Udfyld oplysningerne og vi giver dig en hurtig vurdering.',
  start_valuation: 'Få en pris på din bil',
  learn_more_about_trade_in: 'Lær om byttebil',
  trade_in_your_car: 'Byt din bil nemt med Seez',
  to_get_your_trade_In_price: 'For at få din byttepris, lad os starte din bils nummerplade.',
  car_details_trade_in: 'Bil detaljer',
  fill_in_your_cars_information: 'Udfyld din bils oplysninger så nøjagtigt som muligt.',
  make: 'Mærke',
  model: 'Model',
  variant: 'Variant',
  modelYear: 'Årgang',
  registrationDate: 'Registreringsdato',
  registrationType: 'Registreringstypen',
  more_details: 'Flere detaljer',
  has_your_car_been_in: 'Har din bil været involveret i en ulykke?',
  no: 'Nej',
  yes: 'Ja',
  does_your_car_have_outstanding_financing: 'Er der restgæld i din bil?',
  what_your_car_current_condition: 'Hvad er din bils nuværende stand?',
  poor: 'Dårlig',
  good: 'God',
  average: 'Gennemsnitlig',
  great: 'Næsten perfekt',
  carConditionPoorDef:
    'Dårlig betyder at bilens tilstand er rigtig sløj. Der er væsentlig beskadigelse af både in- og eksteriør. Den trænger til en gennemgribende istandsættelse - måske bør den skrottes.',
  carConditionAverageDef:
    'Gennemsnitlig betyder, at bilens bedste år ligger langt bag den. Den kan køre, men eksteriøret er grimt og slidt og interiøret gammelt og sjusket. Mekanisk er der måske også et par problemer!',
  carConditionGoodDef: 'God betyder, at bilen kører ganske udmærket, men den er ikke helt på toppen længere. Eksteriøret har måske små ridser samt stenslag og interiøret har nogen slitage.',
  carConditionGreatDef:
    'Næsten perfekt betyder, at bilens stand er over gennemsnittet. Eksteriøret er perfekt eller har få mindre ridser og interiøret er rent, uden pletter og uden lugt. Mekanisk kører den uden problemer af nogen art.',
  i_accept_privacy_policy: 'Jeg accepterer Seez Persondata- og cookie-politik',
  get_my_trade_in_value: 'send forespørgsel',
  how_many_keys: 'Hvor mange nøgler har du?',
  number_of_keys: 'Antallet af nøgler',
  what_is_your_email_address: 'Hvad er din e-mail-adresse?',
  we_ll_send_an_email_you: 'Vi sender en e-mail med dit tilbud til dig',
  please_enter_email: 'Indtast venligst din e-mailadresse',
  please_enter_outstanding_amount: 'indtast venligst restgæld',
  thanks_you_for_submitting: 'Tak, fordi du har indsendt din indbytning',
  we_ll_contact_you_via_via_email: 'Vi kontakter dig via e-mail, når vi har et tilbud på din bil',
  done: 'Afslut',
  condition: 'Bilens stand',
  regTypeVanWithoutVat: 'Varebil på gule plader uden moms',
  regTypeVanWithVat: 'Varebil på gule plader med moms',
  regTypePersonal: 'Personbil',
  regTypeLeasing: 'Leasing',
  please_enter_phone: 'Phone number',
  please_enter_your_name: 'Full name'
}
