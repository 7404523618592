<template>
  <transition name="modal-fade">
    <div class="modalListing">
      <div class="title">
        <div>{{ displayTotal }}</div>
        <button @click="close">
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.66992 7.375L14.2969 2.79297L15.2402 1.84961C15.375 1.71484 15.375 1.49023 15.2402 1.31055L14.252 0.322266C14.0723 0.1875 13.8477 0.1875 13.7129 0.322266L8.1875 5.89258L2.61719 0.322266C2.48242 0.1875 2.25781 0.1875 2.07812 0.322266L1.08984 1.31055C0.955078 1.49023 0.955078 1.71484 1.08984 1.84961L6.66016 7.375L1.08984 12.9453C0.955078 13.0801 0.955078 13.3047 1.08984 13.4844L2.07812 14.4727C2.25781 14.6074 2.48242 14.6074 2.61719 14.4727L8.1875 8.90234L12.7695 13.5293L13.7129 14.4727C13.8477 14.6074 14.0723 14.6074 14.252 14.4727L15.2402 13.4844C15.375 13.3047 15.375 13.0801 15.2402 12.9453L9.66992 7.375Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <button @click="prev" class="prev">
        <svg width="14" height="27" viewBox="0 0 14 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.0195 26.6406C13.2539 26.4648 13.3711 26.2305 13.3711 25.9961C13.3711 25.7617 13.2539 25.5273 13.0781 25.3516L2.47266 13.8086L13.0781 2.20703C13.4297 1.85547 13.4297 1.26953 13.0195 0.917969C12.668 0.566406 12.082 0.566406 11.7305 0.976562L0.480469 13.1641C0.128906 13.5156 0.128906 14.043 0.480469 14.3945L11.7305 26.582C12.082 26.9922 12.668 26.9922 13.0195 26.6406Z"
            fill="white"
          />
        </svg>
      </button>
      <img :src="selectedImage" :data-test-id="'listing_images_thumb_' + index" @load="imageLoaded" :class="{ loadingImage: loadingImage }" />
      <button @click="next" class="next">
        <svg width="14" height="27" viewBox="0 0 14 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.980469 26.6406C0.746094 26.4648 0.628906 26.2305 0.628906 25.9961C0.628906 25.7617 0.746094 25.5273 0.921875 25.3516L11.5273 13.8086L0.921875 2.20703C0.570312 1.85547 0.570312 1.26953 0.980469 0.917969C1.33203 0.566406 1.91797 0.566406 2.26953 0.976562L13.5195 13.1641C13.8711 13.5156 13.8711 14.043 13.5195 14.3945L2.26953 26.582C1.91797 26.9922 1.33203 26.9922 0.980469 26.6406Z"
            fill="white"
          />
        </svg>
      </button>
      <div class="thumbsContainer">
        <img
          v-for="(image, i) in imageList"
          :class="selectedImage === image ? 'active thumbs' : 'thumbs'"
          :key="i"
          @click="onClickImage(image, i)"
          :src="getThumbUrl(image)"
          :data-test-id="'listing_images_thumb_' + i"
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalListing',
  props: {
    activeImage: { type: String, required: true },
    activeIndex: { type: Number, required: true },
    imageList: { type: Array, required: true }
  },
  data() {
    return {
      selectedImage: this.activeImage,
      index: this.activeIndex,
      touchStartPoint: null,
      loadingImage: true
    }
  },
  computed: {
    displayTotal() {
      return `${this.index + 1} / ${this.imageList.length}`
    }
  },
  watch: {
    selectedImage() {
      this.loadingImage = true
    }
  },
  mounted() {
    document.addEventListener('touchstart', this.touchStart, false)
    document.addEventListener('touchend', this.touchEnd, false)
    document.addEventListener('keyup', this.keyPressed, false)
  },
  beforeUnmount() {
    document.removeEventListener('touchstart', this.touchStart)
    document.removeEventListener('touchend', this.touchEnd)
    document.removeEventListener('keyup', this.keyPressed)
  },
  methods: {
    close() {
      this.$emit('close', { url: this.selectedImage })
    },
    onClickImage(url) {
      this.selectedImage = url
      this.imageList.map((item, index) => (item === this.selectedImage ? (this.index = index) : null))
    },
    next() {
      if (this.index === this.imageList.length - 1) return

      this.selectedImage = this.imageList[this.index + 1]
      this.index = this.index + 1
      this.$nextTick(() => this.$el.querySelector('.thumbsContainer > .active')?.scrollIntoView({ block: 'nearest', inline: 'nearest' }))
    },
    prev() {
      if (this.index < 1) return

      this.selectedImage = this.imageList[this.index - 1]
      this.index = this.index - 1
      this.$nextTick(() => this.$el.querySelector('.thumbsContainer > .active')?.scrollIntoView({ block: 'nearest', inline: 'nearest' }))
    },
    getThumbUrl(url) {
      return url
    },
    touchStart(e) {
      if (e.changedTouches.length === 1) this.touchStartPoint = { x: e.changedTouches[0].screenX, y: e.changedTouches[0].screenY }
      else this.touchStartPoint = null
    },
    touchEnd(e) {
      if (e.changedTouches.length === 1) {
        const touchEndPoint = { x: e.changedTouches[0].screenX, y: e.changedTouches[0].screenY }
        if (touchEndPoint.x > this.touchStartPoint.x) this.prev()
        else if (touchEndPoint.x < this.touchStartPoint.x) this.next()
        this.touchStartPoint = null
      }
    },
    keyPressed(e) {
      if (e.key === 'ArrowRight') this.next()
      else if (e.key === 'ArrowLeft') this.prev()
      else if (e.key === 'Escape') this.close()
    },
    imageLoaded() {
      this.loadingImage = false
    }
  }
}
</script>

<style lang="scss">
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.4s ease;
}
.modalListing {
  box-sizing: content-box!important;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(16, 23, 31, 0.9);
  backdrop-filter: blur(3px);
  height: calc(100vh);
  padding: 1em;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'title title title'
    'prev main next'
    'thumbs thumbs thumbs';

  > .title {
    grid-area: title;
    display: flex;
    justify-self: stretch;
    > div {
      flex-grow: 1;
    }

    > button {
      border: none;
      background: none;
      cursor: pointer;
      color: white;
    }
  }

  > button {
    border: none;
    background: none;
    font-size: 2em;
    font-weight: bold;
    padding: 0;
    cursor: pointer;
    padding: 1rem;
    outline: none;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  > .prev {
    grid-area: prev;
  }

  > img {
    grid-area: main;
    overflow: hidden;
    border-radius: 0.25rem;
    place-self: center;
    max-height: 100%;
    max-width: 100%;
    filter: blur(0);
    transition: filter 0.5s;

    &.loadingImage {
      filter: blur(0.5em);
    }
  }

  > .next {
    grid-area: next;
  }

  > .thumbsContainer {
    grid-area: thumbs;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    overflow: auto;
    gap: 0.25em;
    margin-bottom: 6rem;
    padding-top: 0.4rem;
    width: 100%;
    
   
    @media (min-width: 41rem) {
      margin-bottom: 0rem;
    }

    @media (min-width: 48rem) {
      padding-bottom: 0rem;
      margin-bottom: 2rem;

    }

    

    @media screen and (max-width: 1024px) {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    > .thumbs {
      height: 5em;
      cursor: pointer;
      border-radius: 0.5em;
      border: 3px solid transparent;

      &.active {
        border-color: #0068ff;
      }
    }
  }

  .activeThumb {
    width: 200px;
  }
}
</style>
