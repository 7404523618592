<template>
  <section class="tradein">
    <img src="@/assets/trade-in.webp" alt="trade-in" />
    <div>
      <h2>{{ t['trade_in_valuation'] }}</h2>
      <p class="big">{{ t['have_a_car_to_trade_in'] }}</p>
      <p>{{ t['plug_car_get_best_offer'] }}.</p>
      <router-link data-test-id="trade_in" @click.native="track('Trade in')" :to="'/' + $root.localizedRoutes.TradeIn.path" aria-label="Trade">
        {{ t['trade_with_seez'] }}
      </router-link>
    </div>
  </section>
</template>

<script>

export default {
  computed: {
    t() {
      return this.$root.languageResources.HOME_VIEW_TRANSLATIONS
    }
  },
  methods: {
    track(name) {
      this.$root.trackCTA(this.$root.analyticsEvents.CTA_CLICK, { interaction: { cta: { name: name } }, payload: 'tradeIn' })
    }
  }
}
</script>

<style lang="scss">
.home > section.tradein {
  margin: 8em var(--content-side-padding);

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4em;
  place-items: center;

  @media screen and (max-width: 50rem) {
    margin: 2rem var(--content-side-padding);
    padding: 3rem 0;
  }

  > div {
    > h2 {
      color: black;
      font-size: 1.75rem;
    }

    > .big {
      font-size: 1.5em;
      font-weight: 500;
      margin: 0;
    }

    > a {
      display: inline-block;
      border: 1px solid var(--highlight);
      background-color: transparent;
      padding: 0.75em 1.5em;
      border-radius: 2em;
      color: var(--highlight);
      margin: 1em 0;
      font-weight: 600;

      &:hover {
        background-color: #cce1ff80;
      }
    }
  }

  > img {
    max-height: 16rem;
    max-width: 80vw;
  }

  @media screen and (max-width: 64rem) {
    grid-template-columns: 1fr 1fr;
    gap: 4em;

    > div {
      text-align: left;

      > p.big {
        font-size: 1.2em;
      }
    }
  }

  @media screen and (max-width: 60rem) {
    grid-template-columns: 1fr;
    gap: 1em;

    > div {
      text-align: center;

      > p.big {
        font-size: 1.2em;
      }
    }
  }
}
</style>
