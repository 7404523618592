<template>
  <div class="search">
    <title-container />
    <div class="showroomContainer" v-if="$root.SDKLoaded">
      <seez-sdk-search
        :to="'/' + $root.localizedRoutes.Listing.path + '/{id}'"
        :filters="urlFilterString"
        @filtersUpdated="filtersUpdated"
        v-if="$root.SDKLoaded && urlFilterString != null"
        :lg="$root.language"
        @select="selected"
      />
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { CustomTypes } from '../../cms'
import Loader from '../../components/Loader.vue'
import TitleContainer from '../TitleContainer.vue'
import { querier } from '../../lib/querierMixin'

export default {
  name: 'Search',
  components: { Loader, TitleContainer },
  mixins: [querier],
  data() {
    return {
      filterOptions: null,
      urlFilterString: null
    }
  },
  mounted() {
    this.loadFilterOptions().then(this.parseUrl)
  },
  methods: {
    async loadFilterOptions() {
      const response = await this.queryApi('{filterOptionsWithCounters{brands{id,name},families{id,name,brandid},models{id,name,familyid}}}')
      this.filterOptions = response.filterOptionsWithCounters
    },
    async parseUrl() {
      const result = new URLSearchParams(this.$route.fullPath.substring(this.$route.path.length))
      if (this.filterOptions && this.$route.params.brand) {
        const brandId = this.filterOptions.brands.find(b => this.$root.slugify(b.name) === this.$route.params.brand.toLowerCase())?.id
        if (brandId) {
          result.set('brands', brandId)
          if (this.$route.params.model) {
            const familiesIds = this.filterOptions.families.filter(f => f.brandid === brandId).map(f => f.id)
            const modelId = this.filterOptions.models.find(m => familiesIds.includes(m.familyid) && this.$root.slugify(m.name) === this.$route.params.model.toLowerCase())?.id
            if (modelId) result.set('models', modelId)
          }
        }
      }
      this.urlFilterString = result.toString()
      this.updateMetadata()
    },
    selected(e) {
      e.detail[0].preventDefault()
      this.$router.push({ path: `/${this.$root.localizedRoutes.Listing.path}/${e.detail[1].id}` })
    },
    cleanFilter(filter) {
      const cleanFilters = {}
      for (const key in filter) {
        if (filter[key] == null || filter === '') continue
        const k = key.replace(/([A-Z])/g, r => '-' + r.toLowerCase())
        if (Array.isArray(filter[key])) cleanFilters[k] = filter[key].join(',')
        else cleanFilters[k] = filter[key].toString()
      }
      if (cleanFilters['number-of-doors-min'] && cleanFilters['number-of-doors-max'] && cleanFilters['number-of-doors-min'] === cleanFilters['number-of-doors-max']) {
        cleanFilters.doors = cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-max']
      }
      if (cleanFilters.sort === '-attractiveness') delete cleanFilters.sort
      return cleanFilters
    },
    filtersUpdated(v) {
      const cleanFilters = this.cleanFilter(v.detail[0])
      const route = { path: `/${this.$root.localizedRoutes.Search.path}`, query: cleanFilters }
      if (this.filterOptions && cleanFilters.brands && !cleanFilters.brands.includes(',')) {
        const brand = this.filterOptions.brands.find(b => b.id === cleanFilters.brands)
        route.path += `/${this.$root.slugify(brand.name)}`
        delete cleanFilters.brands
        if (cleanFilters.models && !cleanFilters.models.includes(',')) {
          const model = this.filterOptions.models.find(m => m.id === cleanFilters.models)
          route.path += `/${this.$root.slugify(model.name)}`
          delete cleanFilters.models
        }
      }
      this.$router.replace(route)
      this.updateMetadata()
    },
    async updateMetadata() {
      if (this.$route.params.brand) {
        const data = await this.getBrandSeo()
        this.$root.setPageTitle(data.title, data.description)
      } else {
        this.$root.setPageTitle(this.$root.localizedRoutes.Search.title, this.$root.localizedRoutes.Search.description)
      }
    },
    async getBrandSeo() {
      const brand = this.filterOptions.brands.find(b => this.$root.slugify(b.name) === this.$route.params.brand.toLowerCase())
      const document = await this.$root.getDynamicContent(CustomTypes.BRAND_CONTENT, this.$root.slugify(brand.name))

      if (!document) {
        const seo = this.$root.languageResources.META_SEO_CONTENT

        return {
          title: seo.default.title.replace('{brand}', brand.name),
          description: seo.default.description.replace('{brand}', brand.name)
        }
      }

      return { title: document.data.meta_title, description: document.data.meta_description }
    }
  }
}
</script>

<style lang="scss">
.search {
  overflow: hidden;
  height: 100vh;

  @media (min-width: 48rem) {
    margin-bottom: 0;
  }

  .showroomContainer {
    height: calc(100vh - 3.5em - 1px);
    display: grid;
    overflow: hidden;
    box-sizing: border-box;

    > * {
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
