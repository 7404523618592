import Vue from 'vue'
import { prismicApi } from './cms'
import App from './App.vue'
import router from './router'

import * as en from './translations/en'
import * as da from './translations/da'
import analyticsEvents from './analytics/analyticsEvents'


Vue.config.productionTip = false
export const bus = new Vue()
new Vue({
  router,
  mixins: [prismicApi],
  data() {
    const l = process.env.VUE_APP_MULTILANGUAGE !== 'false' ? (localStorage.getItem('language') || 'da') : 'da'

    return {
      language: l,
      languageResources: {},
      user: null,
      SDKLoaded: false,
      dynamicData: null,
    }
  },
  computed: {
    localizedRoutes() {
      return this.languageResources.ROUTES_TRANSLATIONS ?? {}
    },
    analyticsEvents() {
      return analyticsEvents
    }
  },
  watch: {
    language() {
      this.setLanguage()
    }
  },
  beforeMount() {
    this.setLanguage()
    const localDetails = this.localizedRoutes[this.$route.name] ?? this.localizedRoutes.Home
    this.setPageTitle(localDetails.title, localDetails.description)
    this.setCanonical()
  },
  mounted() {
    this.loadCookiebot()
    this.loadSegmentTracking()
    this.loadSDK()
    this.loadAppNexusConversionMarker()
  },
  methods: {
    async getDynamicContent(type, uid) {
      const document = await this.queryByUid(type, uid, this.language)
      this.dynamicData = document

      return document
    },
    setLanguage() {
      const translations = { en, da }
      document.querySelector('html').setAttribute('lang', this.language)
      this.languageResources = translations[this.language]
    },
    async loadSDK() {
      const components = ['search', 'favorites', 'saved-searches', 'calculator', 'carousel', 'trade-in', 'orders']
      const promises = components.map(c => window.customElements.whenDefined('seez-sdk-' + c))
      await Promise.allSettled(promises)
      window.seezSdk.getCurrentUser().then(user => this.user = user)
      this.SDKLoaded = true
    },
    loadCookiebot() {
      const script = document.createElement('script')
      const COOKIEBOT_ID = process.env.VUE_APP_COOKIEBOT_ID
      script.id = 'Cookiebot'
      script.src = 'https://consent.cookiebot.com/uc.js'
      script.dataset.cbid = COOKIEBOT_ID
      script.dataset.blockingmode = 'auto'
      script.type = 'text/javascript'


      document.head.appendChild(script)
    },
    startTracking() {
      window.seezSdk.loadSegmentTracking(process.env.VUE_APP_SEGMENT_ID)
    },
    loadSegmentTracking() {
      if (process.env.NODE_ENV === 'development') {
        this.startTracking()
      }
      window.addEventListener('CookiebotOnAccept', () => {
        if (window.Cookiebot.consent.statistics) {
          this.startTracking()
        }
      }, false)
    },
    loadAppNexusConversionMarker() {
      setTimeout(() => {
        const script = document.createElement('script')
        script.src = 'https://secure.adnxs.com/px?id=1568198&t=1'
        script.type = 'text/javascript'
        document.head.appendChild(script)

        const img = document.createElement('img')
        img.src = 'https://secure.adnxs.com/seg?add=29526928&t=2'
        img.className = 'trackingPixel'
        img.height = '1'
        img.width = '1'
        document.body.appendChild(img)
      }, 30000)
    },
    setPageTitle(title, description, keywords) {
      const siteTitle = this.localizedRoutes.site_title
      const defaultDetails = this.localizedRoutes.Home
      document.title = title ? `${title}  ·  ${siteTitle}` : defaultDetails.title
      document.head.querySelector('meta[name=description]').setAttribute('content', description ?? defaultDetails.description)
      document.head.querySelector('meta[name=keywords]').setAttribute('content', keywords ?? 'Seez, Marketplace')
    },
    setCanonical() {
      document.head.querySelectorAll('link[rel=canonical]').forEach(c => c.parentNode.removeChild(c))
      const canonical = document.createElement('link')
      canonical.setAttribute('rel', 'canonical')
      canonical.setAttribute('href', window.location.origin + window.location.pathname)
      document.head.appendChild(canonical)
    },
    track(eventKey, properties, vData) {
      return window.seezSdk.track(eventKey, properties, vData)
    },
    identify({ userId, name, email, loginStatus }) {
      return window.seezSdk.identify({ userId, name, email, loginStatus })
    },
    trackCTA(eventName, properties) {
      return this.track(eventName, {
        event_category: properties.category,
        event_label: properties.name,
        target_site_id: properties.target_site_id,
        event_action: { iteraction: { navigation: { name: properties.name, type: properties.type } } },
        payload: properties.payload
      }, { vehicle: properties.listing })
    },
    //converts a string into something healthy for urls
    slugify(text) {
      return text?.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/\s+/g, '-')
    }
  },
  render: h => h(App),
}).$mount('#app')
