<template>
  <div class="tradeInBanner">
    <div class="weMakeYourTrade">
      <h3 class="trade-in-title">{{ t['we_make_your_trade_in_easy'] }}</h3>
      <p>{{ t['fill_out_your_details_and_quick'] }}</p>
      <div class="buttonWrapper">
        <label class="licensePlateMobile">
          <span>
            <span class="prefix"><img src="../../assets/eu.png" /> <span>DK</span></span>
            <input required type="text" v-model="licensePlateInput" ref="licensePlateInput" minlength="5" maxlength="10" />
            <button :disabled="!licensePlateInput" type="button" :class="['search', { disabled: !licensePlateInput }]" @click="onClickSearch">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13">
                <g stroke-width="2" stroke="#ffffff" fill="none">
                  <path d="M11.29 11.71l-4-4" />
                  <circle cx="5" cy="5" r="4" />
                </g>
              </svg>
            </button>
          </span>
        </label>
      </div>
      <div class="tradeInCarMobile"><img :src="tradeInCarMobile" /></div>
      <a @click="trackEvent()" href="/tradein"> {{ t['learn_more_about_trade_in'] }}</a>
    </div>
  </div>
</template>

<script>
import tradeInCarMobileImage from '../../assets/trade-in-mobile.webp'

export default {
  name: 'TradeInBanner',
  data() {
    return {
      licensePlateInput: null
    }
  },
  computed: {
    tradeInCarMobile() {
      return tradeInCarMobileImage
    },
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    trackEvent() {
      this.$root.trackCTA(this.$root.analyticsEvents.CTA_CLICK, { name: 'instant_tradein_learn_more' })
    },
    onClickSearch() {
      this.$emit('clickTradeIn', this.licensePlateInput)
    }
  }
}
</script>

<style lang="scss">
@import '../base';
@import '../normalize';

.tradeInBanner {
  @include normalize;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2em 0;
  place-items: flex-start;
  align-items: stretch;

  @include respond(st-48) {
    display: none;
  }

  .tradeInCarMobile {
    margin: 2em 1em 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      max-width: 80vw;
    }
  }
  > div.weMakeYourTrade {
    width: 100%;
    background-color: #cce1ff;
    padding: 2em 0;

    > .trade-in-title {
      font-family: 'Biennale';
      font-weight: 700;
      font-size: 20px;
      color: #000000;
      margin-bottom: 0.5rem;
      padding-inline-start: 1rem;
    }
    > p {
      font-family: 'Biennale';
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-block-start: 0em;
      padding-right: 1em;
      color: #000000;
      margin-bottom: 2rem;
      padding-inline-start: 1rem;
    }
    > .buttonWrapper {
      display: flex;
      justify-content: flex-start;
      padding: 0 1rem;
      width: 100%;

      > .licensePlateMobile {
        width: 100%;

        > span {
          display: flex;
          border: 1px solid #cbcbcb;
          border-radius: 2em;
          background-color: white;

          > input {
            border: none;
            flex-grow: 1;
            color: #333333;
            outline: none;
            border-radius: 2em;

            font-size: 1rem;
          }

          > span.prefix {
            padding: 0.3em 1em;
            position: relative;
            background: #003e99;
            border-radius: 2em 0 0 2em;
            display: flex;
            flex-direction: column;

            > span {
              font-style: normal;
              font-weight: 700;
              font-size: 8px;
              line-height: 11px;
              text-align: center;
              padding-top: 4px;
              color: white;
            }

            > img {
              max-width: 21px;
              max-height: 21px;
            }
          }

          &.error {
            > input {
              border-left-color: red;
              border-top-color: red;
              border-bottom-color: red;
            }

            > span {
              border-color: red;

              &::before {
                content: '!';
                color: red;
                position: absolute;
                top: 0.5em;
                left: -4em;
                border: 1px solid red;
                border-radius: 2em;
                height: 1em;
                width: 1em;
                padding: 0.125em;
                text-align: center;
                line-height: 1em;
                font-weight: bold;
              }
            }
          }

          > button.search {
            background-color: transparent;
            background-repeat: no-repeat;
            border: none;
            overflow: hidden;
            outline: none;
            display: flex;
            justify-self: center;
            align-items: center;
            color: white;
            text-decoration: none;
            cursor: pointer;
            width: 40px;
            height: 40px;
            padding: 16px;
            background: #0068ff;
            border-radius: 24px;
            margin: 2px;
            position: relative;

            > svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          > button.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
    > a {
      font-family: 'Biennale';
      font-style: normal;
      font-weight: 700;
      font-size: 12.8px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #0068ff;
      display: block;
      padding-inline-start: 1rem;
    }

    @media screen and (max-width: 80em) {
      align-items: center;
    }
  }
}
</style>
