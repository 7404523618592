<template>
  <div class="account">
    <seez-sdk-user-profile />
  </div>
</template>

<script>
export default {
  name: 'Account'
}
</script>

