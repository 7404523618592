<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from 'src/components/Header/Header'

export default {
  name: 'App', 
  components: { Header },
  updated() {
    this.setLiveAgentVisibility()
  },
  mounted() {
    this.liveAgentLoad(document, 'https://seez.ladesk.com/scripts/track.js', e => this.liveAgentCreate(e))
  },
  methods: {
    liveAgentLoad(d, src, c) {
      var t = d.scripts[d.scripts.length - 1],
        s = d.createElement('script')
      s.id = 'la_x2s6df8d'
      s.async = true
      s.src = src
      s.onload = s.onreadystatechange = function () {
        var rs = this.readyState
        if (rs && rs != 'complete' && rs != 'loaded') {
          return
        }
        c(this)
      }
      t.parentElement.insertBefore(s, t.nextSibling)
    },
    trackLiveAgentAnalytics(eventName, properties) {
      if (eventName === this.$root.analyticsEvents.LIVE_AGENT_BTN_CLICKED) {
        this.$root.track(eventName, { routeName: properties.routeName })
      }
    },
    liveAgentCreate(e) {
      // eslint-disable-next-line no-undef
      LiveAgent.createButton('3wit09o9', e)
      this.setLiveAgentVisibility()
    },
    setLiveAgentVisibility() {
      const liveAgentButton = document.body.querySelector('div[id*="_3wit09o9_"]')
      if (liveAgentButton) {
        liveAgentButton.style.display = this.$route.name === 'Intro' ? 'none' : 'block'
        liveAgentButton.addEventListener('click', () => this.trackLiveAgentAnalytics(this.$root.analyticsEvents.LIVE_AGENT_BTN_CLICKED, { routeName: this.$route.name }))
      }
    }
  }
}
</script>

<style lang="scss">
:root {
  --accent: #004679;
  --background: #ffffff;
  --background1: #f2f2f2;
  --background2: #d7d7d7;
  --font1: #545454;
  --font2: #1f1f1f;
  --max-content-width: 75rem;
  --max-content-width-slim: 60rem;
  --content-side-padding: max(2rem, calc(54vw - var(--max-content-width) / 2));
  --content-side-padding-wide: max(2rem, calc(50vw - var(--max-content-width) / 2));
  --highlight: #0068ff;
  --blue-shadow: 1px 4px 12px 4px rgba(204, 225, 255, 0.34);
  --light-blue: rgba(204, 225, 255, 0.3);

  @media screen and (min-width: 3840px) {
    --content-side-padding: max(2rem, calc(48vw - var(--max-content-width) / 2));
  }
}

* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #cccccc;
}

*::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

@font-face {
  font-family: 'Biennale';
  src: local('sans-serif'), url('~@/assets/fonts/biennale-thin-webfont.woff2') format('woff2');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Biennale';
  src: local('sans-serif'), url('~@/assets/fonts/biennale-light-webfont.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Biennale';
  src: local('sans-serif'), url('~@/assets/fonts/biennale-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Biennale';
  src: local('sans-serif'), url('~@/assets/fonts/biennale-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Biennale';
  src: local('sans-serif'), url('~@/assets/fonts/biennale-bold-webfont.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Biennale';
  src: local('sans-serif'), url('~@/assets/fonts/biennale-black-webfont.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
}

html,
body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  scroll-behavior: smooth;
  font-family: 'Biennale', sans-serif;
}

@media print {
  html,
  body {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

button {
  font-family: 'Biennale', sans-serif;
}

#app {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font1);
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: stretch;
  background-color: var(--background);

  @media print {
    min-height: auto;
    grid-template-rows: 1fr;
  }
}

.trackingPixel {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0;
}
</style>
