<template>
  <section class="seezBenefits">
    <Benefits />
  </section>
</template>

<script>
import Benefits from '../../components/Benefits.vue'

export default {
  components: { Benefits }
}
</script>

<style lang="scss">
.home > section.seezBenefits {
  padding: 2rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 var(--content-side-padding);

  @media screen and (max-width: 60rem) {
    padding: 2rem 2rem;
  }

  > img {
    position: absolute;
    left: 0;
    z-index: 1;
    height: 60rem;
  }

  @media screen and (max-width: 55rem) {
    > img {
      max-width: 100%;
      top: 2rem;
    }
  }
}
</style>
