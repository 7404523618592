<template>
  <div class="myOrders" v-if="$root.SDKLoaded">
    <seez-sdk-orders :lg="$root.language" />
  </div>
</template>

<script>
export default {
  name: 'Orders'
}
</script>

<style lang="scss">
.myOrders {
  place-self: start stretch;

  > * {
    place-self: start stretch;
  }
}
</style>
