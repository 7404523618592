<template>
  <div class="specsToggleList">
    <details v-if="details" open>
      <summary>{{ t['general_information'] }}<ChevronUp /></summary>
      <dl>
        <div>
          <dt>{{ t['year'] }}</dt>
          <dd>{{ details.year }}</dd>
        </div>
        <div>
          <dt>{{ t['color'] }}</dt>
          <dd>{{ formatSpec(details.color) }}</dd>
        </div>
        <div>
          <dt>{{ t['kilometrage'] }}</dt>
          <dd>{{ formatSpec(details.kilometrage) }}</dd>
        </div>
        <div v-if="isElectric">
          <template>
            <dt>
              {{ t['range'] }}
            </dt>
            <dd>
              {{ formatSpec(details.range, 'km') }}
            </dd>
          </template>
        </div>
        <div>
          <dt>{{ t['registration'] }}</dt>
          <dd>
            {{ new Date(details.registrationDate).toLocaleDateString() }}
          </dd>
        </div>
        <div>
          <dt>{{ t['fuel_type'] }}</dt>
          <dd>{{ formatSpec(details.fuelType.name) }}</dd>
        </div>
        <div>
          <dt>{{ t['reference_number'] }}</dt>
          <dd>{{ formatSpec(details.referenceNumber) }}</dd>
        </div>
      </dl>
    </details>
    <details v-if="details" open>
      <summary>{{ t['technical_specs'] }}<ChevronUp /></summary>
      <dl>
        <div>
          <dt>{{ t['hk'] }}</dt>
          <dd>{{ details.hp }}</dd>
        </div>
        <div>
          <dt>{{ t['acceleration'] }}</dt>
          <dd>{{ formatSpec(details.acceleration, 'sec') }}</dd>
        </div>
        <div>
          <dt>{{ t['top_speed'] }}</dt>
          <dd>{{ formatSpec(details.topSpeed, 'km/h') }}</dd>
        </div>
        <div>
          <dt>{{ t['airbags'] }}</dt>
          <dd>{{ formatSpec(details.numAirbags) }}</dd>
        </div>
        <div>
          <dt>{{ t['number_of_gears'] }}</dt>
          <dd>{{ formatSpec(details.numGears) }}</dd>
        </div>
        <div>
          <dt>{{ t['abs'] }}</dt>
          <dd>{{ details.abs ? t['available'] : t['undisclosed'] }}</dd>
        </div>
        <div>
          <dt>{{ t['cylinders'] }}</dt>
          <dd>{{ formatSpec(details.numCylinders) }}</dd>
        </div>
      </dl>
    </details>
    <details v-if="details" open>
      <summary>{{ t['dimension_capacity'] }}<ChevronUp /></summary>
      <dl>
        <div>
          <dt>{{ t['weight'] }}</dt>
          <dd>{{ formatSpec(details.weight, 'kg') }}</dd>
        </div>
        <div>
          <dt>{{ t['max_attachment'] }}</dt>
          <dd>{{ formatSpec(details.maxAttachment, 'kg') }}</dd>
        </div>
        <div>
          <dt>{{ t['load_capacity'] }}</dt>
          <dd>{{ formatSpec(details.loadCapacity, 'kg') }}</dd>
        </div>
        <div>
          <dt>{{ t['number_of_doors'] }}</dt>
          <dd>{{ formatSpec(details.numDoors) }}</dd>
        </div>
      </dl>
    </details>
    <details open v-if="details.equipment.comfortTechnology && details.equipment.comfortTechnology.length > 0">
      <summary>{{ t['comfort_technology'] }}<ChevronUp /></summary>
      <ul>
        <li v-for="value in details.equipment.comfortTechnology" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
    <details open v-if="details.equipment && details.equipment.design && details.equipment.design.length > 0">
      <summary>{{ t['design'] }}<ChevronUp /></summary>
      <ul>
        <li v-for="value in details.equipment.design" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
    <details open v-if="details.equipment && details.equipment.safetyEnvironment && details.equipment.safetyEnvironment.length > 0">
      <summary>{{ t['safety_environment'] }}<ChevronUp /></summary>
      <ul>
        <li v-for="value in details.equipment.safetyEnvironment" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
    <details open v-if="details && details.equipment && details.equipment.uncategorized && details.equipment.uncategorized.length > 0">
      <summary>{{ t['equipment_details'] }}<ChevronUp /></summary>
      <ul>
        <li v-for="value in details.equipment.uncategorized" :key="value">
          {{ value }}
        </li>
      </ul>
    </details>
  </div>
</template>

<script>
import ChevronUp from '../../assets/icons/arrow-up.svg'

export default {
  name: 'SpecsToggleList',
  components: {
    ChevronUp
  },
  props: {
    details: { type: Object, default: null }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    },
    isElectric() {
      return this.details.fuelType.name === 'El'
    }
  },
  methods: {
    formatSpec(value, unit) {
      if (value == null) return '-'
      if (unit == null) unit = ''
      const stringValue = typeof value == 'number' ? value.toLocaleString(this.language) : value
      return stringValue + ' ' + unit
    }
  }
}
</script>

<style lang="scss">
@import '../normalize';
@import '../base';

.specsToggleList {
  background-color: white;
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 1rem;

  > details summary::-webkit-details-marker {
    display: none;
  }

  > details:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }

  > details {
    padding: 2em 0em;
    

    > summary {
      font-weight: bold;
      font-size: 1.17em;
      cursor: pointer;
      position: relative;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > svg {
        transform: rotate(180deg);
        transition: transform 0.4s;
      }
    }

    &[open] > summary > svg {
      transform: rotate(360deg);
    }

    svg path {
      color: #0068ff;
      fill: currentcolor;
    }

    > dl {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 1.4em 0 0 0;
      gap: 1.7rem;

      @include respond(st-48) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      > div {
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > dt {
          white-space: nowrap;
          margin: 0;
          padding: 0;
        }

        > dd {
          padding: 0;
          margin: 0;
          font-weight: 700;
          margin-top: 0.5rem;
        }
      }

      > dt {
        &:nth-of-type(2n) {
          margin-left: 1em;
        }
      }

      > dd {
        text-align: right;
        font-weight: bold;

        &:nth-of-type(2n + 1) {
          margin-right: 1em;
        }
      }
    }

    > ul {
      list-style-type: none;
      margin: 0 0 0 22%;
      padding: 0;
      gap: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 1.4em 0 0 0;

      @include respond(st-48) {
        gap: 0 4em;
      }

      > li {
        padding: 0.5em;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    > div {
      margin: 0 0 0 22%;
    }
  }
}
</style>
