import Vue from 'vue'
import VueRouter from 'vue-router'

import Search from '../pages/Search/Search.vue'
import Profile from 'src/pages/Profile/Profile.vue'
import Error404 from 'src/pages/Error404/Error404.vue'

import Home from '../pages/Home/Home.vue'
import Account from '../components/profile/Account.vue'
import Favorites from '../components/profile/Favorites.vue'
import Orders from '../components/profile/Orders.vue'
import Searches from '../components/profile/Searches.vue'
import Intro from '../pages/JourneyIntro/JourneyIntro.vue'
import Details from '../pages/ListingDetails/ListingDetails.vue'
import Checkout from '../pages/Checkout/Checkout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import('../views/Home.vue')
    component: Home
  },
  {
    path: '/me',
    name: 'Profile',
    component: Profile,
    children: [
      {
        path: 'favorites',
        name: 'Favorites',
        component: Favorites,
        alias: ['favoritter']
      },
      {
        path: 'searches',
        name: 'Searches',
        component: Searches,
        alias: ['mine-soegninger']
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: { requiresUser: true },
        alias: ['ordrer']
      },
      {
        path: 'account',
        name: 'Account',
        component: Account,
        meta: { requiresUser: true },
        alias: ['konto']
      }
    ]
  },
  {
    path: '/start/:id',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/arrival/:id',
    name: 'Arrival',
    component: Intro,
    alias: ['/ankomst/:id']
  },
  {
    path: '/external/:origin/:externalId',
    name: 'External',
    component: Intro,
    alias: ['/ydre/:origin/:externalId']
  },
  {
    path: '/referred/:origin/:reference',
    name: 'Referred',
    component: Intro,
    alias: ['/henvist/:origin/:reference']
  },
  {
    path: '/search/:brand?/:model?',
    name: 'Search',
    component: Search,
    alias: ['/find-brugt-bil/:brand?/:model?']
  },
  {
    path: '/listing/:id',
    name: 'Details',
    component: Details,
  },
  {
    path: '/test-checkout/:id',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/financing',
    name: 'Financing',
    component: () => import('src/pages/Financing/Financing'),
    alias: ['/billaan']
  },
  {
    path: '/tradein',
    name: 'TradeIn',
    component: () => import('src/pages/TradeIn/TradeIn.vue'),
    alias: ['/byttebil', '/trade']
  },
  {
    path: '/why-seez',
    name: 'WhySeez',
    component: () => import('src/pages/WhySeez/WhySeez'),
    alias: ['/hvorfor-seez']
  },
  {
    path: '/about-seez',
    name: 'About Seez',
    component: () => import('src/pages/AboutSeez/AboutSeez'),
    alias: ['/om-seez']
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('src/pages/Privacy/Privacy.vue'),
    alias: ['/privatlivspolitik']
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('src/pages/Terms/Terms.vue'),
    alias: ['/vilkaar-for-brug']
  },
  {
    path: '/quality',
    name: 'Quality',
    component: () => import('src/pages/QualityAssured/QualityAssured'),
    // component: () => import('src/views/Quality'),
    alias: ['/kvalitet']
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('src/pages/FreeDelivery/FreeDelivery'),
    alias: ['/levering']
  },
  {
    path: '/return',
    name: 'Return',
    component: () => import('src/pages/Return/Return'),
    alias: ['/retur']
  },
  {
    path: '/warranty',
    name: 'Warranty',
    component: () => import('src/pages/Warranty/Warranty'),
    alias: ['/garanti']
  },
  {
    path: '/o/:listingId/:orderId?',
    redirect: to => ({
      path: '/start/:listingId',
      query: {
        orderId: to.params.orderId
      }
    })
  },
  {
    path: '*',
    //redirect: '/'
    component: Error404
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ?? { x: 0, y: 0 }
  }
})

router.afterEach(to => {
  if (router.app.$root.languageResources) {
    const localDetails = router.app.$root.localizedRoutes[to.name] ?? router.app.$root.localizedRoutes.Home
    router.app.setPageTitle(localDetails.title, localDetails.description)
    router.app.setCanonical()
  }
})

export default router
