<template>
  <div class="logout">
    <p>{{ t['comfirm_logout'] }}</p>
    <button aria-label="Logout" @click="$emit('logout')" data-test-id="logout">
      {{ t['logout'] }}
    </button>
    <button
      aria-label="Cancel Logout"
      @click="$emit('close')"
      data-test-id="cancel_logout"
    >
      {{ t['cancel'] }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  computed: {
    t() {
      return this.$root.languageResources.LOGOUT_COMPONENT_TRANSLATIONS
    },
  },
}
</script>

<style lang="scss" scoped>
.logout {
  background-color: white;
  padding: 3rem 4rem;
  min-width: 12ch;
  border-radius: 1em;

  > p {
    color: black;
    padding-bottom: 1rem;
    text-align: center;
    margin: 0 auto 1rem;
  
  }

  :nth-child(2) {
    border-style: solid;
    border-color: red;
    color: red;
    font-family: 'Biennale', sans-serif;

    @media screen and (max-width: 50rem) {
      background-color: red;
      color: white;
    }
  }

  > button {
    padding: 0.5rem 1.5rem;
    margin: 0 auto 1rem;
    background-color: transparent;
    border-style: none;
    color: gray;
    font-weight: bold;
    cursor: pointer;
		border-radius: 2em;
    display: block;
    width: 100%;
    font-family: 'Biennale', sans-serif;

    &:hover,
    &:focus {
      background-color: #fafafa;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
