<template>
  <section v-if="brandData" class="brandSEO">
    <div class="textContent">
      <h1>{{ brandData.name }}</h1>
      <p>
        {{ brandData.main }}
      </p>
    </div>

    <div class="imageContainer" v-if="details">
      <img :src="getCarImg(details)" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'BrandBanner',
  props: {
    details: { type: Object, default: null },
    brandData: { type: Object, default: null }
  },
  methods: {
    getCarImg({ brand }) {
      const brandName = this.$root.slugify(brand.name)
      return `https://marketplace-dev.seez.dk/brands/${brandName}.webp`
    }
  }
}
</script>

<style lang="scss">
@import '../normalize';
@import '../base';

.brandSEO {
  @include normalize;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;

  @include respond(st-48) {
    flex-direction: row;
    padding: 3rem 0;
  }

  > .textContent {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include respond(st-48) {
      width: 60%;
      justify-content: center;
    }

    > h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }

    > p {
      margin-top: 1rem;
      max-width: 100%;

      @include respond(st-48) {
        max-width: 90%;
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
  }

  > .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include respond(st-48) {
      width: 40%;
    }

    > img {
      width: auto;
      height: auto;
      max-height: 230px;

       @include respond(st-48) {
      max-height: 300px;
    }
    }
  }
}
</style>
