<template>
  <section class="finance">
    <div>
      <h2>{{ t['car_financing'] }}</h2>
      <p class="big">{{ t['finance_calculator'] }}</p>
      <p>{{ t['get_a_car_loan_easily'] }}.</p>
      <div class="financeBy">
        <h4>{{ t['finance_provided_by'] }}</h4>
        <span><img src="@/assets/santander-logo.webp" alt="" /></span>
      </div>

      <router-link data-test-id="seez_finance" @click.native="track('Financing')" :to="'/' + $root.localizedRoutes.Financing.path" aria-label="Financing">
        {{ t['finance_with_seez'] }}
      </router-link>
    </div>
    <img src="@/assets/Financing.webp" alt="financing" />
  </section>
</template>

<script>

export default {
  computed: {
    t() {
      return this.$root.languageResources.HOME_VIEW_TRANSLATIONS
    }
  },
  methods: {
    track(name) {
      this.$root.trackCTA(this.$root.analyticsEvents.CTA_CLICK, { interaction: { cta: { name: name } }, payload: 'financing' })
    }
  }
}
</script>

<style lang="scss">
.home > section.finance {
  padding: 4em var(--content-side-padding);
  margin: 4rem 0;
  background-color: var(--light-blue);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  place-items: center;

  @media screen and (max-width: 50rem) {
    margin: 3em 0;
  }

  .financeBy {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: start;

    @media (max-width: 60rem) {
      justify-content: center;
      flex-direction: row;
    }

    @media (max-width: 30rem) {
      justify-content: center;
      flex-direction: column;
    }

    > h4 {
      font-size: 0.875;
      white-space: nowrap;
    }
  }

  > div {
    align-self: center;
    padding: 0.6rem;

    @media (min-width: 50rem) {
      padding: 0 0 0 2rem;
    }

    > h2 {
      color: black;
      font-size: 1.75rem;
    }

    > .big {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }

    p:last-of-type {
      font-weight: 400;
    }

    > a {
      display: inline-block;
      border: 1px solid var(--highlight);
      background-color: transparent;
      padding: 0.75em 1.5em;
      border-radius: 2em;
      color: var(--highlight);
      margin: 1em 0;
      font-weight: 600;

      &:hover {
        background-color: #cce1ff80;
      }
    }
  }

  > img {
    max-height: 28rem;
    max-width: 42vw;
    // aspect-ratio: 563 / 538;
    justify-self: right;

    @media (max-width: 60rem) {
      max-width: 60vw;
      justify-self: center;
    }
  }

  @media screen and (max-width: 64rem) {
    grid-template-columns: 1fr 1fr;
    gap: 2em;

    > div {
      text-align: left;

      > p.big {
        font-size: 1.2em;
      }
    }
  }

  @media screen and (max-width: 60rem) {
    grid-template-columns: 1fr;
    gap: 1em;

    > div {
      text-align: center;

      > p.big {
        font-size: 1.2em;
      }
    }

    > img {
      grid-row: 1;
    }
  }
}
</style>
