<template>
  <div class="intersectionObserver" :ref="observedRefName" />
</template>

<script>
export default {
  name: 'IntersectionObserver',
  props: {
    observedRefName: { type: String, required: true }
  },
  data() {
    return {
      isIntersecting: false
    }
  },
  watch: {
    isIntersecting(value) {
      this.$emit('on-intersection', value)
    }
  },
  mounted() {
    const referenceElement = this.$refs[this.observedRefName]
    const handler = e => {
      if (e[0].isIntersecting) {
        this.isIntersecting = true
      } else {
        this.isIntersecting = false
      }
    }
    const observer = new window.IntersectionObserver(handler)
    observer.observe(referenceElement)
  },
}
</script>

<style lang="scss">
 .intersectionObserver {
	width: 100%;
	height: 1px;
	position: relative;

 }

</style>
