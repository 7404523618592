<template>
  <section class="addedCars">
    <h1 class="">{{ t['recently_added_cars'] }}</h1>
    <p>{{ t['check_out_the_newest_cars'] }}</p>
    <seez-sdk-carousel :filters="filters" :to="'/' + $root.localizedRoutes.Listing.path + '/{id}'" :lg="$root.language" v-if="loadCars && $root.SDKLoaded" @select="goToCar" @loaded="carsLoaded" />
    <router-link @click.native="track('Browse all vehicles')" :to="'/' + $root.localizedRoutes.Search.path" data-test-id="added_search_button">
      {{ t['browse_all_vehicles'] }}
    </router-link>
  </section>
</template>

<script>

export default {
  props: { loadCars: { type: Boolean, default: false } },
  data() {
    return {
      noRelatedCars: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HOME_VIEW_TRANSLATIONS
    },
    filters() {
      const aid = localStorage.getItem('ajs_anonymous_id').replace(/"/g, '')
      if (aid && !this.noRelatedCars) return `recommendedFor=${aid}&sort=recommendedFor`
      return 'sort=-created_date'
    }
  },
  methods: {
    carsLoaded(e) {
      if (e.detail[0].total === 0) this.noRelatedCars = true
    },
    goToCar(e) {
      e.detail[0].preventDefault()
      this.$router.push({
        path: `/${this.$root.localizedRoutes.Listing.path}/${e.detail[1].id}`
      })
    },
    track(name) {
      this.$root.trackCTA(this.$root.analyticsEvents.CTA_CLICK, { interaction: { cta: { name: name } } })
    }
  }
}
</script>

<style lang="scss">
.home > section.addedCars {
  grid-area: related;
  text-align: center;
  max-width: 68.2rem;
  margin: 2rem auto;

  > h1 {
    font-size: 1.75rem;
  }

  > h2 {
    margin: 0 0 2em 0;
  }

  > a {
    background-color: var(--highlight);
    padding: 0.75em 3em;
    border-radius: 2em;
    border: none;
    color: white;
    font-size: 1em;
    margin: 2em auto 1em;
    display: block;
    width: fit-content;
    font-weight: 600;

    &:hover {
      background-color: #0053cc;
    }
  }

  @media screen and (max-width: 50rem) {
    padding: 3em 0;
    margin: 0rem var(--content-side-padding);
  }

  > p {
    padding-bottom: 1em;
  }
}
</style>
