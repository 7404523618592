<template>
  <div class="navigation" data-navigation>
    <router-link active-class="active" @click.native="trackNavigation('Browse')" :to="'/' + $root.localizedRoutes.Search.path">{{ t['browse'] }}</router-link>
    <router-link active-class="active" @click.native="trackNavigation('Trade-in')" :to="'/' + $root.localizedRoutes.TradeIn.path">{{ t['trade_in'] }}</router-link>
    <router-link active-class="active" @click.native="trackNavigation('Financing')" :to="'/' + $root.localizedRoutes.Financing.path">{{ t['financing'] }}</router-link>
    <router-link active-class="active" id="why-seez" @click.prevent.native="openSubMenu" event="" :to="'/' + $root.localizedRoutes.WhySeez.path">
      {{ t['why_seez'] }}
      <div class="blueBottom" v-if="showBlueBottom" />
    </router-link>
    <a @click="trackSupport()" href="https://support.seez.dk" target="_blank" rel="noreferrer">{{ t['support'] }}</a>
    <a @click="trackNavigation('Blog')" href="https://blog.seez.dk" target="_blank" rel="noreferrer">Blog</a>
  </div>
</template>

<script>
import { bus } from '../../main'
export default {
  name: 'Navigation',
  props: {
    openSubMenu: {
      default: null,
      type: Function
    }
  },
  data() {
    return {
      showBlueBottom: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    }
  },
  watch: {
    $route() {
      this.showBlueBottom = false
    }
  },
  created() {
    bus.$on('subMenuExpanded', data => {
      this.showBlueBottom = data
    })
  },

  methods: {
    trackSupport() {
      const userId = this.$root.user != null ? this.$root.user.id : ''
      this.$root.track(this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, { event_name: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, event_category: 'user_engagment', event_action: this.$root.analyticsEvents.CUSTOMER_SUPPORT_START, event_label: { user_id: userId } })
    },
    trackNavigation(name) {
      this.$root.track(this.$root.analyticsEvents.NAVIGATION, {
        event_name: this.$root.analyticsEvents.NAVIGATION,
        event_label: name,
        event_category: 'user_engagement',
        event_action: { iteraction: { navigation: { type: 'header_navigation' } } }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/pages/variables';

.navigation {
  > a {
    color: $white;
    line-height: 1.25em;
  }
  > a:hover {
    font-weight: 500;
  }
  > #why-seez {
    .blueBottom {
      border-bottom: 2px solid #0068ff;
      border-radius: 1px 1px 0px 0px;
      position: relative;
      top: 116%;
      max-width: 6rem;
      z-index: 999;
    }
  }
}
</style>
