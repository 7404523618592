<template>
  <section class="reviews">
    <h2>{{ t['our_customer_reviews'] }}</h2>
    <template>
      <div class="customerReviews">
        <div class="review-card">
          <span>
            <StarIcon v-for="index in 5" :key="index" />
          </span>
          <h4>Fantastisk service</h4>
          <p>
            Fantastisk service. Jeg er meget imponeret over den venlighed og kompetence der er at finde hos Seez og deres medarbejdere. Jeg glæder mig til at få leveret min nye bil hjemme hos mig.
            Klar og tydelig anbefaling herfra.
          </p>
          <div class="reviewer-details">
            <p>Gitte</p>
            <p>11, maj 2022</p>
          </div>
        </div>
        <div class="review-card">
          <span>
            <StarIcon v-for="index in 5" :key="index" />
          </span>
          <h4>Vi kan klart anbefale dem!</h4>
          <p>
            Vi ledte efter ny familiebil og faldt over Seez.dk De havde et stort udvalg af biler og da vi havde brug for hjælp til at finde frem til den bil som passede til vores familie, stod deres
            kundeservice klar med det samme.
          </p>
          <div class="reviewer-details">
            <p>Michelle</p>
            <p>18, maj 2022</p>
          </div>
        </div>
        <div class="review-card">
          <span>
            <StarIcon v-for="index in 5" :key="index" />
          </span>
          <h4>Varm anbefaling</h4>
          <p>
            De har det et lækkert udvalg af biler, og efter at have udset mig et par stykker, valgte jeg at tage kontakt til dem for at høre nærmere om deres betingelser. Det er for det virkelig nogle
            søde mennesker de har siddende derinde, som har...
          </p>
          <div class="reviewer-details">
            <p>Frederikke</p>
            <p>17, maj 2022</p>
          </div>
        </div>
      </div>
    </template>
    <div class="trustcopy">
      <h2>{{ t['bought_a_car_from_seez'] }} {{ t['tell_us_what_you_think'] }}</h2>
      <div class="trustpilot-widget" data-locale="da-DK" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6199e7d66d82a74f1b408395" data-style-height="52px" data-style-width="100%">
        <a href="https://dk.trustpilot.com/review/seez.dk" target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </div>
  </section>
</template>

<script>
import StarIcon from '../../assets/icons/whyseez/star.svg'

export default {
  components: { StarIcon },
  computed: {
    t() {
      return this.$root.languageResources.HOME_VIEW_TRANSLATIONS
    },
    develop() {
      if (process.env.VUE_APP_ENV_LABEL === 'dev' || process.env.VUE_APP_ENV_LABEL === 'demo') {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.home > section.reviews {
  padding: 5rem 0 2.5rem;
  background-image: url('~@/assets/bottom.webp');
  background-size: 100% 150%;
  text-align: center;

  > h2 {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  > .trustpilot-widget {
    margin-top: 40px;
  }

  > .trustcopy {
    margin-top: 3rem;
    text-align: center;
    font-size: 0.8rem;
    h2 {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 50rem) {
    h2 {
      margin: 0rem 1rem 1rem;
    }
  }

  .customerReviews {
    margin: 0.8rem var(--content-side-padding);
    display: grid;
    grid-auto-flow: column;
    column-gap: 1rem;
    font-size: 1rem;
    font-weight: 700;

    .review-card {
      display: grid;
      grid-auto-flow: row;
      border-radius: 1rem;
      padding: 2rem 2rem 1rem 2rem;
      box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.12);
      z-index: 20;
      background-color: white;
      > p {
        font-weight: normal;
        margin: 0rem 0rem 1.5rem 0rem;
        font-size: 0.92rem;
      }

      > h4 {
        margin: 0.62rem 0rem;
      }

      .reviewer-details {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        p:last-child {
          font-size: 0.7rem;
          font-weight: 350;
        }
      }
    }
    @media screen and (max-width: 50rem) {
      overflow-x: scroll;
      margin-right: 0rem;
      overflow-x: scroll;
      margin-left: 0rem;
      &.customerReviews {
        grid-template-columns: repeat(3, 55vw);
        padding: 1rem;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    @media screen and (max-width: 30rem) {
      &.customerReviews {
        grid-template-columns: repeat(3, 85vw);
      }
    }
  }

  > .slideWidget {
    .slider {
      max-width: 90%;
      margin: auto;
    }
  }
}
</style>
