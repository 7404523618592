<template>
  <div class="related" ref="related" v-if="!empty">
    <h2>{{ t['you_might_like'] }}</h2>
    <seez-sdk-carousel :filters="relatedFilter" to="/listing/{id}" v-if="$root.SDKLoaded" @select="goToCar" @loaded="realatedLoaded" />
    <router-link @click.native="trackEvent" :to="'/' + $root.localizedRoutes.Search.path" data-test-id="added_search_button">
      {{ t['browse_all_vehicles'] }}
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'RelatedCars',
  props: {
    listingId: { type: String, default: null }
  },
  data() {
    return {
      empty: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    },
    relatedFilter() {
      if (!this.listingId) return
      return `?related-to=${this.listingId}`
    }
  },
  methods: {
    trackEvent() {
      return this.$root.track(this.$root.analyticsEvents.CTA_CLICK, { name: 'listing_browse_all_click' })
    },
    goToCar(e) {
      e.detail[0].preventDefault()
      this.$router.push({ path: `/${this.$root.localizedRoutes.Listing.path}/${e.detail[1].id}` })
    },
    realatedLoaded(e) {
      this.empty = e.detail[0].total === 0
    }
  }
}
</script>

<style lang="scss">
@import '../normalize';
@import '../base';

.related {
  text-align: center;
  max-width: 68.2rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0068ff;

  > h2 {
    padding: 2rem 0;
    color: black;
  }

  > a {
    border-radius: 24px;
    border: 1px solid #0068ff;
    width: fit-content;
    padding: 0.75em 3em;
    margin-top: 2rem;
    font-weight: 700;
    cursor: pointer;
  }
}
</style>
