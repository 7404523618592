<template>
  <div class="priceInfo">
    <div class="listingPrice">
      <div>
        <p>{{ t['cash'] }}</p>
        <h1 class="price">{{ retailPriceString }}</h1>
        <VatTag v-if="registrationType" :registration-type="registrationType" />
        <IntersectionObserver :observed-ref-name="'priceInfo'" @on-intersection="e => $emit('handleShowHeader', e)" />
      </div>
    </div>
    <div class="financing">
      <div>
        <a @click="showFinanceText = !showFinanceText">
          <p id="financing-text">
            {{ t['financing_from'] }}
            <label :class="{ hidden: !showInfo }" for="financing-text">
              <InfoIcon />
            </label>
          </p>
          <transition>
            <div v-if="showFinanceText" class="popover" v-html="loanCalculation.disclaimer" />
          </transition>
        </a>
        <h1 @click="$emit('goToFinance')" class="priceFinance">{{ loanPriceString }}</h1>
      </div>
      <div class="financedBy">
        <p>
          {{ t['financed_by'] }}
        </p>
        <div class="imageWrapper">
          <img src="../../assets/santander.webp" alt="" />
        </div>
      </div>
    </div>
    <button id="desktop-btn" @click="click" class="desktopBtn">{{ ctaLabel }}</button>
  </div>
</template>

<script>
import IntersectionObserver from './IntersectionObserver.vue'
import InfoIcon from '../../assets/icons/info.svg'
import VatTag from './VatTag.vue'

export default {
  
  name: 'PriceInfo',
  components: { IntersectionObserver, InfoIcon, VatTag },
  props: {
    loanCalculation: { type: Object, default: null },
    priceRetail: { type: Number, default: null },
    listingCurrency: { type: String, default: null },
    ctaLabel: { type: String, default: '' },
    popoverText: { type: String, default: '' },
    showInfo: { type: Boolean, default: false },
    registrationType: { type: String, default: '' }
  },
  data() {
    return {
      showFinanceText: false
    }
  },
  computed: {
    currency() {
      return this?.listingCurrency ?? 'kr.'
    },
    retailPriceString() {
      if (this.priceRetail == null) return null
      return this.priceRetail?.toLocaleString(this.language) + ' ' + this.currency + '.'
    },
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    },
    loanPriceString() {
      if (this.loanCalculation == null || !this.loanCalculation.monthlyPayment) return null
      return this.loanCalculation.monthlyPayment.toLocaleString(this.language) + ' ' + this.currency + ' / ' + this.t.month
    }
  },
  methods: {
    click() {
      this.$emit('on-buy')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../normalize';
@import '../base';

@include normalize;

.priceInfo {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 52px;
    background: #009a33;
    color: white;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
  }

  button:hover {
    background-color: #007b29;
  }

  .popover {
    position: absolute;
    background-color: white;
    z-index: 5;
    left: 0;
    padding: 1rem;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-size: 10.24px;
    color: #757575;
    max-width: 275px;
    margin: auto;

    @include respond(st-48) {
      right: 0;
    }
  }
}

//mobile
@media (max-width: 769px) {
  .priceInfo {
    display: grid;
    grid-template-columns: 2fr 3fr;
    background-color: white;
    border-bottom: 1px solid #e2e2e2;
    padding: 0.2rem 0.5rem 0.8rem 0.5rem;
    box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.1);
    :last-child {
      grid-column: span 2 / span 2;
    }

    .tagVAT {
      display: none;
    }

    .financedBy {
      display: none;
    }

    .listingPrice {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-inline-start: 1rem;
      border-right: 0.75px solid #cbcbcb;
      margin-top: 1rem;

      p {
        font-weight: 500;
        font-size: 12.8px;
        display: flex;
      }

      h1 {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
      }
    }

    .financing {
      margin-top: 1rem;
      padding-inline-start: 1rem;

      > div {
        .priceFinance {
          text-decoration: underline;
          color: #0068ff;
        }

        #financing-text {
          > label {
            padding-inline-start: 1.2rem;
            transform: translateY(0.1rem);
            cursor: pointer;
            position: relative;
          }
        }

        .popover {
          position: absolute;
          top: -7.5rem;
          right: 0;
        }

        p {
          font-weight: 500;
          font-size: 12.8px;
          display: flex;
          align-items: center;
        }

        h1 {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    button {
      padding: 15px 22px;
      font-size: 14px;
      width: 75%;
      margin-top: 1rem;
      margin: 1rem auto 0 1rem;
    }
  }
}
//desktop
@include respond(st-48) {
  .priceInfo {
    box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 0 0 1rem 0;
    min-width: 100%;
    background-color: white;
    border: 1px solid #e2e2e2;
    min-height: 331.59px;

    .tagVAT {
      position: absolute;
      right: 16px;
      top: 19px;
    }

    p {
      font-weight: 500;
      font-size: 1em;
      line-height: 22px;
      color: black;
      padding: 28px 0 0 15px;
    }

    h1 {
      font-weight: 700;
      font-size: 1.75em;
      line-height: 35px;
      color: black;
      margin: 0 22px 0 15px;
      cursor: pointer;
    }

    .priceFinance {
      text-decoration: underline;
      color: #0068ff;
    }

    .priceFinance:hover {
      text-decoration: underline;
    }

    .price {
      border-bottom: 0.75px solid #cbcbcb;
      padding-bottom: 0.6rem;
    }

    .financing {
      > div {
        position: relative;

        #financing-text {
          display: flex;
          align-items: center;

          .hidden {
            background-color: red;
            display: none;
          }

          > label {
            padding-inline-start: 0.5rem;
            transform: translateY(0.1rem);
            cursor: pointer;
          }
        }
      }
    }

    .financedBy {
      display: flex;
      padding-bottom: 2em;

      > p {
        font-size: 0.7em;
        color: #333333;
      }

      > .imageWrapper {
        width: 50%;
        display: flex;
        align-items: flex-end;
        padding: 0.5rem;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    @media (min-width: 1170px) {
      .financedBy {
        > p {
          font-size: 0.8em;
        }
      }
    }

    button {
      padding: 12px 16px;
      font-size: 1.25em;
      width: 272px;
      margin: auto auto;
      max-width: 90%;
    }
  }
}
</style>
