<template>
  <div class="benefitWrapper">
    <h1>{{ t['why_buy_a_car_from_seez'] }}</h1>
    <img src="@/assets/benefits-img.webp" alt="" />
    <div class="benefits">
      <article
        @click="navigateLocalized('Quality')"
      >
        <div class="headSvg">
          <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M0 10C0 4.47715 4.47715 0 10 0H55C60.5229 0 65 4.47715 65 10V55C65 60.5229 60.5228 65 55 65H10C4.47715 65 0 60.5228 0 55V10Z" fill="#CCE1FF" />
            <path
              d="M23.8866 14H15.1624C14.2231 14 13.6732 15.0575 14.2122 15.8263L23.6639 29.2297C25.7486 27.1996 28.5239 25.8839 31.6068 25.654L24.8151 14.5636C24.67 14.2139 24.2928 14 23.8866 14ZM49.9132 14H41.2521C40.8444 14 40.4665 14.2139 40.2569 14.5636L33.5269 25.6555C36.6097 25.8852 39.385 27.2011 41.4697 29.2312L50.9214 15.8277C51.4655 15.0575 50.9142 14 49.9132 14Z"
              fill="#99C3FF"
            />
            <path
              d="M32.5713 25.6055C25.517 25.6055 19.8047 31.3172 19.8047 38.3706C19.8047 45.4241 25.5207 51.1358 32.5713 51.1358C39.622 51.1358 45.338 45.4241 45.338 38.3706C45.338 31.3172 39.6293 25.6055 32.5713 25.6055ZM39.2811 37.0143L36.5334 39.6979L37.1771 43.4876C37.295 44.1585 36.5787 44.6843 35.9708 44.3579L32.5703 42.5723L29.1693 44.3543C28.5618 44.6807 27.8455 44.1548 27.963 43.4839L28.6068 39.6943L25.8591 37.0107C25.3611 36.5284 25.644 35.687 26.3258 35.5855L30.1254 35.0325L31.8242 31.5891C31.9693 31.2809 32.2685 31.1268 32.5677 31.1268C32.8669 31.1268 33.1662 31.2845 33.3112 31.5891L35.0159 35.0343L38.8154 35.5873C39.506 35.687 39.7816 36.5284 39.2811 37.0143Z"
              fill="#0068FF"
            />
          </svg>
          <ChevronRightIcon />
        </div>
        <h3>{{ t['quality_assurance'] }}</h3>
        <!--<p>{{ t['we_believe_value_almost_new'] }}.</p>-->
        <p>{{ t['our_selection_of_cars'] }}.</p>
      </article>

      <article
        @click="navigateLocalized('Return')"
      >
        <div class="headSvg">
          <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M0 10C0 4.47715 4.47715 0 10 0H55C60.5229 0 65 4.47715 65 10V55C65 60.5229 60.5228 65 55 65H10C4.47715 65 0 60.5228 0 55V10Z" fill="#CCE1FF" />
            <g clip-path="url(#clip0_10084_77520)">
              <path
                d="M48.265 29.4972C49.097 31.0468 49.5246 32.7763 49.5092 34.5295C49.4647 40.3855 44.5594 45.0757 38.6175 45.0757H27.9415V48.2313C27.9415 49.726 26.177 50.377 25.1781 49.3938L19.7862 44.0792C19.4829 43.7802 19.3125 43.3747 19.3125 42.9518C19.3125 42.529 19.4829 42.1235 19.7862 41.8245L25.1781 36.5098C26.1965 35.506 27.9415 36.2215 27.9415 37.6392V40.828H38.6492C42.2092 40.828 45.1586 38.0258 45.1949 34.5168C45.2065 33.551 44.9941 32.5954 44.5742 31.7227C44.4309 31.4248 44.3851 31.0904 44.443 30.7657C44.501 30.4411 44.6599 30.1422 44.8977 29.9104C45.2172 29.5975 45.48 29.3424 45.6977 29.1271C45.876 28.9515 46.0933 28.8193 46.3326 28.7411C46.5718 28.663 46.8262 28.641 47.0756 28.6769C47.325 28.7129 47.5624 28.8058 47.7689 28.9482C47.9754 29.0906 48.1453 29.2786 48.265 29.4972V29.4972Z"
                fill="#99C3FF"
              />
              <path
                d="M19.6118 38.9883C19.2924 39.3012 19.0295 39.557 18.8118 39.7715C18.6336 39.9472 18.4162 40.0794 18.177 40.1575C17.9378 40.2357 17.6833 40.2577 17.434 40.2217C17.1846 40.1858 16.9472 40.0929 16.7407 39.9505C16.5342 39.8081 16.3643 39.6201 16.2446 39.4015C15.4126 37.8518 14.985 36.1224 15.0004 34.3692C15.0449 28.5131 19.9502 23.8183 25.8921 23.8183H36.5681V20.6681C36.5681 19.1873 38.3204 18.511 39.3314 19.5042L44.7233 24.8188C45.0267 25.1178 45.1971 25.5233 45.1971 25.9462C45.1971 26.369 45.0267 26.7745 44.7233 27.0735L39.3314 32.3882C38.3305 33.3747 36.5681 32.7144 36.5681 31.2283V28.07H25.8597C22.3004 28.07 19.351 30.8722 19.3139 34.3812C19.3031 35.3457 19.5156 36.3 19.9354 37.1713C20.0797 37.4698 20.1261 37.8051 20.0682 38.1308C20.0102 38.4564 19.8507 38.7561 19.6118 38.9883V38.9883Z"
                fill="#0068FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_10084_77520">
                <rect width="34.5082" height="34.0137" fill="white" transform="translate(15 17.4414)" />
              </clipPath>
            </defs>
          </svg>
          <ChevronRightIcon />
        </div>
        <h3>{{ t['fourteen_day_return'] }}</h3>
        <!--<p>{{ t['we_believe_transparency'] }}.</p>-->
        <p>{{ t['you_get_fourteen_days_test_drive'] }}.</p>
      </article>

      <article
        @click="navigateLocalized('Warranty')"
      >
        <div class="headSvg">
          <svg width="66" height="69" viewBox="0 0 66 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.3"
              d="M0 10C0 4.47715 4.47715 0 10 0H55.6498C61.1727 0 65.6498 4.47715 65.6498 10V58.0271C65.6498 63.5499 61.1727 68.0271 55.6498 68.0271H10C4.47716 68.0271 0 63.5499 0 58.0271V10Z"
              fill="#CCE1FF"
            />
            <g clip-path="url(#clip0_10084_77526)">
              <path
                d="M33.988 49.9056C33.4579 50.107 33.0055 50.2222 32.6487 50.2603V17.7068C32.953 17.7396 33.3173 17.8211 33.5489 17.9132C33.549 17.9133 33.5491 17.9133 33.5491 17.9133L46.4893 23.0915L46.4902 23.0918C47.6114 23.5354 48.3245 24.58 48.3245 25.6685C48.3245 33.9244 45.1639 40.0814 41.5824 44.175C39.7908 46.2228 37.8951 47.7526 36.2398 48.7689C35.4121 49.2771 34.6472 49.6552 33.988 49.9056Z"
                fill="#99C3FF"
                stroke="white"
                stroke-width="0.5"
              />
              <path
                d="M31.237 17.8635C31.4917 17.8068 31.8529 17.7363 32.1518 17.7062L32.0921 50.2601C31.7397 50.2221 31.292 50.1079 30.7666 49.9083C30.1118 49.6595 29.3509 49.2836 28.527 48.778C26.8794 47.7667 24.99 46.243 23.2035 44.1994C19.6323 40.1141 16.4766 33.957 16.4766 25.6685C16.4766 24.5192 17.1982 23.477 18.3143 23.0347L18.3151 23.0344L31.237 17.8635Z"
                fill="#0068FF"
                stroke="white"
                stroke-width="0.5"
              />
            </g>
            <defs>
              <clipPath id="clip0_10084_77526">
                <rect width="34.5082" height="33.1414" fill="white" transform="translate(15.1484 17.4414)" />
              </clipPath>
            </defs>
          </svg>
          <ChevronRightIcon />
        </div>
        <h3>{{ t['one_year_warranty'] }}</h3>
        <!--<p>{{ t['we_believe_peace_of_mind'] }}.</p>-->
        <p>{{ t['your_car_goes_home_with_full_guaranty'] }}.</p>
      </article>
      <!-- <article @click="navigateLocalized('Delivery')"> -->
      <article
        @click="navigateLocalized('Delivery')"
      >
        <div class="headSvg">
          <svg width="66" height="69" viewBox="0 0 66 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.3"
              d="M0 10C0 4.47715 4.47715 0 10 0H55.6498C61.1727 0 65.6498 4.47715 65.6498 10V58.0271C65.6498 63.5499 61.1727 68.0271 55.6498 68.0271H10C4.47716 68.0271 0 63.5499 0 58.0271V10Z"
              fill="#CCE1FF"
            />
            <g clip-path="url(#clip0_10084_77670)">
              <path
                d="M30.666 27.3125H16.8627C16.3863 27.3125 16 26.9574 16 26.5234C16 26.0895 16.3863 25.7344 16.8627 25.7344H30.666C31.1405 25.7344 31.5287 26.0895 31.5287 26.5234C31.5287 26.9574 31.1405 27.3125 30.666 27.3125ZM28.9406 28.8906C29.4151 28.8906 29.8033 29.2457 29.8033 29.6797C29.8033 30.1137 29.4151 30.4687 28.9406 30.4687H18.5881C18.1115 30.4687 17.7254 30.1137 17.7254 29.6797C17.7254 29.2457 18.1115 28.8906 18.5881 28.8906H28.9406ZM27.2152 32.0469C27.6897 32.0469 28.0779 32.4019 28.0779 32.8359C28.0779 33.2699 27.6897 33.625 27.2152 33.625H16.8627C16.3863 33.625 16 33.2699 16 32.8359C16 32.4019 16.3863 32.0469 16.8627 32.0469H27.2152ZM29.8033 42.3046C29.8033 44.4844 27.873 46.2499 25.4898 46.2499C23.1065 46.2499 21.1762 44.4844 21.1762 42.3046C21.1762 40.1249 23.1065 38.3593 25.4898 38.3593C27.873 38.3593 29.8033 40.1249 29.8033 42.3046ZM45.332 42.3046C45.332 44.4844 43.4017 46.2499 41.0185 46.2499C38.6353 46.2499 36.7049 44.4844 36.7049 42.3046C36.7049 40.1249 38.6353 38.3593 41.0185 38.3593C43.4017 38.3593 45.332 40.1249 45.332 42.3046Z"
                fill="#99C3FF"
              />
              <path
                d="M19.4531 23.3672C19.4531 22.0598 20.6118 21 22.0412 21H35.8445C37.2734 21 38.4327 22.0598 38.4327 23.3672V25.7344H41.1664C42.083 25.7344 42.9133 26.0648 43.6089 26.6566L47.7768 30.4687C48.4239 31.0605 48.7851 31.8644 48.7851 32.7028V38.3593C49.7395 38.3593 50.5105 39.0646 50.5105 39.9375C50.5105 40.8103 49.7395 41.5156 48.7851 41.5156H47.0004C46.5798 38.8377 44.0618 36.7812 41.0208 36.7812C37.9797 36.7812 35.4617 38.8377 35.0411 41.5156H31.4717C31.0511 38.8377 28.5331 36.7812 25.4921 36.7812C22.8986 36.7812 20.6846 38.2804 19.83 40.3369C19.5912 40.0213 19.4531 39.5972 19.4531 39.1484V33.625H27.2175C27.692 33.625 28.0802 33.2699 28.0802 32.8359C28.0802 32.4019 27.692 32.0468 27.2175 32.0468H19.4531V30.4687H28.9429C29.4174 30.4687 29.8056 30.1136 29.8056 29.6797C29.8056 29.2457 29.4174 28.8906 28.9429 28.8906H19.4531V27.3125H30.6683C31.1428 27.3125 31.531 26.9574 31.531 26.5234C31.531 26.0894 31.1428 25.7344 30.6683 25.7344H19.4531V23.3672ZM45.3343 33.625V32.7028L41.1664 28.8906H38.4327V33.625H45.3343Z"
                fill="#0068FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_10084_77670">
                <rect width="34.5082" height="25.2499" fill="white" transform="translate(16 21)" />
              </clipPath>
            </defs>
          </svg>
          <ChevronRightIcon />
        </div>
        <h3>{{ t['free_delivery'] }}</h3>
        <!--<p>{{ t['we_believe_full_convenience'] }}.</p>-->
        <p>{{ t['you_do_not_need_car_to_buy_one'] }}.</p>
      </article>
    </div>
  </div>
</template>

<script>
import ChevronRightIcon from 'src/assets/icons/chevron-blue.svg'
export default {
  components: { ChevronRightIcon },
  computed: {
    t() {
      return this.$root.languageResources.BENEFITS_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    navigateLocalized(key) {
      this.$root.track(this.$root.analyticsEvents.SEEZ_BENEFITS_CLICKED, { category: 'user_engagement', name: key, type: 'track' })
      this.$router.push({ path: '/' + this.$root.localizedRoutes[key].path })
    },
  }
}
</script>

<style lang="scss" scoped>
.benefitWrapper {
  display: grid;
  align-items: flex-start;
  justify-items: center;
  grid-template-columns: auto 1fr;
  z-index: 2;

  .headSvg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rightChevron {
      display: none;
      transform: rotate(270deg);
    }

    @media screen and (max-width: 60rem) {
      justify-content: center;
    }
  }

  > h1 {
    grid-column: 1 / span 2;
    text-align: center;
    margin-bottom: 2em;
    font-size: 1.75em;
  }
  > img {
    z-index: 2;
    max-width: 20rem;
    border-radius: 0.75rem;
    align-self: flex-start;
    justify-self: center;
  }

  @media screen and (max-width: 60rem) {
    grid-template-columns: 1fr;
    flex-direction: column;
    padding: 0;
    > h1 {
      grid-column: auto;
    }
    > img {
      width: 22rem;
      object-fit: cover;
      object-position: center;
      height: 20rem;
      margin-bottom: 2em;
    }
  }
}
div.benefits {
  display: flex;
  flex-wrap: wrap;
  gap: 0em 1em;
  margin: 1em 0 2em 2em;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 60rem) {
    max-width: 50rem;
    margin: auto;
    > article:hover {
      > .headSvg .rightChevron{
        display: none;
      }
    }
  }

  > article:hover {
    background-image: linear-gradient(to right, rgba(0, 104, 255, 0.15), rgba(158, 128, 255, 0.15));
    .rightChevron {
      display: block;
    }
    //border-radius: 12px;
  }

  h1 {
    margin: 1rem 1rem 2rem 1rem;
    font-size: 1.75em;
    flex: 1 1 24rem;
    text-align: left;

    @media screen and (max-width: 60rem) {
      text-align: center;
    }
  }

  > article {
    flex: 1 1 14em;
    padding: 1em 1em;
    text-align: start;
    border-radius: 1rem;

    @media screen and (max-width: 60rem) {
      text-align: center;
      flex: 1 1 18rem;
    }

    @media screen and (min-width: 3840px) {
      flex: 1 1 20em;
    }

    > i {
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 1rem;
      height: 4em;
      width: 4em;
      box-shadow: var(--blue-shadow);
      border-radius: 3em;
      display: inline-block;
      position: relative;

      &::before,
      &::after {
        height: 1px;
        width: 6em;
        background-color: #eeeeee;
        content: '.';
        display: block;
        position: absolute;
        top: 50%;
        color: transparent;
      }

      &::before {
        left: -6em;
      }

      &::after {
        right: -6em;
      }
    }

    > img {
      margin-bottom: 1rem;
      height: 2em;
      width: 2em;
      padding: 1em;
      box-shadow: var(--blue-shadow);
      border-radius: 3em;
    }

    > h3 {
      margin: 1rem 0;
      font-size: 1.25rem;
    }

    > p:first-of-type {
      font-weight: bold;
      color: black;
      font-size: 1rem;
    }

    > p:last-of-type {
      font-weight: 400;
    }
  }
}
</style>
