<template>
  <div class="benefitsList">
    <ul>
      <li
        @click="
          track('quality_assurance')
          navigateLocalized('Quality')
        "
      >
        <CheckIcon id="firstIcon" /> <span>{{ t['quality_assurance'] }}</span>
      </li>
      <li
        @click="
          track('14 day return')
          navigateLocalized('Return')
        "
      >
        <CheckIcon id="secondIcon" /> <span>{{ t['money_back'] }}</span>
      </li>
      <li
        @click="
          track('1 year warranty')
          navigateLocalized('Warranty')
        "
      >
        <CheckIcon id="thirdIcon" /><span>{{ t['one_year_warranty'] }}</span>
      </li>
      <li
        @click="
          track('free delivery')
          navigateLocalized('Delivery')
        "
      >
        <CheckIcon /> <span>{{ t['free_delivery'] }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import CheckIcon from '../../assets/icons/check-icon.svg'

export default {
  name: 'Benefits',
  components: {
    CheckIcon,
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    navigateLocalized(key) {
      this.$router.push({ path: '/' + this.$root.localizedRoutes[key].path })
    },
    track(name) {
      this.$root.trackCTA(this.$root.analyticsEvents.LISTING_DETAILS_BENEFITS_CLICK, { category: 'user_engagement', name: name, type: 'track' })
    }
  }
}
</script>

<style  lang="scss">
@import '../normalize';
@import '../base';

.benefitsList {
  @include normalize;

  text-align: start;

  @include respond(st-48) {
    text-align: center;
  }

  > h1 {
    font-weight: 700;
    font-size: 20px;
    background: rgb(238, 238, 238, 0.5);
    padding-top: 2rem;

    @include respond(st-48) {
      background: white;
      padding-top: 0;
      font-size: 25px;
    }
  }

  > ul {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    position: relative;

    li:nth-child(1) { animation-delay: 0s; } 
    li:nth-child(2) { animation-delay: 2.5s; }
    li:nth-child(3) { animation-delay: 5s; }
    li:nth-child(4) { animation-delay: 7.5s; }

    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 13px;
    }

    line-height: 22px;
    padding-inline-start: 1.5rem;
    padding-inline-end: 0.5rem;
    text-align: start;

    @include respond(st-48) {
      margin-top: 2rem;
      padding: 3.5rem 0;
      flex-direction: row;
      text-align: center;
    }

    > li {
      cursor: pointer;
      list-style-type: none;
      flex: 1 1 0%;
      padding: 0.5rem 0;
      position: absolute;
      opacity: 0;
      animation-name: fadeinout;
      animation-duration: 10s;  
      animation-iteration-count: infinite;
      

      @include respond(st-48) {
      justify-content: center;
      position: initial;
      opacity: initial;
      animation-name: none;


      }

      > span {
        padding-inline-start: 0.5rem;
       
      }
    }

  }

@keyframes fadeinout {
  0% {
    opacity: 0;
    visibility: hidden;
    // z-index: -1;

  }
 2.85% {                     
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  4.63% {                     
    opacity: 1;
    visibility: visible;
    z-index: 1;

  }
  20.48% {                
    opacity: 0;
    visibility: hidden;
    z-index: -1;


  }
 100% {
    opacity: 0;
    z-index: -1;


  }
}


}
</style>
