<template>
  <span v-if="Object.values(vat).includes(registrationType)" class="tagVAT">{{ vatDetails }}</span>
</template>

<script>
export default {
  name: 'VatTag',
  props: {
    registrationType: { type: String, default: '' }
  },
  data() {
    return {
      vat: {
        VAT_INCLUDE: 'vanMinusTax',
        VAT_PLUS: 'vanPlusTax',
        VAT_NONE: 'van'
      }
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    },
    vatDetails() {
      if (this.registrationType === this.vat.VAT_INCLUDE || this.registrationType === this.vat.VAT_NONE) return this.t.vat_include
      if (this.registrationType === this.vat.VAT_PLUS) return this.t.vat_plus 
      // if (this.registrationType === this.vat.VAT_NONE) return this.t.van 
      return ''
    }
  }
}
</script>

<style scoped>
.tagVAT {
  background-color: #f6f6f6;
  border-radius: 20px;
  padding: 0.5rem;
  font-size: 0.8em;
  color: #757575;
}
</style>
