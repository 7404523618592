<template>
  <div class="modalReserved">
    <dialog open>
      <i class="close" @click="close">&times;</i>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 20C0 8.95313 8.95313 0 20 0C31.0469 0 40 8.95313 40 20C40 31.0469 31.0469 40 20 40C8.95313 40 0 31.0469 0 20ZM20 35C28.2812 35 35 28.2812 35 20C35 11.6484 28.2812 5 20 5C11.6484 5 5 11.6484 5 20C5 28.2812 11.6484 35 20 35Z"
          fill="#0068FF"
        />
        <path opacity="0.4" d="M11.3125 7.77344L32.2266 28.6875C31.2578 30.0547 30.0547 31.2578 28.6875 32.2266L7.77344 11.3125C8.67969 9.94531 9.94531 8.74219 11.3125 7.77344Z" fill="#0068FF" />
      </svg>
      <h1>{{ t['car_is_not_available'] }}</h1>
      <p>{{ t['we_will_notify_you'] }}</p>
      <p v-if="!hideText">{{ t['we_got_similar_cars'] }}</p>
      <div v-if="!hideText && details" class="cardsWrapper">
        <seez-sdk-carousel v-if="details" :filters="`?related-to=${details.id}`" :to="'/' + $root.localizedRoutes.Listing.path + '/{id}'" @loaded="onListingIsLoaded" />
      </div>
      <div class="actionButtons">
        <button @click="goToListing">
          {{ t['browse_all'] }} <span v-if="!hideText && total">{{ `(${total})` }}</span>
        </button>
      </div>
    </dialog>
  </div>
</template>

<script>

export default {
  name: 'ModalUnavailableCar',
  props: {
    details: { type: Object, required: true, default: null },
    to: { type: String, default: null },
    target: { type: String, default: null }
  },
  data() {
    return {
      loading: false,
      total: null,
      hideText: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.MODAL_UNAVAILABLE_CARS_TRANSLATIONS
    }
  },
  watch: {
    total(newTotal) {
      if (newTotal === 0) {
        this.hideText = true
      }
    }
  },
  methods: {
    onListingIsLoaded(e) {
      this.total = e.detail[0].total
    },
    backToHome() {
      return this.$router.push('/')
    },
    goToListing() {
      if (this.hideText) {
        return this.$router.push(`/${this.$root.localizedRoutes.Search.path}`)
      }
      this.$router.push(`/${this.$root.localizedRoutes.Search.path}/?related-to=${this.details.id}`)
      return
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.modalReserved {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  backdrop-filter: blur(2px) brightness(30%);
  z-index: 6;

  > dialog {
    text-align: center;
    position: relative;
    margin: 1rem;
    border: none;
    border-radius: 2em;
    background-color: white;
    padding: 3rem;
    max-width: 96vmin;

    > i {
      cursor: pointer;
      font-size: 2em;
      font-style: normal;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      line-height: 0.5em;
    }

    > h1 {
      margin: 1.2rem 0 0 0;
      font-size: 1.25em;
      margin-bottom: 1rem;
    }

    > .emailInputWrapper {
      border: 1px solid #afafaf;
      display: flex;
      justify-content: space-between;
      border-radius: 24px;

      > input {
        border: none;
        margin: 0.5rem;
      }

      input:focus {
        outline: none;
      }

      > button {
        border-radius: 24px;
        width: 120px;
        background-color: #0068ff;
        color: white;
        font-weight: bold;
        margin: 0.2rem;
        border: none;
      }
    }

    > .cardsWrapper {
      text-align: start;
    }

    > .actionButtons {
      display: flex;
      width: 14.875em;
      margin: auto;
      justify-content: center;
      text-align: center;
      gap: 0.5rem;
      margin-top: 2rem;

      @media screen and (max-width: 35rem) {
        flex-direction: column;
        gap: 1rem;
      }

      > :only-child {
        padding: 0.5em 1.25em;
        border-radius: 2em;
        font-size: 1rem;
        flex: 1 1 0%;
        font-weight: bold;
        cursor: pointer;

        @media screen and (max-width: 35rem) {
          margin: 0 2rem 0 2rem;
          font-size: 1rem;
        }
      }

      > :only-child {
        color: #0068ff;
        background-color: white;
        border-color: #0068ff;
        border-style: solid;
        border-image: none;
        font-family: 'Biennale', sans-serif;
      }
    }
  }
}
</style>
