<template>
  <section v-if="imageData" class="imageGrid">
    <div class="leftImageWrapper">
      <span v-if="isElectric" class="electricTag">
        <LeafIcon />
      </span>
      <img @click="onCickImage(0)" :src="url(0)" loading="lazy" alt="Main Listing Image" />
      <span v-if="showTag" :class="`reservedTag ${tagClass}`">{{ tag }}</span>
      <button id="show-all-photos" @click="onCickImage(0)">
        <span>
          <GridIcon />
        </span>
        {{ `${t['show_all_photos']}` }}
        <span v-if="imageData.length > 0">({{ imageData.length }})</span>
      </button>
      <label for="show-all-photos" class="mobileViewAll">{{ `1 / ${imageData.length}` }}</label>
    </div>
    <div class="rightImagesWrapper">
      <img @click="onCickImage(1)" :src="url(1)" loading="lazy" alt="Listing Image" />
      <img @click="onCickImage(2)" :src="url(2)" loading="lazy" alt="Listing Image" />
      <img @click="onCickImage(3)" :src="url(3)" loading="lazy" alt="Listing Image" />
    </div>
  </section>
</template>

<script>
import CAR_PLACEHOLDER from '../../assets/car.webp'
import GridIcon from '../../assets/icons/grid-photo.svg'
import LeafIcon from '../../assets/icons/leaf.svg'
export default {
  name: 'ImageGrid',
  components: {
    GridIcon,
    LeafIcon,
  },
  props: {
    imageData: { type: Array, default: null },
    tag: { type: String, default: null },
    tagClass: { type: String, default: null },
    showTag: { type: Boolean, default: false },
    isElectric: { type: Boolean, default: false},
  },
  data() {
    return {
      mainUrl: null
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    },
  },
  methods: {
    url(index) {
      if(index === 0) return (process.env.VUE_APP_IMAGE_URL + this.imageData[index]).replace(/\/1600x0\//, '/700x0/') 
      return this.imageData && this.imageData.length > 0 && this.imageData[index] ? (process.env.VUE_APP_IMAGE_URL + this.imageData[index]).replace(/\/1600x0\//, '/300x0/') : CAR_PLACEHOLDER
    },
    onCickImage(value) {
      this.$emit('onClickShowAll', { value, url: this.imageData[value] })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../base';

.imageGrid {
  display: flex;
  gap: 0.3rem;
  height: 372px;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    position: relative;
  }

  > .leftImageWrapper {
    flex: 1 1 70%;
    max-width: 100%;
    position: relative;

    > button {
      position: absolute;
      bottom: 1.25rem;
      right: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px 5px 10px;
      border: 1px solid #eeeeee;
      background-color: #ffffff;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      display: none;

      @include respond(st-48) {
        display: block;
      }

      > span {
        padding-inline-end: 0.5rem;
      }
    }

    > .mobileViewAll {
      position: absolute;
      bottom: 1.25rem;
      right: 1.25rem;
      color: white;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      padding: 0.5rem 1rem;
      font-size: 12.8px;
      font-weight: 500;

      @include respond(st-48) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      max-width: 75%;
    }

    img {
      border-radius: 0 0 0 0;

      @include respond(st-48) {
        border-radius: 12px 0 0 12px;
      }
    }

    > .electricTag {
        background: linear-gradient(125.11deg, #009A33 39.19%, rgba(0, 154, 51, 0.33) 154.06%);
        position: absolute;
        width: 42px;
        height: 35px;
        left: 0;
        top: 0;
        border-radius: 0 0 12px 0;

        @include respond(st-48) {
        border-radius: 12px 0px;
     
     }
        
        > svg {
           margin: 9px 11px;
        }
      


      }

    > .reservedTag {
      font-size: 1em;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.25em 1.75em;
      border-radius: 0px 16px 16px 0px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      color: white;
      font-weight: 700;
      margin: 1.5em 0;
      min-width: 8.375em;
    }

    > .reserved {
      background-color: #ffa200;
    }

    > .sold {
      background-color: #ff0009;
    }
  }
  .rightImagesWrapper {
    flex: 1 1 25%;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    :first-child {
      border-top-right-radius: 0px;

      @include respond(st-48) {
        border-top-right-radius: 12px;
      }
    }
    :last-child {
      border-bottom-right-radius: 12px;
    }
    img {
      height: 32.39333333%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>
