<template>
  <div class="home">
    <Hero />
    <CarTypes :data-tracking="events.SHOP_BY_CAR_TYPE" />
    <Benefits :data-tracking="events.SEEZ_BENEFITS" />
    <LifeStyles :data-tracking="events.BROWSE_BY_LIFESTYLE" />
    <AddedCars :data-tracking="events.RECENTLY_ADDED_CARS" ref="addedCars" :load-cars="showNewCars" />
    <Financing :data-tracking="events.CAR_FINANCING" />
    <TradeIn :data-tracking="events.TRADE_IN_VALUATION" />
    <Reviews />
    <Footer />
  </div>
</template>

<script>

import { categoryFilters } from 'src/lib/constants'
import Hero from './Hero.vue'
import CarTypes from './CarTypes.vue'
import Benefits from './Benefits.vue'
import LifeStyles from './LifeStyles.vue'
import AddedCars from './AddedCars.vue'
import Financing from './Financing.vue'
import TradeIn from './TradeIn.vue'
import Reviews from './Reviews.vue'
import Footer from '../../components/Footer.vue'

export default {
  name: 'Home',
  components: { Hero, CarTypes, LifeStyles, Benefits, AddedCars, Financing, TradeIn, Reviews, Footer },
  data() {
    return {
      events: this.$root.analyticsEvents,
      categoryFilters,
      intersectionObserver: new IntersectionObserver(x => x.forEach(this.intersectionTrigger)),
      showNewCars: false
    }
  },
  mounted() {
    document.querySelectorAll('[data-tracking]').forEach(x => this.intersectionObserver.observe(x))
    const trustbox = document.querySelector('.trustpilot-widget')
    window.Trustpilot.loadFromElement(trustbox)
    document.body.style.setProperty('overflow', 'overlay')
  },
  destroyed() {
    document.body.style.removeProperty('overflow', 'overlay')
  },
  beforeUnmount() {
    this.intersectionObserver.disconnect()
  },
  methods: {
    intersectionTrigger(e) {
      const dt = e.target.getAttribute('data-tracking')
      if (e.isIntersecting && dt) {
        this.$root.track(dt, { userId: this.$root.user?.id })
      }

      if (e.isIntersecting && e.target === this.$refs.addedCars?.$el) {
        this.showNewCars = true
      }
    }
  }
}
</script>

<style lang="scss">
.home {
  align-self: start;
  color: var(--font2);
  overflow: auto;
  height: 100%;
  position: relative;
  z-index: 1;

  .mobileMenu {
    justify-content: center;
    gap: 1rem;
    display: none;

    @media (max-width: 50rem) {
      display: flex;
    }

    > div {
      background-color: lightgrey;
      border-radius: 9999px;
      width: 0.015rem;
      height: 0.015rem;
      padding: 0.3rem 0.3rem;
      cursor: pointer;
    }
  }
}
</style>
