<template>
  <div v-if="headerData" class="stickyHeader">
    <div class="listingInfo">
      <div class="listingNameVariant">
        <div>
          <h5>{{ headerData.name }}</h5>
        </div>
      </div>
    </div>
    <div class="priceInfo">
      <div>
        <h5>{{ t['full_price'] }}</h5>
        <p>{{ headerData.priceRetail }}</p>
      </div>
      <div>
        <h5>{{ t['monthly_price'] }}</h5>
        <p>{{ headerData.monthlyPayment }}</p>
      </div>
      <button aria-label="Buy Button" id="sticky-header-buy-btn" class="buyButton" @click="onClickBuy">{{ t['buy_now'] }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickyHeader',
  props: {
    headerData: { type: Object, default: null }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    onClickBuy() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
@import '../normalize';

.stickyHeader {
  @include normalize;

  height: calc(4em + 1px);
  background-color: white;
  z-index: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 0 var(--content-side-padding-wide);
  border-bottom: 1px solid #e2e2e2;


  button {
    cursor: pointer;
    
  }

  @media (max-width: 800px) {
    display: none;
  }

  .listingInfo {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .listingNameVariant {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;

      > h5 {
        font-weight: 700;
        font-size: 1.25em;
        line-height: 28px;

        @media (max-width: 1024px) {
          font-size: 1em;
        }
      }

      > span {
        padding-inline-start: 0.8rem;

        > button {
          background-color: transparent;

          > svg path {
            color: #ffffff;
            fill: currentcolor;
          }
        }
      }
    }
  }

  .priceInfo {
    display: flex;
    align-items: center;

    div:first-child {
      border-right: 1px solid white;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 0.5rem;
      padding: 0 2rem;

      > h5 {
        font-weight: 400;
        font-size: 0.8em;
        margin-bottom: 0.2rem;
      }

      p {
        font-weight: 700;
        font-size: 1.25em;

        @media (max-width: 1024px) {
          font-size: 1em;
        }
      }
    }
  }

  .buyButton {
    color: white;
    border-radius: 24px;
    padding: 0.8rem 0;
    min-width: 146px;
    font-weight: 700;
    font-size: 1rem;
    background: #009a33;

    @media (max-width: 1024px) {
      height: 40px;
      min-width: 200px;
    }
  }

  .buyButton:hover { background-color: #007b29; }

}
</style>
