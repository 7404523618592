<template>
  <section class="shopByCarType">
    <h1>{{ t['shop_by_car_type'] }}</h1>
    <ul class="bodytypeFlexbox">
      <li @click="track('Sedan')">
        <router-link :to="`/${$root.localizedRoutes.Search.path}/${categoryFilters.SEDAN}`" data-test-id="sedan">
          <img src="@/assets/body-type/sedan.webp" alt="" />
          <p>Sedan</p>
        </router-link>
      </li>
      <li @click="track('Wagon')">
        <router-link :to="`/${$root.localizedRoutes.Search.path}/${categoryFilters.WAGON}`" data-test-id="wagon">
          <img src="@/assets/body-type/wagon.webp" alt="" />
          <p>Wagon</p>
        </router-link>
      </li>
      <li @click="track('Hatchback')">
        <router-link :to="`/${$root.localizedRoutes.Search.path}/${categoryFilters.HATCHBACK}`" data-test-id="hatchback">
          <img src="@/assets/body-type/hatchback.webp" alt="" />
          <p>Hatchback</p>
        </router-link>
      </li>
      <li @click="track('SUV')">
        <router-link :to="`/${$root.localizedRoutes.Search.path}/${categoryFilters.SUV}`" data-test-id="suv">
          <img src="@/assets/body-type/suv.webp" alt="" />
          <p>SUV</p>
        </router-link>
      </li>
    </ul>
    <div class="mobileMenu">
      <div class="sedan active" @click="scrollCarIntoView(0)" />
      <div aria-label="wagon sedan" @click="scrollCarIntoView(1)" />
      <div aria-label="hatchback wagon" @click="scrollCarIntoView(2)" />
      <div aria-label="suv hatchback" @click="scrollCarIntoView(3)" />
    </div>
  </section>
</template>

<script>
import { categoryFilters } from 'src/lib/constants'

export default {
  data() {
    return {
      categoryFilters
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HOME_VIEW_TRANSLATIONS
    }
  },
  methods: {
    track(name) {
      this.$root.trackCTA(this.$root.analyticsEvents.CAR_TYPE_CLICKED, { category: 'user_engagement', name: name, type: 'track' })
    },
    scrollCarIntoView(index) {
      const car = document.querySelectorAll('.shopByCarType > ul > li')[index]
      car.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
    }
  }
}
</script>

<style lang="scss">
.home > section.shopByCarType {
  text-align: center;
  margin: 0 var(--content-side-padding);

  @media screen and (max-width: 50rem) {
    padding-top: 6em;
  }

  > h1 {
    padding: 0rem 2rem;
    font-size: 1.75rem;

    @media screen and (max-width: 50rem) {
      padding: 1rem 2rem 0rem 2rem;
    }
  }

  > .bodytypeFlexbox {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin: auto;
    gap: 5rem;
    list-style: none;
    margin-inline-start: 0rem;
    margin-block-end: 0rem;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin: auto;

    @media (max-width: 50rem) {
      overflow: hidden;
      max-width: 300px;
      padding: 2rem 1.25em 1em 1.25em;
      overflow-x: scroll;
      justify-content: start;
      margin: auto;

      :nth-child(4) {
        margin-inline-end: 3rem;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    > li {
      > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem 1rem;
        height: 3rem;
        color: inherit;

        > img:hover {
          transition: transform 150ms ease-in-out 20ms;
          transform: scale(1.1);
        }

        > img:not(:hover) {
          transition: transform 150ms ease-out 20ms;
          transform: scale(1);
        }

        @media (max-width: 50rem) {
          padding: 2rem 1rem;
        }

        > img {
          width: 100%;

          @media (max-width: 50rem) {
            height: 5rem;
            width: auto;
          }
        }
      }
    }
  }
}
</style>
