<template>
  <div v-if="$route.name === 'Intro' || !fullScreenLoading">
    <div class="intro">
      <section class="title">
        <div class="brandedHeader" />
        <h1>{{ t['welcome'] }}</h1>
        <p>{{ t['you_found_perfect_car'] }}</p>
      </section>
      <section @click="listingClick" :class="{ carInfo: true, clickable: $route.name == 'Intro' }" v-if="details != null && details.id != null">
        <img :src="imageUrl" alt="car-image" />
        <div>
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="$route.name == 'Intro'">
            <path
              d="M9.1875 8C8.84375 8.03125 8.65625 8.21875 8.625 8.5625V11.1875C8.60938 11.2969 8.54688 11.3594 8.4375 11.375H1.3125C1.20312 11.3594 1.14062 11.2969 1.125 11.1875V4.0625C1.14062 3.95312 1.20312 3.89063 1.3125 3.875H3.9375C4.28125 3.84375 4.46875 3.65625 4.5 3.3125C4.46875 2.96875 4.28125 2.78125 3.9375 2.75H1.3125C0.9375 2.76563 0.625 2.89062 0.375 3.125C0.140625 3.375 0.015625 3.6875 0 4.0625V11.1875C0.015625 11.5625 0.140625 11.875 0.375 12.125C0.625 12.3594 0.9375 12.4844 1.3125 12.5H8.4375C8.8125 12.4844 9.125 12.3594 9.375 12.125C9.60938 11.875 9.73438 11.5625 9.75 11.1875V8.5625C9.71875 8.21875 9.53125 8.03125 9.1875 8ZM11.4375 0.5H7.5C7.15625 0.53125 6.96875 0.71875 6.9375 1.0625C6.96875 1.40625 7.15625 1.59375 7.5 1.625H10.0781L4.28906 7.41406C4.07031 7.67969 4.07031 7.94531 4.28906 8.21094C4.39844 8.32031 4.53125 8.375 4.6875 8.375C4.84375 8.375 4.97656 8.32031 5.08594 8.21094L10.875 2.42188V5C10.9062 5.34375 11.0938 5.53125 11.4375 5.5625C11.7812 5.53125 11.9688 5.34375 12 5V1.0625C11.9688 0.71875 11.7812 0.53125 11.4375 0.5Z"
              fill="#AFAFAF"
            />
          </svg>
          <h1>{{ fullName }}</h1>
          <p>
            {{ details.variant }}
            <svg width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 0.5625C0.929688 0.5625 0.0625 1.42969 0.0625 2.5C0.0625 3.57031 0.929688 4.4375 2 4.4375C3.07031 4.4375 3.9375 3.57031 3.9375 2.5C3.9375 1.42969 3.07031 0.5625 2 0.5625Z"
                fill="#333333"
              />
            </svg>

            {{ details.year }}
          </p>
          <p>{{ retailPriceString }}.</p>
        </div>
      </section>
      <section v-if="details == null || details.id == null" class="carInfo carLoading">
        <img :src="car" alt="car-image" />
        <div>
          <div class="overlay" />
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.1875 8C8.84375 8.03125 8.65625 8.21875 8.625 8.5625V11.1875C8.60938 11.2969 8.54688 11.3594 8.4375 11.375H1.3125C1.20312 11.3594 1.14062 11.2969 1.125 11.1875V4.0625C1.14062 3.95312 1.20312 3.89063 1.3125 3.875H3.9375C4.28125 3.84375 4.46875 3.65625 4.5 3.3125C4.46875 2.96875 4.28125 2.78125 3.9375 2.75H1.3125C0.9375 2.76563 0.625 2.89062 0.375 3.125C0.140625 3.375 0.015625 3.6875 0 4.0625V11.1875C0.015625 11.5625 0.140625 11.875 0.375 12.125C0.625 12.3594 0.9375 12.4844 1.3125 12.5H8.4375C8.8125 12.4844 9.125 12.3594 9.375 12.125C9.60938 11.875 9.73438 11.5625 9.75 11.1875V8.5625C9.71875 8.21875 9.53125 8.03125 9.1875 8ZM11.4375 0.5H7.5C7.15625 0.53125 6.96875 0.71875 6.9375 1.0625C6.96875 1.40625 7.15625 1.59375 7.5 1.625H10.0781L4.28906 7.41406C4.07031 7.67969 4.07031 7.94531 4.28906 8.21094C4.39844 8.32031 4.53125 8.375 4.6875 8.375C4.84375 8.375 4.97656 8.32031 5.08594 8.21094L10.875 2.42188V5C10.9062 5.34375 11.0938 5.53125 11.4375 5.5625C11.7812 5.53125 11.9688 5.34375 12 5V1.0625C11.9688 0.71875 11.7812 0.53125 11.4375 0.5Z"
              fill="#AFAFAF"
            />
          </svg>
        </div>
      </section>
      <section class="stepsGrid">
        <div class="stepsSession">
          <div class="iconHeader">
            <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_10084_77335)">
                <path
                  d="M4.16667 0C4.6658 0 5.09549 0.355469 5.19097 0.846788L7.80816 14.5833H21.1806C21.7578 14.5833 22.2222 15.0477 22.2222 15.625C22.2222 16.2023 21.7578 16.6667 21.1806 16.6667H6.90538C6.44531 16.6667 6.01562 16.3108 5.92014 15.8203L3.30469 2.08333H1.04167C0.46658 2.08333 0 1.61675 0 1.04167C0 0.46658 0.46658 0 1.04167 0H4.16667ZM5.55556 20.1389C5.55556 18.9887 6.48872 18.0556 7.63889 18.0556C8.78906 18.0556 9.72222 18.9887 9.72222 20.1389C9.72222 21.2891 8.78906 22.2222 7.63889 22.2222C6.48872 22.2222 5.55556 21.2891 5.55556 20.1389ZM22.2222 20.1389C22.2222 21.2891 21.2891 22.2222 20.1389 22.2222C18.9887 22.2222 18.0556 21.2891 18.0556 20.1389C18.0556 18.9887 18.9887 18.0556 20.1389 18.0556C21.2891 18.0556 22.2222 18.9887 22.2222 20.1389ZM17.3611 6.07639C17.8385 6.07639 18.2292 6.42795 18.2292 6.94444C18.2292 7.42187 17.8385 7.8125 17.3611 7.8125H11.8056C11.2891 7.8125 10.9375 7.42187 10.9375 6.94444C10.9375 6.42795 11.2891 6.07639 11.8056 6.07639H17.3611Z"
                  fill="#0068FF"
                />
                <path
                  opacity="0.4"
                  d="M21.1716 12.5H7.40864L5.25586 1.38892H23.5154C24.3965 1.38892 25.0996 2.26782 24.8522 3.15367L22.5084 11.4887C22.3392 12.0877 21.7923 12.5 21.1716 12.5ZM11.8053 6.07642C11.2888 6.07642 10.9373 6.42798 10.9373 6.94447C10.9373 7.4219 11.2888 7.81253 11.8053 7.81253H17.3609C17.8383 7.81253 18.2289 7.4219 18.2289 6.94447C18.2289 6.42798 17.8383 6.07642 17.3609 6.07642H11.8053Z"
                  fill="#0068FF"
                />
              </g>
              <defs>
                <clipPath id="clip0_10084_77335">
                  <rect width="25" height="22.2222" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <h1>{{ t['during_your_checkout'] }}</h1>
          </div>
          <ul>
            <li>{{ t['you_fill_personal_details'] }}</li>
            <li>{{ t['you_add_trade_in'] }}</li>
            <li>{{ t['you_decide_how_apply_finance'] }}</li>
            <li>{{ t['you_add_your_date'] }}</li>
          </ul>
        </div>
        <div class="stepsSession">
          <div class="iconHeader">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.15594 7.72381C3.93199 7.96756 3.61613 8.11483 3.28199 8.07928C2.94785 8.13514 2.62488 8.00311 2.38824 7.72381L0.356941 5.69256C-0.11898 5.26092 -0.11898 4.48905 0.356941 4.01323C0.832812 3.53741 1.60469 3.53741 2.08051 4.01323L3.20328 5.13397L6.00234 2.02819C6.44922 1.52799 7.22109 1.48737 7.72383 1.9378C8.22149 2.38823 8.26211 3.15858 7.81016 3.65928L4.15594 7.72381ZM4.15594 15.8488C3.93199 16.0926 3.61613 16.2398 3.28199 16.2043C2.94785 16.2601 2.62488 16.1281 2.38824 15.8488L0.356941 13.8176C-0.11898 13.3859 -0.11898 12.614 0.356941 12.1367C0.832812 11.6644 1.60469 11.6644 2.08051 12.1367L3.20328 13.259L6.00234 10.1512C6.44922 9.6535 7.22109 9.61288 7.72383 10.0648C8.22149 10.5117 8.26211 11.2836 7.81016 11.7863L4.15594 15.8488ZM0 21.125C0 19.7793 1.09129 18.6875 2.4375 18.6875C3.78371 18.6875 4.875 19.7793 4.875 21.125C4.875 22.4707 3.78371 23.5625 2.4375 23.5625C1.09129 23.5625 0 22.4707 0 21.125Z"
                fill="#0068FF"
              />
              <path
                opacity="0.4"
                d="M9.75 19.5H24.375C25.2738 19.5 26 20.2262 26 21.125C26 22.0238 25.2738 22.75 24.375 22.75H9.75C8.85117 22.75 8.125 22.0238 8.125 21.125C8.125 20.2262 8.85117 19.5 9.75 19.5ZM13 11.375H24.375C25.2738 11.375 26 12.1012 26 13C26 13.8988 25.2738 14.625 24.375 14.625H13C12.1012 14.625 11.375 13.8988 11.375 13C11.375 12.1012 12.1012 11.375 13 11.375ZM13 6.5C12.1012 6.5 11.375 5.77383 11.375 4.875C11.375 3.9777 12.1012 3.25 13 3.25H24.375C25.2738 3.25 26 3.9777 26 4.875C26 5.77383 25.2738 6.5 24.375 6.5H13Z"
                fill="#0068FF"
              />
            </svg>
            <h1>{{ t['what_happens_next'] }}</h1>
          </div>
          <ul>
            <li>
              <b>{{ t['trade_in'] }}:</b> {{ t['receive_offer_one_day'] }}
            </li>
            <li>
              <b>{{ t['financing'] }}:</b> {{ t['you_receive_decision'] }}
            </li>
            <li>
              <b>{{ t['sales_agreement'] }}:</b> {{ t['we_send_you_contract'] }}
            </li>
            <li>
              <b>{{ t['payment'] }}:</b> {{ t['you_transfer_your_payment'] }}
            </li>
            <li>
              <b>{{ t['delivery'] }}:</b> {{ t['we_deliver_your_car'] }}
            </li>
          </ul>
        </div>
        <div class="thingsToNote">
          <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 4C8.55219 4 9 4.44781 9 5C9 5.55219 8.55219 6 8 6C7.44781 6 7 5.55312 7 5C7 4.44688 7.44688 4 8 4ZM9.25 12H6.75C6.3375 12 6 11.6656 6 11.25C6 10.8344 6.33594 10.5 6.75 10.5H7.25V8.5H7C6.58594 8.5 6.25 8.16406 6.25 7.75C6.25 7.33594 6.5875 7 7 7H8C8.41406 7 8.75 7.33594 8.75 7.75V10.5H9.25C9.66406 10.5 10 10.8359 10 11.25C10 11.6641 9.66562 12 9.25 12Z"
                fill="#CCE1FF"
              />
              <path
                d="M8 6C8.55219 6 9 5.55219 9 5C9 4.44781 8.55219 4 8 4C7.44781 4 7 4.44687 7 5C7 5.55313 7.44687 6 8 6ZM9.25 10.5H8.75V7.75C8.75 7.3375 8.41563 7 8 7H7C6.5875 7 6.25 7.3375 6.25 7.75C6.25 8.1625 6.5875 8.5 7 8.5H7.25V10.5H6.75C6.3375 10.5 6 10.8375 6 11.25C6 11.6625 6.3375 12 6.75 12H9.25C9.66406 12 10 11.6641 10 11.25C10 10.8359 9.66563 10.5 9.25 10.5Z"
                fill="#0068FF"
              />
            </svg>
            <h3>{{ t['things_to_note'] }}:</h3>
          </div>
          <p>{{ t['you_free_of_charge'] }}</p>
          <p>{{ t['you_will_not_be_expected_payment'] }}</p>
        </div>
        <div class="stepsSession">
          <div class="iconHeader">
            <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 8.25C0 7.48945 0.621962 6.875 1.38889 6.875H3.66753L4.63542 4.13746C5.22135 2.48273 6.79687 1.375 8.56771 1.375H16.4323C18.2031 1.375 19.7786 2.48273 20.3646 4.13746L21.3325 6.875H23.6111C24.3793 6.875 25 7.48945 25 8.25C25 9.01055 24.3793 9.625 23.6111 9.625H23.2378C23.4766 10.0289 23.6111 10.4973 23.6111 11V17.1875H1.38889V11C1.38889 10.4973 1.52431 10.0289 1.76042 9.625H1.38889C0.621962 9.625 0 9.01055 0 8.25ZM6.12413 8.25H18.8759L17.7431 5.04453C17.5477 4.49453 17.0226 4.125 16.4323 4.125H8.56771C7.97743 4.125 7.45226 4.49453 7.25694 5.04453L6.12413 8.25ZM5.55556 11C4.78733 11 4.16667 11.6145 4.16667 12.375C4.16667 13.1355 4.78733 13.75 5.55556 13.75C6.32378 13.75 6.94444 13.1355 6.94444 12.375C6.94444 11.6145 6.32378 11 5.55556 11ZM19.4444 13.75C20.2127 13.75 20.8333 13.1355 20.8333 12.375C20.8333 11.6145 20.2127 11 19.4444 11C18.6762 11 18.0556 11.6145 18.0556 12.375C18.0556 13.1355 18.6762 13.75 19.4444 13.75Z"
                fill="#0068FF"
              />
              <path
                opacity="0.4"
                d="M16.4321 4.125C17.0224 4.125 17.5475 4.49453 17.7428 5.04453L18.8757 8.25H6.12392L7.25673 5.04453C7.45204 4.49453 7.97721 4.125 8.56749 4.125H16.4321ZM1.38867 17.1875H5.55534V19.25C5.55534 20.0105 4.93468 20.625 4.16645 20.625H2.77756C2.01063 20.625 1.38867 20.0105 1.38867 19.25V17.1875ZM23.6109 19.25C23.6109 20.0105 22.9902 20.625 22.222 20.625H20.8331C20.0649 20.625 19.4442 20.0105 19.4442 19.25V17.1875H23.6109V19.25Z"
                fill="#0068FF"
              />
            </svg>
            <h1>{{ t['after_delivery'] }}</h1>
          </div>
          <ul>
            <li>{{ t['you_have_fourteen_days'] }}</li>
          </ul>
        </div>
      </section>
      <!-- <section class="terms">
        <button :disabled="loading" class="letsGoLink" @click="checkout">
          {{ t['start_checkout'] }}
        </button>
      </section> -->
      <section class="assistanceBanner">
        <h3>{{ t['here_when_you_need_us'] }}</h3>
        <p>{{ t['if_you_need_assistance'] }}</p>
        <div class="contactUs">
          <div class="imgContainer">
            <img src="~@/assets/assistance-img.webp" />
            <div class="phoneInfo">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M27.732 22.1316L27.9555 21.1636C28.1417 20.3602 27.7254 19.5399 26.9645 19.2063L21.0328 16.6628C20.3339 16.3655 19.5185 16.5658 19.0384 17.1536L18.1098 18.2813L27.732 22.1316ZM8.06352e-09 2.98001C-7.65544e-05 16.7722 11.2219 27.9995 25.0195 27.9995C25.8207 27.9995 26.5076 27.4544 26.6853 26.675L27.1982 24.4514L16.6271 20.0966C12.8406 18.2378 9.76172 15.1589 7.90234 11.3253L3.54812 0.801806L1.32453 1.31477C0.54507 1.4936 -7.65544e-05 2.17774 8.06352e-09 2.98001ZM10.8445 8.96282C11.4341 8.48775 11.638 7.66837 11.3356 6.96946L8.79375 1.03423C8.46562 0.273908 7.63437 -0.140897 6.78672 0.0435534L5.86797 0.266635L9.7125 9.88704L10.8445 8.96282Z"
                  fill="#0068FF"
                />
                <path
                  d="M27.1948 24.4493L27.73 22.13L18.1542 18.28L16.6722 20.0945L27.1948 24.4493ZM7.90109 11.3243L9.71125 9.88603L5.86672 0.265625L3.54688 0.800797L7.90109 11.3243Z"
                  fill="#99C3FF"
                />
              </svg>
              <a href="tel:+4580820801">+45 80 82 08 01</a>
            </div>
            <div class="emailInfo">
              <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.8984 16.6875C20.8984 12.0293 24.7281 8.25 29.4484 8.25C34.1688 8.25 37.9984 12.0293 37.9984 16.6875C37.9984 21.3457 34.1688 25.125 29.4484 25.125C24.7281 25.125 20.8984 21.3457 20.8984 16.6875ZM33.4444 15.4746C33.8184 15.1113 33.8184 14.5137 33.4444 14.1504C33.0763 13.7813 32.4706 13.7813 32.1025 14.1504L28.4984 17.707L26.7944 16.0254C26.4262 15.6563 25.8206 15.6563 25.4525 16.0254C25.0784 16.3887 25.0784 16.9863 25.4525 17.3496L27.8275 19.6934C28.1956 20.0625 28.8012 20.0625 29.1694 19.6934L33.4444 15.4746Z"
                  fill="#0068FF"
                />
                <path
                  d="M0 3.5625C0 2.00918 1.27597 0.75 2.85 0.75H27.55C29.1234 0.75 30.4 2.00918 30.4 3.5625C30.4 4.44727 29.9784 5.2793 29.26 5.8125L28.4347 6.42188C24.4803 6.79688 21.1672 9.35156 19.7481 12.8555L16.34 15.375C15.6631 15.873 14.7369 15.873 14.06 15.375L1.14 5.8125C0.422334 5.2793 0 4.44727 0 3.5625ZM19 16.6875C19 19.1777 19.8966 21.4688 21.3869 23.25H3.8C1.70109 23.25 0 21.5684 0 19.5V7.3125L12.92 16.875C14.2738 17.877 16.1262 17.877 17.48 16.875L19.0475 15.7148C19.0178 16.0371 19 16.3594 19 16.6875Z"
                  fill="#99C3FF"
                />
              </svg>
              <a href="mailto:support@seez.dk">support@seez.dk</a>
            </div>
            <div />
          </div>
        </div>
        <div class="infoGrid" v-if="$route.name == 'Intro'">
          <div class="infoContainer">
            <div class="iconContainer">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M0 10C0 4.47715 4.47715 0 10 0H55C60.5229 0 65 4.47715 65 10V55C65 60.5229 60.5228 65 55 65H10C4.47715 65 0 60.5228 0 55V10Z" fill="#CCE1FF" />
                <g clip-path="url(#clip0_10084_77520)">
                  <path
                    d="M48.265 29.4972C49.097 31.0468 49.5246 32.7763 49.5092 34.5295C49.4647 40.3855 44.5594 45.0757 38.6175 45.0757H27.9415V48.2313C27.9415 49.726 26.177 50.377 25.1781 49.3938L19.7862 44.0792C19.4829 43.7802 19.3125 43.3747 19.3125 42.9518C19.3125 42.529 19.4829 42.1235 19.7862 41.8245L25.1781 36.5098C26.1965 35.506 27.9415 36.2215 27.9415 37.6392V40.828H38.6492C42.2092 40.828 45.1586 38.0258 45.1949 34.5168C45.2065 33.551 44.9941 32.5954 44.5742 31.7227C44.4309 31.4248 44.3851 31.0904 44.443 30.7657C44.501 30.4411 44.6599 30.1422 44.8977 29.9104C45.2172 29.5975 45.48 29.3424 45.6977 29.1271C45.876 28.9515 46.0933 28.8193 46.3326 28.7411C46.5718 28.663 46.8262 28.641 47.0756 28.6769C47.325 28.7129 47.5624 28.8058 47.7689 28.9482C47.9754 29.0906 48.1453 29.2786 48.265 29.4972V29.4972Z"
                    fill="#99C3FF"
                  />
                  <path
                    d="M19.6118 38.9883C19.2924 39.3012 19.0295 39.557 18.8118 39.7715C18.6336 39.9472 18.4162 40.0794 18.177 40.1575C17.9378 40.2357 17.6833 40.2577 17.434 40.2217C17.1846 40.1858 16.9472 40.0929 16.7407 39.9505C16.5342 39.8081 16.3643 39.6201 16.2446 39.4015C15.4126 37.8518 14.985 36.1224 15.0004 34.3692C15.0449 28.5131 19.9502 23.8183 25.8921 23.8183H36.5681V20.6681C36.5681 19.1873 38.3204 18.511 39.3314 19.5042L44.7233 24.8188C45.0267 25.1178 45.1971 25.5233 45.1971 25.9462C45.1971 26.369 45.0267 26.7745 44.7233 27.0735L39.3314 32.3882C38.3305 33.3747 36.5681 32.7144 36.5681 31.2283V28.07H25.8597C22.3004 28.07 19.351 30.8722 19.3139 34.3812C19.3031 35.3457 19.5156 36.3 19.9354 37.1713C20.0797 37.4698 20.1261 37.8051 20.0682 38.1308C20.0102 38.4564 19.8507 38.7561 19.6118 38.9883V38.9883Z"
                    fill="#0068FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10084_77520">
                    <rect width="34.5082" height="34.0137" fill="white" transform="translate(15 17.4414)" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <h3>{{ t['fourteen_days_return'] }}</h3>
            <p>{{ t['full_transparency'] }}</p>
            <p>{{ t['fourteen_days_test_drive'] }}</p>
          </div>
          <div class="infoContainer">
            <div class="iconContainer">
              <svg width="66" height="69" viewBox="0 0 66 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.3"
                  d="M0 10C0 4.47715 4.47715 0 10 0H55.6498C61.1727 0 65.6498 4.47715 65.6498 10V58.0271C65.6498 63.5499 61.1727 68.0271 55.6498 68.0271H10C4.47716 68.0271 0 63.5499 0 58.0271V10Z"
                  fill="#CCE1FF"
                />
                <g clip-path="url(#clip0_10084_77526)">
                  <path
                    d="M33.988 49.9056C33.4579 50.107 33.0055 50.2222 32.6487 50.2603V17.7068C32.953 17.7396 33.3173 17.8211 33.5489 17.9132C33.549 17.9133 33.5491 17.9133 33.5491 17.9133L46.4893 23.0915L46.4902 23.0918C47.6114 23.5354 48.3245 24.58 48.3245 25.6685C48.3245 33.9244 45.1639 40.0814 41.5824 44.175C39.7908 46.2228 37.8951 47.7526 36.2398 48.7689C35.4121 49.2771 34.6472 49.6552 33.988 49.9056Z"
                    fill="#99C3FF"
                    stroke="white"
                    stroke-width="0.5"
                  />
                  <path
                    d="M31.237 17.8635C31.4917 17.8068 31.8529 17.7363 32.1518 17.7062L32.0921 50.2601C31.7397 50.2221 31.292 50.1079 30.7666 49.9083C30.1118 49.6595 29.3509 49.2836 28.527 48.778C26.8794 47.7667 24.99 46.243 23.2035 44.1994C19.6323 40.1141 16.4766 33.957 16.4766 25.6685C16.4766 24.5192 17.1982 23.477 18.3143 23.0347L18.3151 23.0344L31.237 17.8635Z"
                    fill="#0068FF"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10084_77526">
                    <rect width="34.5082" height="33.1414" fill="white" transform="translate(15.1484 17.4414)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3>{{ t['one_year_guarantee'] }}</h3>
            <p>{{ t['we_believe_in_peace_of_mind'] }}</p>
            <p>{{ t['no_extra_cost_guarantee'] }}</p>
          </div>
          <div class="infoContainer">
            <div class="iconContainer">
              <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M0 10C0 4.47715 4.47715 0 10 0H55C60.5229 0 65 4.47715 65 10V55C65 60.5229 60.5228 65 55 65H10C4.47715 65 0 60.5228 0 55V10Z" fill="#CCE1FF" />
                <path
                  d="M23.8866 14H15.1624C14.2231 14 13.6732 15.0575 14.2122 15.8263L23.6639 29.2297C25.7486 27.1996 28.5239 25.8839 31.6068 25.654L24.8151 14.5636C24.67 14.2139 24.2928 14 23.8866 14ZM49.9132 14H41.2521C40.8444 14 40.4665 14.2139 40.2569 14.5636L33.5269 25.6555C36.6097 25.8852 39.385 27.2011 41.4697 29.2312L50.9214 15.8277C51.4655 15.0575 50.9142 14 49.9132 14Z"
                  fill="#99C3FF"
                />
                <path
                  d="M32.5713 25.6055C25.517 25.6055 19.8047 31.3172 19.8047 38.3706C19.8047 45.4241 25.5207 51.1358 32.5713 51.1358C39.622 51.1358 45.338 45.4241 45.338 38.3706C45.338 31.3172 39.6293 25.6055 32.5713 25.6055ZM39.2811 37.0143L36.5334 39.6979L37.1771 43.4876C37.295 44.1585 36.5787 44.6843 35.9708 44.3579L32.5703 42.5723L29.1693 44.3543C28.5618 44.6807 27.8455 44.1548 27.963 43.4839L28.6068 39.6943L25.8591 37.0107C25.3611 36.5284 25.644 35.687 26.3258 35.5855L30.1254 35.0325L31.8242 31.5891C31.9693 31.2809 32.2685 31.1268 32.5677 31.1268C32.8669 31.1268 33.1662 31.2845 33.3112 31.5891L35.0159 35.0343L38.8154 35.5873C39.506 35.687 39.7816 36.5284 39.2811 37.0143Z"
                  fill="#0068FF"
                />
              </svg>
            </div>

            <h3>{{ t['quality_assured'] }}</h3>
            <p>{{ t['we_believe_almost_new'] }}</p>
            <p>{{ t['our_cars_are_certified'] }}</p>
          </div>
          <div class="infoContainer">
            <div class="iconContainer">
              <svg width="66" height="69" viewBox="0 0 66 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  opacity="0.3"
                  d="M0 10C0 4.47715 4.47715 0 10 0H55.6498C61.1727 0 65.6498 4.47715 65.6498 10V58.0271C65.6498 63.5499 61.1727 68.0271 55.6498 68.0271H10C4.47716 68.0271 0 63.5499 0 58.0271V10Z"
                  fill="#CCE1FF"
                />
                <g clip-path="url(#clip0_10084_77670)">
                  <path
                    d="M30.666 27.3125H16.8627C16.3863 27.3125 16 26.9574 16 26.5234C16 26.0895 16.3863 25.7344 16.8627 25.7344H30.666C31.1405 25.7344 31.5287 26.0895 31.5287 26.5234C31.5287 26.9574 31.1405 27.3125 30.666 27.3125ZM28.9406 28.8906C29.4151 28.8906 29.8033 29.2457 29.8033 29.6797C29.8033 30.1137 29.4151 30.4687 28.9406 30.4687H18.5881C18.1115 30.4687 17.7254 30.1137 17.7254 29.6797C17.7254 29.2457 18.1115 28.8906 18.5881 28.8906H28.9406ZM27.2152 32.0469C27.6897 32.0469 28.0779 32.4019 28.0779 32.8359C28.0779 33.2699 27.6897 33.625 27.2152 33.625H16.8627C16.3863 33.625 16 33.2699 16 32.8359C16 32.4019 16.3863 32.0469 16.8627 32.0469H27.2152ZM29.8033 42.3046C29.8033 44.4844 27.873 46.2499 25.4898 46.2499C23.1065 46.2499 21.1762 44.4844 21.1762 42.3046C21.1762 40.1249 23.1065 38.3593 25.4898 38.3593C27.873 38.3593 29.8033 40.1249 29.8033 42.3046ZM45.332 42.3046C45.332 44.4844 43.4017 46.2499 41.0185 46.2499C38.6353 46.2499 36.7049 44.4844 36.7049 42.3046C36.7049 40.1249 38.6353 38.3593 41.0185 38.3593C43.4017 38.3593 45.332 40.1249 45.332 42.3046Z"
                    fill="#99C3FF"
                  />
                  <path
                    d="M19.4531 23.3672C19.4531 22.0598 20.6118 21 22.0412 21H35.8445C37.2734 21 38.4327 22.0598 38.4327 23.3672V25.7344H41.1664C42.083 25.7344 42.9133 26.0648 43.6089 26.6566L47.7768 30.4687C48.4239 31.0605 48.7851 31.8644 48.7851 32.7028V38.3593C49.7395 38.3593 50.5105 39.0646 50.5105 39.9375C50.5105 40.8103 49.7395 41.5156 48.7851 41.5156H47.0004C46.5798 38.8377 44.0618 36.7812 41.0208 36.7812C37.9797 36.7812 35.4617 38.8377 35.0411 41.5156H31.4717C31.0511 38.8377 28.5331 36.7812 25.4921 36.7812C22.8986 36.7812 20.6846 38.2804 19.83 40.3369C19.5912 40.0213 19.4531 39.5972 19.4531 39.1484V33.625H27.2175C27.692 33.625 28.0802 33.2699 28.0802 32.8359C28.0802 32.4019 27.692 32.0468 27.2175 32.0468H19.4531V30.4687H28.9429C29.4174 30.4687 29.8056 30.1136 29.8056 29.6797C29.8056 29.2457 29.4174 28.8906 28.9429 28.8906H19.4531V27.3125H30.6683C31.1428 27.3125 31.531 26.9574 31.531 26.5234C31.531 26.0894 31.1428 25.7344 30.6683 25.7344H19.4531V23.3672ZM45.3343 33.625V32.7028L41.1664 28.8906H38.4327V33.625H45.3343Z"
                    fill="#0068FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10084_77670">
                    <rect width="34.5082" height="25.2499" fill="white" transform="translate(16 21)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3>{{ t['free_delivery'] }}</h3>
            <p>{{ t['total_convinience'] }}</p>
            <p>{{ t['your_car_delivered_to_door'] }}</p>
          </div>
        </div>
      </section>
    </div>
    <ModalUnavailableCar v-if="showModal" :details="details" @close="showModal = false" />
    <ModalActiveOrder v-if="activeOrder" :active-order="activeOrder" @cancel="cancelActiveOrder" @close="activeOrder = null" />
    <ModalEmailError v-if="showWrongEmailModal" :show="showWrongEmailModal" @logout="logout" @browseSimilarCars="browseSimilarCars" />
    <div class="checkoutStickyContainer">
      <div class="contentHolder">
        <div class="priceDetails">
          <p>{{ t['total_to_pay'] }}</p>
          <p class="retailPricing">{{ retailPriceString }}</p>
        </div>
        <button :disabled="loading" @click="checkout">
          {{ t['start_checkout'] }}
        </button>
      </div>
    </div>
    <Footer v-show="$route.name == 'Intro'" />
    <div class="spacer" />
    <Loader v-if="loading || fullScreenLoading" class="introLoader" />
  </div>
  <Loader v-else class="curtainLoader" />
</template>

<script>
import Footer from '../../components/Footer.vue'
import ModalUnavailableCar from '../../components/ModalUnavailableCar.vue'
import ModalActiveOrder from '../../components/ModalActiveOrder.vue'
import ModalEmailError from './ModalEmailError.vue'
import Loader from '../../components/Loader.vue'
import car from '../../assets/car.webp'
import { querier } from '../../lib/querierMixin'

export default {
  name: 'JourneyIntro',
  components: { Loader, Footer, ModalUnavailableCar, ModalActiveOrder, ModalEmailError },
  mixins: [querier],
  data() {
    return {
      listingId: null,
      details: {},
      loading: false,
      fullScreenLoading: false,
      error: null,
      orderCreated: false,
      car: car,
      shouldShowStickyFooter: false,
      showModal: false,
      activeOrder: null,
      showWrongEmailModal: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.JOURNEY_INTRO_VIEW_TRANSLATIONS
    },
    fullName() {
      if (this.details?.name) return this.details.name

      const brand = this.details && this.details.model && this.details.model.brand ? this.details.model.brand.name : null
      const model = this.details && this.details.model ? this.details.model.name : null
      return [brand, model].filter(n => n != null).join(' ')
    },
    retailPriceString() {
      if (this.details?.priceRetail == null) return null
      return this.details.priceRetail?.toLocaleString(this.$root.language) + ' ' + this.details.currency
    },
    imageUrl() {
      if (!this.details?.pictures) return car
      return this.details.pictures.length > 0 ? this.details.pictures[0] : car
    }
  },
  created() {
    this.brandStyles()
  },
  mounted() {
    if (this.$route.hash === '#go' && this.$root.user != null) this.checkout()
    this.getListingId().then(r => {
      this.listingId = r
      this.loadDetails()
    })
  },
  methods: {
    async cancelActiveOrder() {
      this.loading = true
      await this.queryApi(`mutation {cancelOrder(orderId: ${this.activeOrder.id}) {id} }`)
      this.loading = false
      this.checkout()
    },
    async getListingId() {
      this.loading = true
      try {
        if (this.$route.params.id) return this.$route.params.id
        if (this.$route.params.externalId) {
          const result = await this.queryApi(`{listingsByExternalId(externalId: "${this.$route.params.externalId}", clientId: "${this.$route.params.origin}")}`)
          return result.listingsByExternalId[0]
        }
        if (this.$route.params.reference) {
          const result = await this.queryApi(`{listingsByDealerReference(reference: "${this.$route.params.reference}", clientId: "${this.$route.params.origin}")}`)
          return result.listingsByDealerReference[0]
        }
      } catch (error) {
        console.error('Invalid listing identifier')
      } finally {
        this.loading = false
      }
    },
    async loadDetails() {
      this.fullScreenLoading = true
      this.error = null
      try {
        const query = `{
          listing(id: ${this.listingId})
          {id,name, model {id, name},
          brand {id, name},transmission {name},dealership {id, name},fuelType {name},bodyType {name},family { id, name }, kilometrage,registrationDate, variant,color,year,currency,priceRetail,state,pictures, targetSite{id,logo,brandingHighlightColor} }}`
        const result = await this.queryApi(query)
        result.listing.pictures = result.listing.pictures ? [...new Set(result.listing.pictures)] : []
        this.details = result.listing
        this.trackLoadDetails(result.listing.targetSite.id, result.listing.id)
        if (this.details?.targetSite?.brandingHighlightColor != null || this.details?.targetSite?.logo != null)
          this.brandStyles(this.details?.targetSite?.brandingHighlightColor, this.details?.targetSite?.logo)
      } catch (error) {
        console.error(error)
        this.error = 'Error loading listing details'
      } finally {
        this.fullScreenLoading = false
      }
    },
    async checkout() {
      if (this.loading) {
        return
      }

      if (this.details.state === 'unavailable') {
        return (this.showModal = true)
      }

      const user = await window.seezSdk.getCurrentUser()

      if (user) {
        this.loading = true
        const query =
          'fragment orderFields on Order {id,state, customerInfo { email } listing {name,year,kilometrage,priceRetail,pictures,variant,reserved,state,reservationExpires}},{user {activeOrder {...orderFields}}}'
        const { user } = await this.queryApi(query)
        const orderId = this.$route.query.orderId
        const order = await this.getOrder(orderId)

        if (this.showWrongEmailModal) return

        const sessionUser = user
        const sessionUserEmailMarketplaceCreated = sessionUser?.email
        const sessionUserEmailSeezpadCreated = sessionUser?.activeOrder?.customerInfo?.email
        const orderEmail = (order && order?.customerInfo?.email) || ''

        if (orderEmail === sessionUserEmailSeezpadCreated || (orderEmail === sessionUserEmailMarketplaceCreated && orderId)) {
          this.loading = false
          this.directToCheckout(orderId)
          return
        }

        if (orderEmail !== sessionUserEmailSeezpadCreated && orderId) {
          this.loading = false
          this.showWrongEmailModal = true
          return
        }

        this.activeOrder = user.activeOrder
        this.loading = false
        if (user.activeOrder) return
      }

      if (user == null) {
        this.track()
        const router = await this.$router
        if (this.$route.hash !== '#go') {
          await router.replace({ hash: '#go', query: { orderId: this.$route.query.orderId } })
        }

        if (this.details.targetSite.id !== '1') {
          window.seezSdk.showLogin('marketplaceBanner')
          return
        }

        window.seezSdk.showLogin('marketplaceBanner')
        return
      }

      this.loading = true
      if (this.orderCreated) {
        this.loading = false
        return
      }

      const orderId = this.$route.query.orderId

      !orderId ? this.createOrder() : this.directToCheckout(orderId)

      this.loading = false
    },
    async createOrder() {
      try {
        const { createOrder } = await this.queryApi(`mutation{createOrder(input:{listingId:${this.listingId}}){id}}`)
        this.track('pj_start')

        window.location = `/checkout?order=${createOrder.id}`
      } catch (error) {
        if (error.message === 'Listing Reserved') {
          this.showModal = true
        }
        this.error = error.message
      }
    },
    async getOrder(orderId) {
      if (!orderId) {
        return null
      }
      const gqlQuery = ` { order(id: ${orderId}) { state customerInfo { email } } } `

      try {
        const { order } = await this.queryApi(gqlQuery)
        return order
      } catch (e) {
        this.showWrongEmailModal = true
        return null
      }
    },
    directToCheckout(orderId) {
      this.track('qr_code_start_pj')
      window.location = `/checkout?order=${orderId}`
    },
    listingClick() {
      if (this.$route.name !== 'Intro') return

      const link = document.createElement('a')
      link.href = `/${this.$root.localizedRoutes.Listing.path}/${this.details.id}`
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async logout() {
      await window.seezSdk.logout()
      this.showWrongEmailModal = false
    },
    browseSimilarCars() {
      return this.$router.push(`/${this.$root.localizedRoutes.Search.path}/?brands=${this.details.brand.id}&models=${this.details.model.id}`)
    },
    track(type) {
      if (type) {
        this.savePageReferrer()
        this.$root.trackCTA(this.$root.analyticsEvents.START_PJ, {
          category: 'enhanced_ecommerce',
          type: type,
          name: this.listingId,
          listing: this.details,
          listing_price: this.details.priceRetail,
          target_site_id: this.details.targetSite.id
        })
      } else {
        this.$root.trackCTA(this.$root.analyticsEvents.SHOW_LOGIN, {
          name: this.listingId,
          listing: this.details,
          listing_price: this.details.priceRetail
        })
      }
    },

    brandStyles(highlight, logo) {
      document.querySelectorAll('#arrivalBranding').forEach(x => {
        x.parentNode.removeChild(x)
      })
      if (this.$route.name === 'Intro') return

      let styles = '#app > div > header { display: none; } #app div.intro > section.title { border-radius: 0; }'
      if (highlight) styles += ` #app div.intro > section.title, .checkoutStickyContainer > .contentHolder > button { background: ${highlight}; } .modal { --highlight: ${highlight}; }`
      if (logo) styles += ` .intro > .title > .brandedHeader { display: block !important; background-image: url('${logo}'); }`

      const style = document.createElement('style')
      style.setAttribute('type', 'text/css')
      style.setAttribute('id', 'arrivalBranding')
      style.textContent = styles
      document.head.append(style)
    },
    trackLoadDetails(targetSiteId, listingId) {
      this.$root.track(this.$root.analyticsEvents.LOAD_INTRO_PAGE, { target_site_id: targetSiteId, listingId })
    },
    savePageReferrer() {
      localStorage.setItem('page_referrer', window.location.href)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  display: grid;
  place-items: center;
  z-index: 3;

  > * {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.intro {
  align-self: start;
  overflow: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  color: black;

  @media screen and (max-width: 50rem) {
    align-self: start;
    overflow: auto;
    display: block;
    padding: 0em 1em;
  }

  > .title {
    background: #0068ff;
    background-image: url('~@/assets/blob2.webp'), linear-gradient(147.27deg, #0068ff 23.48%, #9e80ff 92.92%);
    background-repeat: no-repeat;
    background-position: top left;
    color: white;
    padding: 7em var(--content-side-padding) 6.8rem var(--content-side-padding);
    text-align: center;
    border-radius: 0 0 15px 15px;

    @media screen and (max-width: 50rem) {
      background-image: url('~@/assets/blob.webp'), linear-gradient(147.27deg, #0068ff 23.48%, #9e80ff 92.92%);
    }

    @media screen and (max-width: 50rem) {
      margin: 0rem -1em 1em;
      border-radius: 0;
    }

    @media screen and (max-width: 42rem) {
      background-size: cover;
    }

    > .brandedHeader {
      position: absolute;
      inset: 0 0 auto 0;
      height: 3.5rem;
      background-position: 2%;
      background-repeat: no-repeat;
      background-size: auto 80%;
      background-color: white;
      z-index: 3;
      display: none;
    }

    > h1 {
      font-size: 2rem;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    > p {
      width: auto;
      font-size: 1.1rem;
      margin-block-start: 0.5em;
      margin-block-end: 0;

      @media screen and (max-width: 50rem) {
        width: 80%;
        margin: 0.5rem auto 0 auto;
      }
    }
  }

  > .carInfo {
    display: flex;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    max-height: 10rem;
    min-width: 43.5rem;
    max-width: 40%;
    margin: auto;
    transform: translateY(-50%);
    position: relative;
    background-color: white;
    &.clickable {
      cursor: pointer;
    }

    @media screen and (max-width: 50rem) {
      min-width: 100%;
      transform: translateY(-55%);
    }

    > img {
      width: 50%;
      height: auto;
      object-fit: cover;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;

      @media screen and (max-width: 50rem) {
        width: 14rem;
        height: auto;
        object-fit: cover;
      }

      @media screen and (max-width: 30rem) {
        width: 9rem;
        height: auto;
        object-fit: cover;
      }
    }

    > div {
      padding: 1rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 1rem;

      > svg {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      > h1 {
        font-size: 1.2em;
        margin-block-start: 0;
        margin-block-end: 0.2em;

        @media screen and (max-width: 50rem) {
          font-size: 1.2rem;
        }
      }

      > p {
        margin-block-start: 0;
        margin-block-end: 0.5em;

        > svg {
          vertical-align: middle;
        }
      }

      p:last-child {
        text-align: end;
        font-weight: 900;
        font-size: 1.2rem;
        margin: 0 0.5em;
        transform: translateY(0.5rem);

        @media screen and (max-width: 50rem) {
          text-align: end;
          padding-top: 0;
          margin: 0;
          font-size: 1.2em;
        }
      }
    }
  }

  > .carLoading {
    background-color: lightgrey;
    position: relative;

    > div {
      > .overlay {
        position: absolute;
        background-color: #f6f6f6;
        opacity: 100%;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 12px;
      }
    }
  }

  > .stepsGrid {
    max-width: 44rem;
    margin: auto;

    @media screen and (max-width: 50rem) {
      min-width: auto;
      max-width: 100%;
    }

    transform: translateY(-4rem);

    > .stepsSession {
      display: flex;
      flex-direction: column;

      > .iconHeader {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        margin-bottom: 0;

        > h1 {
          font-weight: 700;
          font-style: normal;
          font-size: 1.5rem;
          margin: 0;
        }
      }

      > ul {
        margin-top: 0;
        list-style-type: none;
        padding-inline-start: 1.5em;

        > li {
          font-size: 0.875rem;
          line-height: 19.39px;
          list-style-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 0C3.25 0.0104167 2.57812 0.192708 1.98438 0.546875C1.38021 0.901042 0.901042 1.38021 0.546875 1.98438C0.192708 2.57812 0.0104167 3.25 0 4C0.0104167 4.75 0.192708 5.42188 0.546875 6.01562C0.901042 6.61979 1.38021 7.09896 1.98438 7.45312C2.57812 7.80729 3.25 7.98958 4 8C4.75 7.98958 5.42188 7.80729 6.01562 7.45312C6.61979 7.09896 7.09896 6.61979 7.45312 6.01562C7.80729 5.42188 7.98958 4.75 8 4C7.98958 3.25 7.80729 2.57812 7.45312 1.98438C7.09896 1.38021 6.61979 0.901042 6.01562 0.546875C5.42188 0.192708 4.75 0.0104167 4 0ZM5.5 4C5.48958 4.42708 5.34375 4.78125 5.0625 5.0625C4.78125 5.34375 4.42708 5.48958 4 5.5C3.57292 5.48958 3.21875 5.34375 2.9375 5.0625C2.65625 4.77083 2.51042 4.41667 2.5 4C2.51042 3.58333 2.65625 3.22917 2.9375 2.9375C3.21875 2.65625 3.57292 2.51042 4 2.5C4.42708 2.51042 4.78125 2.65625 5.0625 2.9375C5.34375 3.21875 5.48958 3.57292 5.5 4Z' fill='%230068FF'/%3E%3C/svg%3E%0A");
          padding: 0.7rem;
        }
      }
    }

    > .thingsToNote {
      display: block;
      padding: 1rem 2rem;
      background: rgba(246, 246, 246, 0.6);
      border-radius: 12px;

      > div {
        display: flex;
        align-items: center;

        > h3 {
          padding-inline-start: 0.5rem;
          font-size: 0.8rem;
          font-weight: 700;
          line-height: 17.73px;
        }
      }

      > p {
        padding: 0.5rem 1.5rem;
        margin: 0;
        font-size: 0.8rem;
      }
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem;
      margin-top: 1.5rem;

      @media screen and (max-width: 50rem) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.5rem;
      }
    }
  }

  .terms {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    transform: translateY(-2rem);

    > button {
      background-color: #0068ff;
      color: white;
      border: none;
      outline: none;
      border-radius: 2em;
      padding: 0.8rem;
      margin: auto;
      font-size: 1.125rem;
      width: 20rem;
      font-weight: 700;
      cursor: pointer;
      font-family: 'Biennale', sans-serif;

      @media screen and (max-width: 30rem) {
        width: 100%;
        display: none;
      }
    }
  }

  > .assistanceBanner {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(204, 225, 255, 0.25);
    border-radius: 12px 12px 0 0;

    > h3 {
      font-size: 1.563rem;
      line-height: 34.62px;
      font-weight: 700;
      margin: 3.125rem 0 1rem 0;
      padding: 0;

      @media screen and (max-width: 30rem) {
        font-size: 1.25rem;
      }
    }

    > p {
      max-width: calc(var(--max-content-width) - 30rem);
      margin: 0 2rem 3rem;
    }

    > .contactUs {
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: center;

      > .imgContainer {
        width: 100%;
        max-width: 396px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: auto;

        > img {
          width: 100%;
          height: auto;
        }

        @media screen and (max-width: 50rem) {
          flex-direction: column;
          width: 90%;
          margin: auto;
          border-radius: 12px;
        }

        > .phoneInfo {
          background-color: #ffffff;
          position: absolute;
          padding: 0.6rem 1.8rem;
          border-radius: 12px;
          box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
          left: -6.5rem;
          top: 6.6rem;
          display: flex;
          justify-content: center;
          align-items: center;

          > a {
            color: inherit;
            text-decoration: underline;
            text-decoration-thickness: 0.06em;
          }

          @media screen and (max-width: 50rem) {
            font-size: 1rem;
            left: -2rem;
            padding: 0.6rem 1rem;
          }

          > svg {
            padding-inline-end: 0.8rem;
          }
        }

        > .emailInfo {
          background-color: #ffffff;
          position: absolute;
          padding: 0.6rem 1.8rem;
          border-radius: 12px;
          box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
          left: -3.7rem;
          top: 10.5rem;
          display: flex;
          justify-content: center;
          align-items: center;

          > a {
            color: inherit;
            text-decoration: underline;
            text-decoration-thickness: 0.06em;
          }

          @media screen and (max-width: 50rem) {
            font-size: 1rem;
            left: -0.8rem;
            padding: 0.6rem 1rem;
          }

          > svg {
            padding-inline-end: 0.8rem;
          }
        }
      }

      &:last-child {
        margin-bottom: 3em;
      }
    }

    > .infoGrid {
      margin-top: 3rem;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
      margin-bottom: 6.25rem;

      @media screen and (max-width: 75rem) {
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 2rem;
      }

      @media screen and (max-width: 50rem) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      }

      > .infoContainer {
        background: #ffffff;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        width: 20rem;
        height: 20rem;
        text-align: center;

        > .iconContainer {
          height: 8rem;
          width: 100%;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        > h3 {
          margin: 0;
          padding: 1rem 0;
          font-size: 1.25rem;
          width: 100%;
        }

        :nth-child(3) {
          margin: 0;
          padding: 1rem 0;
          font-weight: 600;
          padding: 0;
          margin: 0;
          font-size: 1rem;
          width: 100%;
        }

        > p {
          margin: 0 auto;
          padding: 1rem 0;
          width: 80%;
          font-weight: 300;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.checkoutStickyContainer {
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 0.5em;

  .contentHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  button {
    background-color: #0068ff;
    color: white;
    border: none;
    outline: none;
    border-radius: 1.5rem;
    padding: 0.8rem;
    width: 20.9rem;
    font-size: 1rem;
    margin-left: 10.5rem;
    font-weight: 700;
    cursor: pointer;
    font-family: 'Biennale', sans-serif;
  }

  .retailPricing {
    font-weight: 900;
    color: #000;
    //margin-top: -1em;
  }

  .priceDetails {
    display: flex;

    > p {
      margin-left: 0.8em;
    }
  }

  @media screen and (max-width: 30rem) {
    padding: 0em;

    .retailPricing {
      font-weight: 900;
      color: #000;
      margin-top: -1em;
    }

    button {
      width: 12rem;
      margin: auto;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      font-family: 'Biennale', sans-serif;
    }

    .priceDetails {
      display: block;
      margin-left: 0.5rem;
    }
  }
}

.introLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.spacer {
  height: 4.2rem;
}

.curtainLoader {
  background-color: white;
  z-index: 100;
}
</style>
