<template>
  <div class="navigationWrapper" :class="{ showNavigation: hoveringMainMenu }">
    <div class="navigationOverlay" />
    <div class="navigationSlider" :class="{ showSubMenu: subMenuExpanded }">
      <div class="navigation">
        <router-link active-class="active" @click.native="trackNavigation('Browse')" :to="'/' + $root.localizedRoutes.Search.path">{{ t['browse'] }}<Bullet /></router-link>
        <router-link active-class="active" @click.native="trackNavigation('Trade-in')" :to="'/' + $root.localizedRoutes.TradeIn.path"> {{ t['trade_in'] }}<Bullet /> </router-link>
        <router-link active-class="active" @click="trackNavigation('Financing')" :to="'/' + $root.localizedRoutes.Financing.path">{{ t['financing'] }}<Bullet /></router-link>
        <router-link active-class="active" @click.prevent.native="subMenuExpanded = !subMenuExpanded" event="" :to="'/' + $root.localizedRoutes.WhySeez.path">
          {{ t['why_seez'] }}<Bullet />
        </router-link>
        <a @click="trackSupport()" href="https://support.seez.dk" target="_blank" rel="noreferrer">{{ t['support'] }}<Bullet /></a>
        <a @click="trackNavigation('Blog')" href="https://blog.seez.dk" target="_blank" rel="noreferrer">Blog<Bullet /></a>
        <router-link class="termsAndConditions" :to="'/' + $root.localizedRoutes.Terms.path">Terms and Conditions</router-link>
      </div>
      <WhySeezSubMenuMobile :close-menu="closeMenu" />
    </div>
  </div>
</template>

<script>
import WhySeezSubMenuMobile from 'src/components/Header/WhySeezSubMenuMobile'
import Bullet from '../../assets/icons/arrow-right.svg'

export default {
  name: 'MainNav',
  components: { WhySeezSubMenuMobile, Bullet },
  props: {
    hoveringMainMenu: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      subMenuExpanded: false
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    },
  },
  methods: {
    trackSupport() {
      const userId = this.$root.user != null ? this.$root.user.id : ''
      this.$root.track('customer_support_start', { event_name: 'customer_support_start', event_category: 'user_engagment', event_action: 'customer_support_start', event_label: { user_id: userId } })
    },
    trackNavigation(name) {
      this.$root.track(this.$root.analyticsEvents.NAVIGATION, {
        event_name: this.$root.analyticsEvents.NAVIGATION,
        event_label: name,
        event_category: 'user_engagement',
        event_action: { iteraction: { navigation: { type: 'header_navigation' } } }
      })
    },
    closeMenu() {
      this.subMenuExpanded = !this.subMenuExpanded
    }
  }
}
</script>

<style lang="scss" scoped>
.navigationWrapper {
  position: absolute;
  top: 3.5em;
  height: calc(100% - 3.5em);
  z-index: 2;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.navigationSlider {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: 200%;
  height: 100%;
  transition: transform 0.3s ease-in-out;

  &.showSubMenu {
    transform: translateX(-50%);
  }
}

.navigation {
  background-color: #1a2634;

  > a {
    display: block;
    font-size: 1.25em;
    padding: 1em 4em 1em 1em;
    white-space: nowrap;
    color: #fff;
    position: relative;

    > svg {
      position: absolute;
      right: 1em;
      top: 1.25em;
    }
  }

  > a + a {
    border-top: 1px solid #48515d;
    border-radius: 0;
  }

  > .termsAndConditions {
    display: none;

    @media screen and (max-width: 50rem) {
      display: block;
      position: absolute;
      bottom: 3em;
      left: 1em;
      font-size: 0.75em;
      border-top: none;
    }
  }
}

.showNavigation {
  transform: translateX(0);
}
</style>
