<template>
  <div v-if="dealership && dealerDescription(dealership.id)" class="dealerBanner">
    <div class="dealerText">
      <h1>{{ t['partner_dealer'] }}</h1>
      <p>{{ dealerDescription(dealership.id) }}</p>
    </div>
    <div class="dealerLogoWrapper">
      <img :src="dealership.logoUrl" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DealerBanner',
  props: {
    dealership: { type: Object, default: null, required: true }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    dealerDescription(id) {
      const dealerDescriptions = {
        1: 'Starmark har siden 2001 leveret nogle af Danmarks flottest klargjorte brugte biler. Starmark ejes af Danmarks største Mercedes-Benz forhandler Ejner Hessel. Og netop herfra stammer mange af vores let tilkørte biler.',
        2: 'Hos Ejner Hessel har vi købt og solgt biler siden 1968, og har i dag forretninger i det meste af landet. Når du køber en lettere brugt bil I en af vores forretninger, følger der en lang række fordele med.',
        3: 'Hos Kvalitetsbiler har vi i 40 år været leverandør af klargjorte kvalitetsbiler til konkurrencedygtige priser. Vi leverer årligt 3000 biler af højeste kvalitet, som sælges med markedets bedste sikkerhedsnet.',
        4: 'Hos N. Kjær går vi højt op i at yde vores kunder den bedste service og vejledning. Det gør vi med stort engagement, åbenhed og ærlighed, som vi altid møder vores kunder med. Det er også derfor, vi flere gange er blevet kåret til ”Årets Forhandler”, der tildeles baseret på kundetilfredshed.',
        5: 'Aunsbjerg er både køreglæde og købeglæde. Hos Aunsbjerg lægger vi os i selen for at give dig den allerbedste oplevelse, både når du skal køre og købe. Derfor står vi altid klar med mere end 400 biler på lager og Danmarks skarpeste priser. Det er din garanti for at blive godt kørende.Trustpilot 4.7 stjerner med 5,000 reviews.',
        6: 'Bin2Bil forhandler brugte og nye biler i højeste kvalitet. De brænder for biler og du vil hurtigt mærke at kundeservice i førersædet – både i deres salgsafdeling og eftermarked.',
      }
      
      //TODO: Please adjust this logic once content is moved to Prismic 
      if(id == 8 && process.env.VUE_APP_ENV_LABEL === 'dev')return dealerDescriptions[5]
      if(id == 9 && process.env.VUE_APP_ENV_LABEL === 'dev') return dealerDescriptions[6]
      

      return dealerDescriptions[id] || ''
    }
  }
}
</script>

<style lang="scss">
@import '../normalize';
@import '../base';

.dealerBanner {
  @include normalize;

  display: flex;
  justify-content: space-between;
  color: black;
  border-bottom: 1px solid #e2e2e2;
  min-height: 6rem;
  padding-bottom: 1.5rem;

  @include respond(st-48) {
    padding-bottom: 0.5rem;
  }

  > .dealerText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 75%;

    > h1 {
      font-size: 1rem;
      padding-bottom: 8px;
      font-weight: 600;
    }

    > p {
      font-size: 14px;
      font-weight: 400;
    }
  }

  > .dealerLogoWrapper {
    display: flex;
    align-items: center;
    margin: 0.5rem;

    > img {
      width: auto;
      max-width: 6.5rem;
      max-height: 4rem;
      margin: auto;
    }
  }
}
</style>
