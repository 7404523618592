export const categoryFilters = {
  FAMILY_FRIENDLY: '?body-types=1,5,9',
  ECONOMIC: '?price-max=250000&price-type=retail',
  OUTDOOR: '?body-types=8,9',
  LUXURY: '?body-types=7,11',
  FAMILY_SUV: '?body-types=1,8,9',
  ECONOMICAL_CARS: '?engine-size-max=1.6&fuel-types=4,9&price-max=250000&price-type=retail',
  ENVIRONMENTALLY_PROD: '?fuel-types=6,7,8',
  ENVIRONMENTALLY_DEV: '?fuel-types=10,6,9',
  MERCEDES: 'mercedes-benz',
  DACIA: 'dacia',
  FORD: 'ford',
  RENAULT: 'renault',
  SUV: '?body-types=9',
  SEDAN: '?body-types=3',
  HATCHBACK: '?body-types=4',
  WAGON: '?body-types=11',
}
