<template>
  <section class="browseLifeStyle">
    <h1>{{ t['browse_by_lifestyle'] }}</h1>
    <p>{{ t['unsure_what_you_are_looking_for'] }} {{ t['find_inspiration_here'] }}</p>
    <ul>
      <li class="leftIndicator">
        <router-link data-test-id="budget" @click.native="trackSearch('Budget')" :to="`/${$root.localizedRoutes.Search.path}${categoryFilters.ECONOMIC}`" class="budget">
          <img src="@/assets/budget.webp" alt="" />
        </router-link>
      </li>
      <li class="middleIndicator">
        <router-link data-test-id="eco_friendly" @click.native="trackSearch('Eco Friendly')" :to="handleBodyTypeFilter" class="eco-friendly">
          <img src="@/assets/eco-friendly.webp" alt="" />
        </router-link>
      </li>
      <li class="rightIndicator">
        <router-link data-test-id="family_life" @click.native="trackSearch('Family Life')" :to="`/${$root.localizedRoutes.Search.path}${categoryFilters.FAMILY_FRIENDLY}`" class="family">
          <img src="@/assets/family.webp" alt="" />
        </router-link>
      </li>
    </ul>
    <div class="mobileMenu">
      <div />
      <div />
      <div />
    </div>
  </section>
</template>

<script>
import { categoryFilters } from 'src/lib/constants'

export default {
  data() {
    return {
      categoryFilters
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HOME_VIEW_TRANSLATIONS
    },
    handleBodyTypeFilter() {
      if (process.env.VUE_APP_ENV_LABEL === 'dev') return `/${this.$root.localizedRoutes.Search.path}${this.categoryFilters.ENVIRONMENTALLY_DEV}`
      return `/${this.$root.localizedRoutes.Search.path}${this.categoryFilters.ENVIRONMENTALLY_PROD}`
    }
  },
  methods: {
    trackCTA(name) {
      this.$root.trackCTA(this.$root.analyticsEvents.CTA_CLICK, { interaction: { cta: { name: name } } })
    },
    trackSearch(name) {
      const brand = this.searchBoxSelectedBrand
      const model = this.searchBoxSelectedModel

      this.$root.track(this.$root.analyticsEvents.SEARCH_RESULT, {
        category: 'user_engagement',
        action: 'search_result',
        label: name,
        iteraction: { brand, model }
      })
    }
  }
}
</script>

<style lang="scss">
.home > section.browseLifeStyle {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding: 6rem 1rem 4rem 1rem;

  @media screen and (max-width: 50rem) {
    padding: 1rem 1rem;
  }

  > h1 {
    margin-block-end: 0rem;
    font-size: 1.75rem;
  }

  > ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 2rem 0 0 0;

    @media (max-width: 1024px) {
      display: none;
      overflow: hidden;
      max-width: 90vw;
      gap: 2rem;
      padding: 2rem 1rem 0 0;
      overflow-x: scroll;
      justify-content: start;
      margin: auto;
      padding: auto;
    }

    > li {
      border-radius: 12px 12px 0 0;
      cursor: pointer;
      position: relative;

      > a {
        img {
          width: 90%;
          height: auto;
          background-color: transparent;
          position: relative;

          @media (max-width: 1024px) {
            width: auto;
            max-width: 100vw;
          }
        }
      }
    }

    .leftIndicator {
      transition: transform 300ms ease-in-out 50ms;

      &:hover {
        transform: scale(1);
        transform: translateX(170px);
      }
    }

    .middleIndicator {
      transition: transform 300ms ease-in-out 50ms;

      &:hover {
        transform: scale(1);
        transform: translateY(0px);
      }
    }

    .rightIndicator {
      transition: transform 300ms ease-in-out 50ms;

      &:hover {
        transform: scale(1);
        transform: translateX(-165px);
      }
    }

    @media (min-width: 1024px) {
      transition: transform 150ms ease-in-out 30ms;

      .leftIndicator {
        transform: scale(1);
        transform: translateX(190px);
      }

      .middleIndicator {
        transform: scale(1);
        transform: translateY(-20px);
        z-index: 2;
      }

      .rightIndicator {
        transform: scale(1);
        transform: translateX(-185px);
      }
    }
  }

  .mobileMenu {
    display: none;
  }
}
</style>
