<template>
  <div class="whySeezSubMenuMobile" ref="submenuMobile">
    <div class="backButtonContainer">
      <ChevronIcon @click="closeMenu" />
      <span>{{ t['why_seez'] }}</span>
    </div>
    <div class="mobileSubMenuContent">
      <section>
        <div class="whyBuyFromSeezMobile">
          <p>{{ t['why_buy_from_seez'] }}</p>
          <p>{{ t['our_aim'] }}</p>
        </div>
        <router-link :to="`/${$root.localizedRoutes.WhySeez.path}`">
          <div class="links">
            <p>{{ t['how_it_works'] }}</p>
            <ChevronIcon />
          </div>
        </router-link>
        <router-link :to="`/${$root.localizedRoutes.About.path}`">
          <div class="links">
            <p>{{ t['read_more_about_seez'] }}</p>
            <ChevronIcon />
          </div>
        </router-link>
        <div class="seezBenefits">
          <p>{{ t['seez_benefits'] }}</p>
        </div>
        <div>
          <router-link :to="`/${$root.localizedRoutes.Quality.path}`" class="seezBenefitsSection">
            <QualityIcon />
            <div class="titleAndSubtitle">
              <h5>{{ t['quality_asured_title'] }}</h5>
              <p>{{ t['quality_asured_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
        </div>
        <div>
          <router-link :to="`/${$root.localizedRoutes.Return.path}`" class="seezBenefitsSection">
            <MoneyBackGauranteeIcon />
            <div class="titleAndSubtitle">
              <h5>{{ t['return_title'] }}</h5>
              <p>{{ t['return_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
        </div>
        <div>
          <router-link :to="`/${$root.localizedRoutes.Warranty.path}`" class="seezBenefitsSection">
            <OneYearWarranty />
            <div class="titleAndSubtitle">
              <h5>{{ t['warranty_title'] }}</h5>
              <p>{{ t['warranty_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
        </div>
        <div>
          <router-link :to="`/${$root.localizedRoutes.Delivery.path}`" class="seezBenefitsSection">
            <FreeDelivery />
            <div class="titleAndSubtitle">
              <h5>{{ t['free_delivery_title'] }}</h5>
              <p>{{ t['free_delivery_text'] }}</p>
            </div>
            <ChevronIcon />
          </router-link>
        </div>
        <div class="terms">
          <router-link :to="'/' + $root.localizedRoutes.Terms.path">Terms and Conditions</router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import QualityIcon from 'src/assets/why-seez-submenu/quality.svg'
import MoneyBackGauranteeIcon from 'src/assets/why-seez-submenu/money-back-gaurantee.svg'
import OneYearWarranty from 'src/assets/why-seez-submenu/1-year-warranty.svg'
import FreeDelivery from 'src/assets/why-seez-submenu/free-delivery.svg'
import ChevronIcon from 'src/assets/icons/chevron-white.svg'

export default {
  name: 'WhySeezSubMenuMobile',
  components: { QualityIcon, MoneyBackGauranteeIcon, OneYearWarranty, FreeDelivery, ChevronIcon },
  props: {
    closeMenu: {
      default: null,
      type: Function
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HEADER_COMPONENT_TRANSLATIONS
    }
  }
}
</script>

<style lang="scss">
.whySeezSubMenuMobile {
  @media screen and (min-width: 50rem) {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  height: 100%;
  width: 100%;
  background-color: #1a2634;
  font-size: 1.25em;
  overflow: auto;

  a {
    color: white;
  }

  > .backButtonContainer {
    box-sizing: border-box;
    display: flex;
    background: linear-gradient(112.06deg, #0068ff 34.7%, #9e80ff 93.87%);
    padding: 0.8em 2rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-top: 0.5px solid white;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    color: white;
    align-items: center;
    cursor: pointer;
    width: 100%;
    span {
      font-size: 1rem;
      position: relative;
      left: 1.43rem;
    }

    svg {
      transform: rotate(180deg);
    }
  }
  > .mobileSubMenuContent {
    .whyBuyFromSeezMobile {
      padding: 1.2em 2rem;
      font-size: 1rem;
      white-space: normal;
      font-weight: 700;
      color: white;
      p:not(:first-child) {
        font-size: 0.8rem;
        margin: 0;
        font-weight: normal;
      }
    }
    .links {
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      border-top: 0.75px solid #48515d;
      border-bottom: 0.75px solid #48515d;
      justify-content: space-between;
      align-items: center;
      //padding: 1rem 2rem;
      padding: 1em 2rem;
      p {
        margin: 0px;
      }
    }
    .seezBenefits {
      padding: 2.25rem 0rem 1rem 2rem;
      border-bottom: 0.75px solid #48515d;
      font-size: 1rem;
      font-weight: 700;
      color: white;
      p {
        margin: 0;
      }
    }
    .seezBenefitsSection {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 1.5rem;
      align-items: center;
      border-bottom: 0.75px solid #48515d;
      padding: 1em 2rem;
      a {
        color: white;
      }
      .titleAndSubTitle {
        margin-bottom: 1.25rem;
      }
      h5 {
        margin: 0;
        font-size: 0.8rem;
        font-weight: 600;
      }

      p {
        margin: 0.37rem 0rem 0rem 0rem;
        font-size: 12.8px;
        font-weight: 400;
        white-space: normal;
      }
    }
    .terms {
      padding: 1.5rem 2.5rem;
      > a {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
