<template>
  <div v-if="$root.SDKLoaded">
    <h1>{{ t['saved_searches'] }}</h1>
    <seez-sdk-saved-searches @select="select" :lg="$root.language" :to="'/' + $root.localizedRoutes.Search.path" />
  </div>
</template>

<script>
export default {
  name: 'Searches',
  computed: {
    t() {
      return this.$root.languageResources.SEARCHES_VIEW_TRANSLATIONS
    }
  },
  methods: {
    select(search) {
      const newFilters = search.detail[0].filterParameters
      const cleanFilters = {}
      for (const key in newFilters) {
        if (newFilters[key] == null) continue
        const k = key.replace(/([A-Z])/g, r => '-' + r.toLowerCase())
        if (Array.isArray(newFilters[key])) cleanFilters[k] = newFilters[key].join(',')
        else cleanFilters[k] = newFilters[key].toString()
      }
      if (cleanFilters['number-of-doors-min'] && cleanFilters['number-of-doors-max'] && cleanFilters['number-of-doors-min'] === cleanFilters['number-of-doors-max']) {
        cleanFilters.doors = cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-max']
      }
      this.$router.push({ path: '/' + this.$root.localizedRoutes.Search.path, query: cleanFilters })
    }
  }
}
</script>

<style lang="scss">
.h1 {
  text-align: left;
  font-size: 1.25em;
  padding: 0.5em 0;
}
</style>
