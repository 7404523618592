<template>
  <div class="detailsNew">
    <div v-if="!showHeader" class="headerBackground" />
    <div class="mobileBackground" />
    <div v-if="details && showHeader" class="buyHeader">
      <StickyHeader :header-data="headerData" @click="clickBuy" />
    </div>
    <div v-if="$root.SDKLoaded && details" class="detailsContainer2" :key="componentKey">
      <div @scroll="handleScroll" class="listingDetails">
        <div class="benefitsHeader">
          <Benefits />
        </div>
        <div class="imagePanel">
          <div class="actionHeaderWrapper">
            <ActionHeader
              :header-data="headerData"
              @download="onClickBrochure"
              @favorite="toggleFavorite"
              :favorited="favorited"
              @share="copyClipToboard"
              :registration-type="details.registrationType"
            />
          </div>
          <div v-if="showClipboard" class="fade-in-bottom">
            <ClipboardButton @close="showClipboard = false" />
          </div>
          <ImageGrid @onClickShowAll="onClickShowAll" :image-data="details.pictures" :is-electric="isElectric" :tag="tag" :tag-class="tagClass" :show-tag="tagText()" />
        </div>
        <section class="wrapperContainer">
          <div class="leftContent">
            <div class="layoutPadding">
              <DealerBanner v-if="details.dealership" :dealership="details.dealership" />
              <SpecsGrid :specs-data="quickSpecsData" />
            </div>
            <TradeInBanner @clickTradeIn="clickTradeIn" />
            <div class="layoutPadding">
              <Equipment :description="description" />
              <SpecsToggleList :details="details" />
            </div>
          </div>
          <aside class="rightContent">
            <div class="stickyEffect">
              <PriceInfo
                @goToFinance="goToFinance()"
                :registration-type="details.registrationType"
                :loan-calculation="loanCalculation"
                :price-retail="details.priceRetail"
                :listing-currency="details.currency"
                @on-buy="clickBuy"
                @handleShowHeader="handleShowHeader"
                :cta-label="ctaLabel"
                :popover-text="financeText"
                :show-info="fadeOutText"
              />
              <div v-if="!loanCalculation" class="loadingPriceInfo" />
              <TradeInInputContainer @clickTradeIn="clickTradeIn" />
            </div>
            <span v-if="loanCalculation" :class="[{ paymentSmall: true, 'fade-out': fadeOutText }]" v-html="loanCalculation.disclaimer" />
          </aside>
        </section>
        <div ref="calculatorRef" class="calculatorWrapper">
          <div class="calculatorPadding">
            <seez-sdk-calculator v-if="details" :listing="details.id" :lg="lg">
              <h1>{{ t['finance_calculator'] }}</h1>
            </seez-sdk-calculator>
          </div>
        </div>
        <div class="paddingWrapper">
          <BrandBanner v-if="details" :details="details" :brand-data="brandData" />
          <RelatedCars :listing-id="$route.params.id" />
        </div>
        <Footer />
      </div>
    </div>
    <div v-if="details" class="stickyBuyFooter">
      <PriceInfo
        @goToFinance="goToFinance()"
        :registration-type="details.registrationType"
        :loan-calculation="loanCalculation"
        :price-retail="details.priceRetail"
        :listing-currency="details.currency"
        @on-buy="clickBuy"
        :cta-label="ctaLabel"
        :popover-text="financeText"
      />
    </div>
    <Brochure v-if="details && !loading && !error" :details="details" :qr-code-url="url" />
    <ModalListing v-if="showModal" @close="onCloseModal" :active-image="selectedImage" :active-index="activeIndex" :image-list="details.pictures" />
    <Drawer :direction="'right'" :exist="true" ref="RightDrawer">
      <seez-sdk-trade-in v-if="$root.SDKLoaded" :listing="$route.params.id" :search-vehicle="licensePlateToSearch" @closeDrawer="clickTradeIn" />
    </Drawer>
    <Loader v-if="loading" />
    <ModalActiveOrder v-if="errorMessage" :error-message="errorMessage" :active-order="activeOrder" @cancel="cancelActiveOrder" @close="closeError" />
    <ModalUnavailableCar v-if="showReservedModal && details" :details="details" @close="showReservedModal = false" />
  </div>
</template>

<script>
import StickyHeader from './StickyHeader.vue'
import Benefits from './Benefits.vue'
import ActionHeader from './ActionHeader.vue'
import ClipboardButton from './ClipboardButton.vue'
import ImageGrid from './ImageGrid.vue'
import DealerBanner from './DealerBanner.vue'
import SpecsGrid from './SpecsGrid.vue'
import TradeInBanner from './TradeInBanner.vue'
import Equipment from './Equipment.vue'
import SpecsToggleList from './SpecsToggleList.vue'
import PriceInfo from './PriceInfo.vue'
import TradeInInputContainer from './TradeInInputContainer.vue'
import BrandBanner from './BrandBanner.vue'
import RelatedCars from './RelatedCars.vue'
import Footer from '../../components/Footer.vue'
import Brochure from './Brochure.vue'
import ModalListing from './ModalListing.vue'
import Drawer from './Drawer.vue'
import Loader from '../../components/Loader.vue'
import ModalActiveOrder from '../../components/ModalActiveOrder.vue'
import ModalUnavailableCar from '../../components/ModalUnavailableCar.vue'
import { querier } from '../../lib/querierMixin'

import car from '../../assets/car.webp'

const LISTING_STATES = {
  SOLD: 'sold',
  RESERVED: 'reserved'
}

export default {
  name: 'ListingDetails',
  components: {
    StickyHeader,
    Benefits,
    ActionHeader,
    ClipboardButton,
    ImageGrid,
    DealerBanner,
    SpecsGrid,
    TradeInBanner,
    Equipment,
    SpecsToggleList,
    PriceInfo,
    TradeInInputContainer,
    BrandBanner,
    RelatedCars,
    Footer,
    Brochure,
    ModalListing,
    Drawer,
    Loader,
    ModalActiveOrder,
    ModalUnavailableCar
  },
  mixins: [querier],
  data() {
    return {
      details: null,
      user: null,
      loading: false,
      errorMessage: null,
      listing: null,
      activeOrder: null,
      showReservedModal: false,
      loanCalculation: null,
      buyDisabled: null,
      tagState: '',
      tagClass: '',
      tag: '',
      fadeOutText: false,
      licensePlateInput: null,
      hideRelated: false,
      url: window.location.href,
      showModal: false,
      activeIndex: null,
      selectedImage: null,
      licensePlateToSearch: '',
      action: null,
      showHeader: false,
      showClipboard: false,
      favorites: window.seezSdk.getFavorites()
    }
  },
  computed: {
    financeText() {
      if (!this.loanCalculation) return
      return this.loanCalculation.disclaimer
    },
    lg() {
      return this.$root.language
    },
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    },
    brandData() {
      return Object.entries(this.t).find(e => e[0] === this.details?.brand?.name)?.[1]
    },
    isElectric() {
      return this.details.fuelType.name === 'El'
    },
    favorited() {
      return this.favorites.includes(parseInt(this.details.id))
    },
    componentKey() {
      return this.$root.user?.email + '_' + this.$route.params.id
    },
    currency() {
      return this?.listingCurrency ?? 'kr.'
    },
    headerData() {
      if (!this.details) return
      if (!this.loanCalculation) return
      return {
        name: this.details.name,
        variant: this.details.variant,
        year: this.details.year,
        kilometrage: this.formatSpec(this.details.kilometrage, 'km'),
        brochureLabel: this.t.brochure,
        saveLabel: this.t.save,
        shareLabel: this.t.share,
        priceRetail: this.details.priceRetail?.toLocaleString(this.language) + ' ' + this.currency,
        monthlyPayment: this.loanCalculation.monthlyPayment?.toLocaleString(this.language) + ' ' + this.currency + ' / ' + this.t.month
      }
    },
    quickSpecsData() {
      if (!this.details) return
      return {
        color: this.details.color,
        kilometrage: this.formatSpec(this.details.kilometrage, 'km'),
        horsepower: this.formatSpec(this.details.hp, 'BHP'),
        transmission: this.details.transmission.name,
        fuelType: this.details?.fuelType.name,
        registration: this.details?.registrationDate ? new Date(this.details.registrationDate).toLocaleDateString() : ''
      }
    },
    ctaLabel() {
      if (this.buyDisabled) return this.t.coming_soon
      if (this.user && this.details?.reservedBy === this.user.id) return this.t.resume_checkout
      return this.t.get_started
    },
    description() {
      if (this.details.description == null || this.details.description?.toLowerCase() === this.details?.name?.toLowerCase()) return null
      return this.editDescription(this.details.description)
    }
  },
  watch: {
    '$route.params.id'() {
      this.details = null
      this.selectedImage = null
      this.showHeader = false
      this.calculateLoan()
      this.loadDetails()
    }
  },
  mounted() {
    this.calculateLoan()
    this.loadDetails()

    window.seezSdk.getCurrentUser().then(r => {
      this.user = r
    })
  },
  methods: {
    async calculateLoan() {
      try {
        const result = await this.queryApi(`{loanCalculation(listingId:${this.$route.params.id},paymentTerms:96){monthlyPayment,disclaimer}}`)
        this.loanCalculation = result.loanCalculation
      } catch (error) {
        this.loanCalculation = null
      }
    },
    onClickBrochure() {
      window.print()
      this.trackEvent(this.$root.analyticsEvents.CTA_CLICK, { event_label: 'download_brochure' })
    },
    toggleFavorite() {
      window.seezSdk.setFavorite(this.details.id)
      this.favorites = window.seezSdk.getFavorites()
      this.trackEvent(this.$root.analyticsEvents.FAVOURITE_CAR, { event_label: { listing_id: this.details.id } })
    },
    clickBuy() {
      this.trackEvent(
        this.$root.analyticsEvents.ADD_TO_CART,
        {
          name: this.$root.analyticsEvents.ADD_TO_CART,
          category: 'enhanced_ecommerce',
          event_action: this.$root.analyticsEvents.ADD_TO_CART,
          event_label: { car_id: this.details.id },
          listing_price: this.details.priceRetail
        },
        { vehicle: this.details }
      )

      this.validation()
    },
    listingLoaded(e) {
      this.listing = e.detail[0]
      this.$root.setPageTitle(this.listing?.name ?? this.$root.localizedRoutes.Details.title)
    },
    async queryActiveOrder() {
      try {
        //TODO: this query should be unified with the one in validation
        const query = 'fragment orderFields on Order {id,state,listing {name,year,kilometrage,priceRetail,pictures,variant,reserved,state,reservationExpires}},{user {activeOrder {...orderFields}}}'
        this.loading = true
        const { user } = await this.queryApi(query)
        this.loading = false
        return user
      } catch (e) {
        console.error(e)
      }
    },
    async cancelActiveOrder() {
      this.loading = true
      await this.queryApi(`mutation {cancelOrder(orderId: ${this.activeOrder.id}) {id} }`)
      this.loading = false
      this.validation()
    },
    async loadDetails() {
      this.loading = true
      this.error = null
      try {
        const query = `
          query listingDetails($listingId: ID) {
            listing(id: $listingId) {
              id,name,model {id, name},brand {id, name}, bodyType { name }, targetSite { name, logo, brandingHighlightColor}, variant,year,currency,priceRetail,priceMonthly,kilometrage,
              dealership{id, name,logoUrl},pictures,color,hp,transmission {name},fuelType {id, name},registrationDate,reserved, state,
              reservedBy,equipment{design,comfortTechnology,safetyEnvironment,uncategorized},referenceNumber,acceleration,
              topSpeed,numAirbags,numGears,numCylinders,abs,weight,loadCapacity,maxAttachment,numDoors,isElectric,
              range,registrationType,description
            }
          }`
        const result = await this.queryApi(query, { listingId: this.$route.params.id })
        result.listing.pictures = result.listing.pictures ? [...new Set(result.listing.pictures)] : []
        this.details = result.listing
        this.selectedImage = this.details.pictures && this.details.pictures.length > 0 ? this.details.pictures[0] : car
      } catch (error) {
        console.error(error)
        this.error = 'Error loading listing details'
      } finally {
        this.loading = false
      }
    },
    async validation() {
      if (await window.seezSdk.getCurrentUser()) {
        this.loading = true
        const { user } = await this.queryApi('{user{activeOrder{id, state}, orders {id, listingId, state}}}')
        this.loading = false

        if (this.details.reservedBy != null && this.details.reservedBy === this.$root.user?.id) {
          const order = user.activeOrder ?? user.orders.find(o => o.listingId === this.details.id.toString() && o.state === 'confirmed')
          // eslint-disable-next-line require-atomic-updates
          window.location = `/checkout?order=${order.id}`
          return
        } else if (user.activeOrder) {
          const user = await this.queryActiveOrder()
          this.activeOrder = user.activeOrder
          this.errorMessage = 'You already have an active order'
          return
        }
      }
      if (this.details.state === 'unavailable') return (this.showReservedModal = true)
      if (this.details.reservedBy != null) this.errorMessage = 'This vehicle is already reserved'
      if (this.details.reserved) return (this.showReservedModal = true)
      else this.$router.push({ path: `/${this.$root.localizedRoutes.Intro.path}/${this.$route.params.id}` })
    },
    closeError() {
      this.errorMessage = null
    },
    editDescription(description) {
      const START = 'Vi glæder os til at se dig!'
      const END = 'Og meget mere...'
      const isStringPatternPresent = description.includes(START) && description.includes(END)
      const lastIndexStart = description.lastIndexOf(START)
      const lastIndexEnd = description.lastIndexOf(END)
      const finalDescription = isStringPatternPresent ? description.slice(lastIndexStart + START.length, lastIndexEnd + END.length) : description

      return finalDescription.replace(/\n+/g, '<br />').replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '&middot; ')
    },
    formatSpec(value, unit) {
      if (value == null) return '-'
      if (unit == null) unit = ''
      const stringValue = typeof value == 'number' ? value.toLocaleString(this.language) : value
      return stringValue + ' ' + unit
    },
    tagText() {
      if (this.details.reserved) {
        this.tag = this.t.reserved
        this.tagClass = LISTING_STATES.RESERVED
        return true
      }
      if (this.details.state === 'unavailable') {
        this.tag = this.t.sold
        this.tagClass = LISTING_STATES.SOLD
        return true
      }
      return false
    },
    onClickShowAll(v) {
      this.activeIndex = v.value
      this.selectedImage = v.url
      this.details.pictures.map((item, index) => (item === this.selectedImage ? (this.activeIndex = index) : null))
      return (this.showModal = !this.showModal)
    },
    onCloseModal(value) {
      this.showModal = false
      this.selectedImage = value.url
    },
    goToFinance() {
      this.$refs.calculatorRef.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    },
    handleShowHeader(isOnViewPort) {
      if (isOnViewPort) return (this.showHeader = false)
      if (!isOnViewPort) return (this.showHeader = true)
    },
    copyClipToboard() {
      const listingUrl = `${window.location.origin + this.$route.fullPath}`
      this.trackEvent(this.$root.analyticsEvents.CTA_CLICK, { action: 'share_button_click' })

      navigator.clipboard
        .writeText(listingUrl)
        .then(() => {
          this.showClipboard = true
        })
        .catch(e => {
          alert('Error copying the clipboard', e)
        })
      setTimeout(() => {
        this.showClipboard = false
      }, 3000)
    },
    handleScroll(e) {
      if (e.target.scrollTop >= 650) {
        this.fadeOutText = true
      } else {
        this.fadeOutText = false
      }
    },
    trackEvent(eventName, properties, vehicleData) {
      if (eventName === this.$root.analyticsEvents.CTA_CLICK) {
        return this.$root.track(eventName, { properties })
      }
      return this.$root.track(eventName, { properties }, vehicleData)
    },
    clickTradeIn(inputValue) {
      if (this.$refs.RightDrawer.active) {
        this.$refs.RightDrawer.close()
      } else {
        this.trackEvent(this.$root.analyticsEvents.CTA_CLICK, {
          name: 'instant_tradein_inititated'
        })
        this.licensePlateToSearch = inputValue
        this.$refs.RightDrawer.open()
      }
    }
  }
}
</script>

<style lang="scss">
@import '../normalize';
@import '../base';

.detailsNew {
  @include normalize;

  align-self: start;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative !important;

  > .stickyBuyFooter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0rem;
    z-index: 2;

    @include respond(st-48) {
      display: none;
    }
  }

  > .headerBackground {
    height: calc(4.2em + 1px);
    background: linear-gradient(111.12deg, #0068ff 35.04%, #9e80ff 105%);
    z-index: inherit;
    display: none;

    @media (min-width: 800px) {
      display: block;
    }
  }

  > .mobileBackground {
    height: calc(4.2em + 1px);
    background: linear-gradient(111.12deg, #0068ff 35.04%, #9e80ff 105%);
    z-index: inherit;
    display: block;

    @media (min-width: 800px) {
      display: none;
    }
  }

  .buyHeader {
    position: sticky;
    top: 0;
    z-index: 5;
  }

  > .detailsContainer2 {
    padding: 0;
    background-color: white;
    border-radius: 12px 12px 0 0;
    transform: translateY(-0.8rem);
    color: black;

    @keyframes pulse-bg {
      0% {
        background-color: white;
      }
      50% {
        background-color: #f9f9f7;
      }
      100% {
        background-color: white;
      }
    }

    .listingDetails {
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: scroll;
    }

    .listingDetails::-webkit-scrollbar {
      display: none;
    }

    > .listingDetails {
      width: 100%;
      position: relative;
      display: block;
      padding: 0;
      gap: 0;
      overflow: scroll;
      overscroll-behavior: contain;
      height: auto;

      @include respond(st-48) {
        height: calc(100vh - 4.5vh);
      }

      margin-bottom: 100px;

      > .benefitsHeader {
        background: rgb(238, 238, 238, 0.5);
        padding: 1.75em var(--content-side-padding-wide);
        border-radius: 12px 12px 0 0;
      }

      > .imagePanel {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;

        .actionHeaderWrapper {
          padding: 0 1rem;

          @include respond(st-48) {
            padding: 0 0;
          }
        }

        @include respond(st-48) {
          flex-direction: column;
          padding: 0 var(--content-side-padding-wide);
        }
      }

      > .wrapperContainer {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        position: relative;

        .layoutPadding {
          padding: 1rem 1rem 0 1rem;

          @include respond(st-48) {
            padding: 0;
          }
        }

        @include respond(st-48) {
          margin-top: 41px;
          flex-direction: row;
          padding: 0 var(--content-side-padding-wide);
        }

        > .leftContent {
          flex: 1 1 70%;

          @include respond(st-48) {
            padding-inline-end: 2rem;
          }
        }
        > .rightContent {
          flex: 1 1 25%;
          position: fixed;
          z-index: 1999;
          left: 0;
          right: 0;
          bottom: 2rem;
          display: none;

          @include respond(st-48) {
            display: block;
            position: initial;
            z-index: 1;
          }

          .stickyEffect {
            position: sticky;
            top: 2rem;
            float: right;
            width: 100%;
            z-index: 3;

            .loadingPriceInfo {
              height: 331.5px;
              width: 100%;
              background-color: white;
              border-radius: 12px;
              animation: pulse-bg 1s infinite;
              position: absolute;
              top: 0;
            }
          }

          .paymentSmall {
            padding-top: 10rem;
            font-weight: 400;
            font-size: 12.24px;
            line-height: 1.1rem !important;
            color: #757575;
            -webkit-animation: fadeIn 0.5s linear forwards;
            animation: fadeIn 0.5s linear forwards;
            opacity: 1;
            display: none;

            @include respond(st-48) {
              display: block;
            }
          }

          .fade-out {
            -webkit-animation: fadeOut 1s linear forwards;
            animation: fadeOut 1s linear forwards;
            opacity: 0;
          }

          @keyframes fadeOut {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
              display: none;
              background-color: white;
            }
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
              display: none;
              background-color: white;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }

      > .calculatorWrapper {
        background-color: #ebf3ff;
        z-index: 2;
        position: relative;

        > .calculatorPadding {
          padding: 2em 1em;

          @include respond(st-48) {
            padding: 2em var(--content-side-padding-wide);
          }
        }
      }

      > .paddingWrapper {
        padding: 2.5em var(--content-side-padding-wide);
      }

      @keyframes fadeInBottom {
        from {
          opacity: 0;
          transform: translateY(1500px);
        }
        to {
          opacity: 1;
        }
      }

      .fade-in-bottom {
        animation: fadeInBottom 0.5s ease-in-out;
        padding: 1rem;
        position: fixed;
        z-index: 2;
        left: -0.5rem;
        bottom: 50%;

        @include respond(st-48) {
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          top: 50%;
        }
      }
    }
  }

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  @media screen and (max-width: 50rem) {
    align-self: start;
    overflow: auto;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    > .detailsContainer {
      padding: 1em;
    }
  }
}
</style>
