<template>
  <div v-if="activeOrder" class="modal">
    <dialog open>
      <i @click="closeModal">&times;</i>
      <h1>{{ t['active_order_change'] }}</h1>
      <p>{{ t['you_have_active_order'] }}</p>
      <h3>
        <b>{{ t['are_you_sure_cancel'] }}</b>
      </h3>
      <div class="carCard">
        <img v-if="activeOrder" :src="activeOrder.listing.pictures[0]" alt="" />
        <div class="listingInfo">
          <div>
            <span>{{ activeOrder.listing.year }} &middot; {{ activeOrder.listing.kilometrage.toLocaleString($root.language) }} Km</span>
            <h3>{{ activeOrder.listing.name }}</h3>
            <p>{{ activeOrder.listing.variant }}</p>
          </div>
          <div>
            <p>{{ t['price'] }}</p>
            <h3 v-if="activeOrder.listing.priceRetail">{{ activeOrder.listing.priceRetail.toLocaleString($root.language) }} Kr.</h3>
          </div>
        </div>
      </div>
      <div class="actionButtons">
        <button @click="cancelActiveOrder">{{ t['cancel_start_new_order'] }}</button>
        <a v-if="activeOrder.state === 'ongoing'" :href="'/checkout?order=' + activeOrder.id">{{ t['resume_order'] }}</a>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalActiveOrder',
  props: { activeOrder: { type: Object, default: null } },
  computed: {
    t() {
      return this.$root.languageResources.MODAL_ACTIVE_ORDER_COMPONENT
    },
  },

  methods: {
    cancelActiveOrder() {
      this.$emit('cancel')
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(2px) brightness(60%);
  z-index: 5;
  background-color: rgba(117, 116, 116, 0.50);

  > dialog {
    text-align: center;
    position: relative;
    padding: 2em 3em;
    border: none;
    border-radius: 2em;

    @media screen and (max-width: 35rem) {
      padding: 1em 0;
      margin: 1rem;
      border-radius: 2em;
    }

    > i {
      cursor: pointer;
      font-size: 2em;
      font-style: normal;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      line-height: 0.5em;
    }

    > h1 {
      margin: 0;
      font-size: 1.25em;
      margin-bottom: 1rem;
    }

    > p,
    h3 {
      padding: 0;
      margin: 0;
      font-size: 1.2em;

      @media screen and (max-width: 35rem) {
        font-size: 0.8em;
        padding: 0 2rem 0 2rem;
      }
    }

    > h3 {
      margin-bottom: 2rem;
    }

    > .carCard {
      display: flex;
      @media screen and (max-width: 35rem) {
        margin: 0 2rem 0 2rem;
      }
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      border-radius: 2em;

      img {
        height: 6.25rem;
        width: auto;
        object-fit: cover;
        border-radius: 2em 0 0 2em;

        @media screen and (max-width: 35rem) {
          width: 50%;
          height: auto;
        }
      }
      .listingInfo {
        display: flex;
        justify-content: space-between;
        gap: 5rem;
        padding: 0.8rem 1rem 0 1.5rem;

        @media screen and (max-width: 35rem) {
          flex-direction: column;
          justify-content: baseline;
          gap: 0rem;
        }

        :first-child {
          text-align: start;
          display: block;

          > * {
            padding: 0;
            margin: 0;
          }

          > :first-child,
          :last-child {
            font-size: 0.8em;
            color: #afafaf;
            @media screen and (max-width: 35rem) {
              font-size: 0.64rem;
            }
          }

          > h3 {
            font-size: 1.2em;

            @media screen and (max-width: 35rem) {
              font-size: 0.8rem;
            }
          }
        }

        :last-child {
          > * {
            padding: 0;
            margin: 0;
          }
          > :first-child {
            font-size: 0.8em;
            color: #afafaf;

            @media screen and (max-width: 35rem) {
              font-size: 0.64rem;
            }
          }
          > p {
            @media screen and (max-width: 35rem) {
              display: none;
            }
          }
          > h3 {
            font-size: 1.2em;
            @media screen and (max-width: 35rem) {
              text-align: start;
              font-size: 0.8rem;
              padding-bottom: 1rem;
            }
          }
        }
      }
    }

    > .actionButtons {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 0.4rem;
      margin-top: 2rem;

      @media screen and (max-width: 35rem) {
        flex-direction: column;
        gap: 1rem;
      }

      > :first-child,
      :last-child {
        padding: 0.75em 1em;
        border-radius: 2em;
        font-size: 1rem;
        flex: 1 1 0%;
        font-weight: bold;
        cursor: pointer;
        white-space: nowrap;

        @media screen and (max-width: 35rem) {
          padding: 1rem 2rem 1rem 2rem;
          margin: 0 2rem 0 2rem;
          font-size: 1rem;
        }
      }

      > :first-child {
        color: #0068ff;
        background-color: white;
        border-color: #0068ff;
        border-style: solid;
        border-image: none;
        font-family: 'Biennale', sans-serif;
      }

      > :last-child {
        color: white;
        background-color: #0068ff;
        border: none;
        font-family: 'Biennale', sans-serif;
      }
    }
  }
}
</style>
