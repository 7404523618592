<template>
  <section v-if="headerData" class="listingDetailsHeader">
    <div class="carInfo">
      <div class="listing-name-vat">
        <h1>{{ headerData.name }}</h1>
        <VatTag :registration-type="registrationType" />
      </div>
      <p v-if="headerData.variant">
        {{ headerData.variant }}
        <span> &middot; {{ headerData.year }} &middot; </span>
        <span>{{ headerData.kilometrage }}</span>
      </p>
    </div>
    <ul class="actionList">
      <li>
        <a @click="$emit('download')">
          <label for="downloadIcon">
            <DownloadIcon name="downloadIcon" class="icon" />
            <span>{{ headerData.brochureLabel }}</span>
          </label>
        </a>
      </li>
      <li>
        <a @click="$emit('favorite')">
          <label for="heartIcon">
            <HeartIcon v-if="!favorited" name="heartIcon" class="icon" />
            <HeartFilledIcon v-if="favorited" name="heartFilled" class="icon" />
            <span>{{ headerData.saveLabel }}</span>
          </label>
        </a>
      </li>
      <li>
        <a @click="$emit('share')">
          <label for="shareIcon">
            <ShareIcon name="shareIcon" class="icon" />
            <span>{{ headerData.shareLabel }}</span>
          </label>
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
import HeartIcon from 'src/assets/icons/heart-icon.svg'
import VatTag from './VatTag.vue'
import HeartFilledIcon from 'src/assets/icons/heart-filled-icon.svg'
import DownloadIcon from 'src/assets/icons/download.svg'
import ShareIcon from 'src/assets/icons/share-icon.svg'

export default {
  name: 'ActionHeader',
  components: {
    DownloadIcon,
    HeartIcon,
    HeartFilledIcon,
    ShareIcon,
    VatTag
  },
  props: {
    headerData: { type: Object, default: null },
    favorited: { type: Boolean, default: false },
    registrationType: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
@import '../normalize';

.listingDetailsHeader {
  @include normalize;

  background-color: white;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: 0;
  padding: 3rem 0 1rem 0;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 1rem 0;
  }

  > .carInfo {
    color: black;

    > h1 {
      font-size: 31.25px;
      line-height: 56px;
      font-weight: 700;
      @media (max-width: 768px) {
        line-height: 40px;
      }
    }

    > .listing-name-vat {
      display: grid;
      grid-template-columns: 1fr 0.55fr;
      align-items: center;
      margin-bottom: 10px;
    }

    .tagVAT {
      display: none;
      margin-left: 10px;
      background-color: #f6f6f6;
      max-height: 30px;
      text-align: center;
      font-size: 0.7em;
      @media (max-width: 768px) {
        display: inline;
      }
    }

    > p {
      font-size: 16px;
      font-weight: 500;

      > span {
        margin: 0 0.4rem;
      }
    }
  }

  .icon {
    margin: 0 1px;

    @media (min-width: 768px) {
      width: 22px;
    }
  }
  .actionList {
    list-style: none;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 768px) {
      position: initial;
      transform: translate(0, 0);
    }

    > li {
      padding: 0 1rem;

      > a {
        > label {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0068ff;
          background-color: white;
          cursor: pointer;
          border-radius: 9999px;
          width: 42px;
          height: 42px;
          box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
          text-align: center;

          @media (min-width: 768px) {
            box-shadow: none;
            width: auto;
            height: auto;
          }

          > span {
            display: none;

            @media (min-width: 768px) {
              display: contents;
            }
          }
        }
      }

      a:hover {
        text-decoration: underline;
        color: #0068ff;
      }
    }
  }
}
</style>
