<template>
  <div class="myFavorites" v-if="$root.SDKLoaded">
    <h1>{{ t['my_favorites'] }}</h1>
    <seez-sdk-favorites class="favoritesList" :lg="$root.language" to="/listing/{id}" />
  </div>
</template>

<script>

export default {
  name: 'Favorites',
  computed: {
    t() {
      return this.$root.languageResources.FAVORITES_VIEW_TRANSLATIONS
    },
  }
}
</script>

<style lang="scss" scoped>
.myFavorites {
  place-self: start stretch;

  .favoritesList {
    place-self: start stretch;
  }
}
</style>
