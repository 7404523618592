<template>
  <div v-if="description" class="equipmentWrapper">
    <div class="text">
      <h3>{{ t['equipment'] }}</h3>
      <div :class="{ expanded: descriptionExpanded }">
        <div v-if="description" v-html="description" ref="descriptionContainer" />
      </div>
    </div>
    <button :class="{ open: descriptionExpanded }" @click="descriptionExpanded = !descriptionExpanded">
      {{ descriptionExpanded ? t['read_less'] : t['read_more'] }}
      <span class="icon"><ArrowUp /></span>
    </button>
    <div class="separator" />
  </div>
</template>

<script>
import ArrowUp from '../../assets/icons/arrow-up.svg'

export default {
  name: 'Equipment',
  components: {
    ArrowUp
  },
  props: {
    description: { type: String, default: '' }
  },
  data() {
    return {
      descriptionExpanded: false,
      descriptionContainerHeight: 0
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    }
  }
}
</script>

<style scoped lang="scss">
@import '../base';
@import '../normalize';

@include normalize;

.equipmentWrapper {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  height:100%;
  overflow: hidden;



  > .text {
    > div {
      max-height: 6.7rem;
      line-height: 1.5rem;
      margin-top: 1rem;

      @include respond(st-48) {
        max-height: 7.2rem;

       }

      &.expanded {
        max-height: 100%;
      }
    }
  }

  .icon {
    padding-inline-start: 0.5rem;
    cursor: pointer;

    > svg {
      transform: rotate(180deg);
      transition: transform 0.4s;
    }
  }

  .open svg {
    transform: rotate(360deg);
  }

  button {
    color: #0068ff;
    background-color: white;
    font-weight: 700;
    font-size: 12.8px;
    border-style: none;
    padding: 0 0 3rem 0;
    width: 100%;
    text-align: start;
    margin: 1rem 0 0 0;
    background-color: white;

    @include respond(st-48) {
      margin: 0.5rem 0 0 0;

    }
    
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: #e2e2e2;

  }
}
</style>
