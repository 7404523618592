<template>
  <div class="qrCode">
    <qrcode-vue :value="value" :size="size" level="H" />
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue
  },
  props: { value: { type: String, required: true } },
  data() {
    return {
      size: 70
    }
  }
}
</script>

<style lang="scss" scoped>
.qrCode {
  padding: 0.2rem;
  background-color: white;
}
</style>
