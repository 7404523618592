<template>
  <section :class="['hero']">
    <div class="overlay" />
    <p class="title">
      {{ t['buy_your_almost_new_car'] }}
    </p>
    <p class="subtitle">{{ t['with_complete_confidence'] }}</p>

    <div class="searchBox">
      <multi-select :options="brands" :multi="false" v-model.number="searchBoxSelectedBrand" :placeholder="t['make']" data-test-id="car_make" />
      <multi-select :options="families" :multi="false" v-model="searchBoxSelectedModel" :placeholder="t['model']" data-test-id="car_model" />
      <router-link :to="searchBoxLink" class="default" data-test-id="hero_search_button">
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.125 14.875L12.375 11.125C13.2188 9.875 13.6562 8.3125 13.4375 6.625C13.0312 3.75 10.6875 1.4375 7.84375 1.0625C3.59375 0.53125 0 4.125 0.53125 8.375C0.90625 11.2188 3.21875 13.5625 6.09375 13.9688C7.78125 14.1875 9.34375 13.75 10.625 12.9062L14.3438 16.6562C14.8438 17.125 15.625 17.125 16.125 16.6562C16.5938 16.1562 16.5938 15.375 16.125 14.875ZM2.96875 7.5C2.96875 5.3125 4.75 3.5 6.96875 3.5C9.15625 3.5 10.9688 5.3125 10.9688 7.5C10.9688 9.71875 9.15625 11.5 6.96875 11.5C4.75 11.5 2.96875 9.71875 2.96875 7.5Z"
            fill="white"
          />
        </svg>
        {{ searchBoxSelectedBrand ? t['search'] : t['search_all_cars'] }}
        <span v-if="totalVehicles">{{ `(${totalVehicles})` }}</span>
        <span class="trackBtn" role="button" @click="handleTrack" />
      </router-link>
    </div>
  </section>
</template>

<script>
import MultiSelect from 'src/components/MultiSelect.vue'
import { querier } from '../../lib/querierMixin'

export default {
  components: { MultiSelect },
  mixins: [querier],
  data() {
    return {
      brands: [],
      searchBoxSelectedBrand: null,
      searchBoxSelectedModel: null,
      totalVehicles: null
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.HOME_VIEW_TRANSLATIONS
    },
    heroClass() {
      return `hero${Math.floor(Math.random() * 2) + 1}`
    },
    families() {
      const fams = this.brands?.find(b => b.id === this.searchBoxSelectedBrand)?.families
      if (fams == null) return []
      return fams.map(f => ({
        id: `F${f.id}`,
        name: f.name,
        count: f.count,
        children: f.children.map(m => ({
          id: `M${m.id}`,
          name: m.name,
          count: m.count
        }))
      }))
    },
    searchBoxLink() {
      let result = this.$root.localizedRoutes.Search.path

      if (this.searchBoxSelectedBrand) {
        const brand = this.brands.find(b => b.id === this.searchBoxSelectedBrand)
        result += `/${this.$root.slugify(brand.name)}`

        if (this.searchBoxSelectedModel?.startsWith('F')) {
          result += `?families=${this.searchBoxSelectedModel.substring(1)}`
        } else if (this.searchBoxSelectedModel?.startsWith('M')) {
          const model = brand.families.flatMap(f => f.children).find(m => m.id === parseInt(this.searchBoxSelectedModel.substring(1)))
          result += `/${this.$root.slugify(model.name)}`
        }
      }

      return result
    }
  },
  watch: {
    searchBoxSelectedBrand() {
      this.updateBrandTotalVehicles()
    },
    searchBoxSelectedModel() {
      this.updateModelFamilyTotalVehicles()
    }
  },
  mounted() {
    this.loadAvailableBrands()
  },
  methods: {
    async loadAvailableBrands() {
      const { filterOptionsWithCounters: filterOptions } = await this.queryApi('{filterOptionsWithCounters{brands{id,name,count},families{id,name,brandid,count},models{id,name,familyid,count}}}')
      this.totalVehicles = filterOptions.brands.reduce((acc, obj) => {
        return acc + obj.count
      }, 0)
      this.brands = filterOptions.brands.map(b => ({
        id: parseInt(b.id),
        name: b.name,
        families: filterOptions.families
          .filter(f => f.brandid === b.id)
          .map(f => ({
            id: parseInt(f.id),
            name: f.name,
            children: filterOptions.models.filter(m => m.familyid === f.id).map(m => ({ id: parseInt(m.id), name: m.name, count: m.count })),
            count: f.count
          })),
        count: b.count
      }))
    },
    handleTrack() {
      const brand = this.searchBoxSelectedBrand
      const model = this.searchBoxSelectedModel
      this.$root.track(this.$root.analyticsEvents.SEARCH_RESULT, { category: 'user_engagement', action: this.$root.analyticsEvents.SEARCH_RESULT, label: 'Search Bar', iteraction: { brand, model } })
    },
    updateBrandTotalVehicles() {
      if (this.searchBoxSelectedBrand === null) {
        this.totalVehicles = this.brands.reduce((acc, obj) => {
          return acc + obj.count
        }, 0)
        return (this.searchBoxSelectedModel = null)
      }

      const brandCount = this.brands?.find(b => b.id === this.searchBoxSelectedBrand)?.count
      this.totalVehicles = brandCount
      this.searchBoxSelectedModel = null
    },
    updateModelFamilyTotalVehicles() {
      const selectedBrandFamilies = this.brands?.find(b => b.id === this.searchBoxSelectedBrand).families
      if (this.searchBoxSelectedModel?.startsWith('F')) {
        const familyId = parseInt(this.searchBoxSelectedModel.substring(1))
        const familyCount = selectedBrandFamilies.find(f => f.id === familyId).count
        this.totalVehicles = familyCount
      }
      if (this.searchBoxSelectedModel?.startsWith('M')) {
        const modelId = parseInt(this.searchBoxSelectedModel.substring(1))
        const modelCount = selectedBrandFamilies.flatMap(f => f.children).find(m => m.id === modelId).count
        this.totalVehicles = modelCount
      }
      if (!this.searchBoxSelectedModel) {
        this.totalVehicles = this.brands?.find(b => b.id === this.searchBoxSelectedBrand)?.count
      }
    }
  }
}
</script>

<style lang="scss">
.home > section.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 55%;
  background-position-y: 40%;
  height: 42em;
  position: relative;
  margin-bottom: 4em;
  background-image: url('~@/assets/landing-photo.webp');

  > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(31, 31, 31, 0.7) 50%, rgba(31, 31, 31, 0) 90%);
    mix-blend-mode: multiply;
    opacity: 1;
  }

  > .title,
  > .subtitle {
    left: var(--content-side-padding);
    right: var(--content-side-padding);
    bottom: auto;
    color: white;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width: 50rem) {
      left: var(--content-side-padding);
      right: var(--content-side-padding);
      top: 20%;
    }
  }

  > .title {
    position: absolute;
    top: 17%;
    font-size: 2.2em;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    max-width: 50rem;
    margin: auto;

    @media screen and (max-width: 50rem) {
      top: 20%;
      font-size: 1.6em;
      left: 1rem;
      right: 1rem;
    }

    @media screen and (max-width: 17.5rem) {
      top: 15%;
      font-size: 1.4em;
    }
  }

  > .subtitle {
    margin: 0;
    position: absolute;
    top: 34%;
    font-size: 2em;
    text-align: center;

    @media screen and (max-width: 50rem) {
      top: 45%;
      font-size: 1.4em;
    }

    @media screen and (max-width: 17.5rem) {
      top: 50%;
      font-size: 1.2em;
    }
  }

  > .searchBox {
    position: absolute;
    top: auto;
    left: var(--content-side-padding);
    right: var(--content-side-padding);
    bottom: 3em;
    margin: auto;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4em;
    padding: 0.5em 0.5em;
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 0.5em;
    max-width: 56rem;

    > h2 {
      margin: 0;
    }

    > .multiselect {
      margin: auto 0;

      > button {
        font-size: 1rem;
        border: none;
        font-weight: 500;
      }

      &:first-of-type {
        border-right: 1px solid #ababab;
        margin: auto 0;
        padding: 0 1em 0 0;
      }

      > ul {
        top: 3.25rem;
      }
    }

    > select {
      flex-grow: 1;
      box-sizing: border-box;
      padding: 0.75em 1.5em;
      border-radius: 0.2em;
      border: 1px solid #cbcbcb;
      background-color: white;
      color: #757575;
      font-family: 'Biennale', sans-serif;

      &:focus {
        outline: thin solid var(--highlight);
      }

      > option:first-child {
        font-style: italic;
      }
    }

    > a {
      text-decoration: none;
      color: white;
      background-color: var(--highlight);
      padding: 0.75em 2em;
      border-radius: 2em;
      text-align: center;
      border: 1px solid var(--highlight);
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      > span {
        padding-inline-start: 0.3rem;
      }

      &:hover {
        background-color: #0053cc;
      }

      &.secondary {
        background: none;
        color: var(--highlight);
        font-weight: bold;
        border: 1px solid var(--highlight);
      }

      > * {
        vertical-align: sub;
        padding-right: 0.2em;
      }

      > img {
        display: inline-block;
        margin-right: 1ch;
      }
    }

    .trackBtn {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
    }

    @media screen and (max-width: 50rem) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-auto-flow: column;
      padding: 1.5em 2em;
      border-radius: 3em;
      bottom: -5em;
      gap: 1em;
      box-shadow: var(--blue-shadow);

      > .multiselect {
        border: 1px solid #cbcbcb;
        border-radius: 2em;

        &:first-of-type {
          padding: 0;
          border-right: 1px solid #cbcbcb;
        }
      }
    }
  }

  @media screen and (max-width: 50rem) {
    margin-bottom: 8em;
    background-position: 52%;
    height: 38rem;

    > .searchBox {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      top: auto;
      left: var(--content-side-padding);
      right: var(--content-side-padding);
      bottom: -9em;
      border-radius: 2em;
      padding: 1em 1em;

      > a {
        grid-area: 3;
      }
    }
  }
}
</style>
