<template>
  <div class="tradeIn">
    <h1>{{ t['trade_it_in'] }}</h1>
    <p>{{ t['use_your_old_car'] }}</p>
    <label class="licensePlate">
      <span>
        <span class="prefix"><img src="../../assets/eu.png" /> <span>DK</span></span>
        <input required type="text" v-model="licensePlateInput" ref="licensePlateInput" minlength="5" maxlength="10" @keyup.enter="clickTradeIn" />
        <button :disabled="!licensePlateInput" type="button" :class="['search', { disabled: !licensePlateInput }]" @click="clickTradeIn">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13">
            <g stroke-width="2" stroke="#ffffff" fill="none">
              <path d="M11.29 11.71l-4-4" />
              <circle cx="5" cy="5" r="4" />
            </g>
          </svg>
        </button>
      </span>
    </label>
    <div>
      <router-link @click.native="trackEvent()" :to="'/' + $root.localizedRoutes.TradeIn.path">{{ t['learn_about_trade_in'] }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TradeInInputContainer',
  data() {
    return {
      licensePlateInput: null
    }
  },
  computed: {
    t() {
      return this.$root.languageResources.LISTING_DETAILS_COMPONENT_TRANSLATIONS
    }
  },
  methods: {
    clickTradeIn() {
      this.$emit('clickTradeIn', this.licensePlateInput)
    },
    trackEvent() {
      this.$root.trackCTA(this.$root.analyticsEvents.CTA_CLICK,{ name: 'track_link_listing_click' })
    },
  }
}
</script>

<style lang="scss">
@import '../normalize';
@import '../base';

.tradeIn {
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 0.5rem 1rem 0.1rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  z-index: 1;
  display: none;

  @include respond(st-48) {
    display: block;
  }

  > h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.8rem;
  }

  > p,
  a {
    font-size: 0.78em;
    font-weight: 400;
    padding-top: 0.2rem;
    line-height: 1rem;

    @media (min-width: 1108px) {
      font-size: 0.8em;
      padding-inline-start: 0.4rem;
    }
  }

  > div {
    margin-bottom: 0.5rem;

    > a {
      text-decoration: underline;
    }
  }

  > .licensePlate > span {
    display: flex;
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    margin: 14px 0 12px 0;

    > input {
      padding: 0em 1em;
      border: none;
      flex-grow: 1;
      color: #333333;
      outline: none;
      border-radius: 2em;
    }

    > span.prefix {
      padding: 0.4em 1em;
      position: relative;
      background: #003e99;
      border-radius: 2em 0 0 2em;
      display: flex;
      flex-direction: column;
      > span {
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 11px;
        text-align: center;
        color: white;
      }

      > img {
        max-width: 21px;
        max-height: 21px;
      }
    }

    &.error {
      > input {
        border-left-color: red;
        border-top-color: red;
        border-bottom-color: red;
      }

      > span {
        border-color: red;

        &::before {
          content: '!';
          color: red;
          position: absolute;
          top: 0.5em;
          left: -4em;
          border: 1px solid red;
          border-radius: 2em;
          height: 1em;
          width: 1em;
          padding: 0.125em;
          text-align: center;
          line-height: 1em;
          font-weight: bold;
        }
      }
    }

    > button.search {
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      overflow: hidden;
      outline: none;
      display: flex;
      justify-self: center;
      align-items: center;
      color: white;
      text-decoration: none;
      cursor: pointer;
      width: 39px;
      height: 38px;
      padding: 14px;
      background: #0068ff;
      border-radius: 24px;
      margin: 3px;
      position: relative;

      @media (min-width: 1108px) {
        width: 40px;
        height: 40px;
      }

      > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    > button.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
